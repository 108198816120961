import { Component, HostBinding, OnInit, ViewChild, inject } from '@angular/core';
import { HierarchyUnitFormData } from '@unifii/sdk';

import { UfControl } from '../../../controls';
import { HierarchyUnitIdentifier, HierarchyUnitPickerModalData, HierarchyUnitProvider } from '../../../models';
import { HierarchyService, UfFormBuilder } from '../../../services';
import { SharedTermsTranslationKey } from '../../../translations';
import { Modal, ModalData, ModalRuntime } from '../../modal';

import { HierarchyPrefillController } from './hierarchy-prefill-controller';
import { HierarchyUnitSelectorComponent } from './hierarchy-unit-selector.component';

@Component({
	selector: 'uf-hierarchy-unit-picker-modal',
	templateUrl: './hierarchy-unit-picker-modal.html',
})
export class HierarchyUnitPickerModalComponent implements Modal<HierarchyUnitPickerModalData, HierarchyUnitFormData | null>, OnInit {

	@HostBinding('class.uf-form-card') cardClass = true;

	@ViewChild(HierarchyUnitSelectorComponent)
	private hierarchyUnitSelectorComponent: HierarchyUnitSelectorComponent;

	runtime = inject<ModalRuntime<HierarchyUnitPickerModalData, HierarchyUnitFormData | null>>(ModalRuntime);
	data = inject<HierarchyUnitPickerModalData>(ModalData);

	protected readonly sharedTermsTK = SharedTermsTranslationKey;
	protected control: UfControl | undefined;
	protected label: string;
	protected ceiling: HierarchyUnitIdentifier | null | undefined;
	protected isRequired = false;
	protected leavesOnly = false;
	protected activesOnly = false;
	protected unselectableUnits: HierarchyUnitIdentifier[] | null | undefined;
	protected showsClear = false;
	protected inProgress = true;

	private ufFormBuilder = inject(UfFormBuilder);
	private hierarchyProvider = inject(HierarchyUnitProvider);
	private hierarchyService = inject(HierarchyService);
	
	async ngOnInit() {
		const { leavesOnly, preselected, ceiling, label, readOnly, activesOnly, unselectableUnits, allowsClear, isRequired } = this.data;
		let value = this.data.value;

		this.isRequired = !!isRequired;
		this.ceiling = ceiling;
		this.leavesOnly = !!leavesOnly;
		this.activesOnly = !!activesOnly;
		this.unselectableUnits = unselectableUnits;
		this.label = label ?? '';
		this.showsClear = !!value && !!allowsClear;

		if (!value && preselected) {
			// verify that the preselected unit exists and is a descendant of the configured ceiling
			const hierarchyPrefillController = new HierarchyPrefillController(this.hierarchyProvider);
			const preselectedUnit = await hierarchyPrefillController.getUnit(preselected);

			value = preselectedUnit;
		}

		const validators = this.hierarchyService.createValidator(this.data);

		this.control = this.ufFormBuilder.control(
			{ value, disabled: !!readOnly },
			validators.validators,
			validators.asyncValidators,
		);

		this.inProgress = false;
	}

	close() {
		this.runtime.close(undefined);
	}
	
	protected clear() {
		this.runtime.close(null);
	}

	protected save() {
		if (!this.control) {
			return;
		}

		if (this.control.invalid) {
			this.control.setSubmitted();
			this.hierarchyUnitSelectorComponent.markControlAsTouched();

			return;
		}

		this.runtime.close(this.control.value as HierarchyUnitFormData | undefined ?? null);
	}

}
