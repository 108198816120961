<ng-template [ngIf]="mode === modes.Input">
	<ng-container [ngSwitch]="entry.type">
		<ng-template [ngSwitchCase]="filterTypes.Date">
			<uf-date [(value)]="valueString" (valueChange)="valueChanged()" />
		</ng-template>
		<ng-template [ngSwitchCase]="filterTypes.Datetime">
			<uf-datetime [(value)]="valueString" (valueChange)="valueChanged()" defaultTime="00:00:00" />
		</ng-template>
		<ng-template [ngSwitchCase]="filterTypes.Time">
			<uf-time [(value)]="valueString" (valueChange)="valueChanged()" />
		</ng-template>
		<ng-template [ngSwitchCase]="filterTypes.ZonedDatetime">
			<uf-datetime-tz [(value)]="valueZDT" (valueChange)="valueChanged()" class="stacked" />
		</ng-template>
	</ng-container>
</ng-template>

<div *ngIf="mode === modes.Display && !isEmpty" class="uf-chip x-small">
	{{ label }}: {{ displayValue }}
	<button (click)="remove()" type="button" class="uf-action">
		<uf-icon name="delete" />
	</button>
</div>