import { Directive, EventEmitter, Input, Output } from '@angular/core';

/** Event emitted when the value of a MatSelectionModel has changed */
export interface SelectionChange<T> {
	/** Options that were added to the model */
	added: T[];
	/** Options that were removed from the model */
	removed: T[];
}

export interface SelectItem<T> {
	selected: boolean;
	disabled: boolean;
	item: T;
	index: number;
}

@Directive({
	selector: '[selectMaster]',
})
export class SelectMasterDirective<T> {

	@Input() selectable: number | boolean | undefined;
	/** Event emitted when the value has changed */
	@Output() selectionChange = new EventEmitter<SelectionChange<T>>();
	@Output() selectionLimitReached = new EventEmitter<void>();

	/** Similar to _selection, keep aligned to provide array reference to external access */
	selected: T[] = [];

	/** Selects registered under this master */
	private _mainSelect: SelectItem<T> | null;

	private _selects = new Map<T, SelectItem<T>>();

	/** Currently-selected values */
	private _selection = new Set<T>();

	/** Keeps track of the options selection change that haven't been emitted by the change event yet */
	private _deselectedToEmit: T[] = [];
	private _selectedToEmit: T[] = [];

	/** Selects a value or an array of values */
	select(values: T[]) {
		// Mark all as selected
		values.forEach((value) => this.markSelected(value));

		// Status changed, notify
		this.emitChangeEvent();

		if (!this.canSelect) {
			this.selectionLimitReached.emit();
		}
	}

	/** Selects all registered selects value */
	selectAll() {

		const values = this.getSelectableValues();

		this.select(values);
		// Call select

		if (this._mainSelect) {
			this._mainSelect.selected = true;
		}
	}

	/** Deselects a value or an array of values */
	deselect(values: T[]) {

		// Mark all as deselected
		values.forEach((value) => this.unmarkSelected(value));

		// Status changed, notify
		this.emitChangeEvent();
	}

	/** Clears all of the selected values */
	deselectAll() {
		this.deselect(Array.from(this._selection));

		// Unlikely but here incase all are deselected from outside
		if (this._mainSelect) {
			this._mainSelect.selected = false;
		}
	}

	/** Add a select, item select is expected to be deselected first */
	register(select: SelectItem<T>) {

		// Main select
		if (!select.item) {
			if (this._mainSelect) {
				throw new Error(`Main select already registered!`);
			}

			this._mainSelect = select;
			this._mainSelect.selected = this.allSelected && this._selects.size > 0;

			return;
		}

		// Single select
		this._selects.set(select.item, select);
		if (this._mainSelect && this._mainSelect.selected) {
			this._mainSelect.selected = false;
		}
	}

	/** Remove a select, item select is expected to be deselected first */
	deregister(select: SelectItem<T>) {

		// Main select
		if (this._mainSelect === select) {
			this._mainSelect = null;

			return;
		}

		// Sigle select
		this._selects.delete(select.item);
	}

	/** Toggles a value between selected and deselected */
	toggle(value: T) {

		if (this._mainSelect) {
			this._mainSelect.selected = false;
		}

		if (this.isSelected(value)) {
			this.deselect([value]);
		} else {
			this.select([value]);
		}
	}

	/** Update select item */
	updateSelectItem(prev: T, next: T) {
		const select = this._selects.get(prev);

		if (select) {

			const isSelected = this.isSelected(prev);

			if (isSelected) {
				this.unmarkSelected(prev);
			}

			select.item = next;

			// Update Map key reference
			this._selects.delete(prev);
			this._selects.set(next, select);

			if (isSelected) {
				this.markSelected(next);
			}

		}
	}

	// Disables checkboxes once limit has been reached
	get canSelect(): boolean {

		if (!this.selectable) {
			return false;
		}

		if (typeof this.selectable === 'boolean') {
			return true;
		}

		/**
		 * If selectable set to negative becomes unlimited not sure if this ok or weather user
		 * should have to set an extra large positive amount eg 10,000
		 */
		return this.selectable > this.selected.length || this.selectable < 0;
	}

	private getSelectableValues(): T[] {
		/**
		 * For backwards compatibility if selectable is boolean or
		 * selectable is -1 meaning all can be selected
		 */
		if (typeof this.selectable === 'number' && this.selectable > 0) {

			const items = Array.from(this._selects.keys(), (value) => ({ value, index: this.getSelectIndex(value) })).filter((i) => i.index != null);
			const sorted = items.sort((a, b) => (a.index as number) - (b.index as number)).splice(0, this.selectable);

			return sorted.map((item) => item.value);
		}

		// Return all
		return Array.from(this._selects.keys());
	}

	private getSelectIndex(value: T): number | undefined {

		const select = this._selects.get(value);

		if (select) {
			return select.index;
		}

		return undefined;
	}

	/** All items selected */
	private get allSelected(): boolean {
		return this.selected.length === this._selects.size;
	}

	/** Determines whether a value is selected */
	private isSelected(value: T): boolean {
		return this._selection.has(value);
	}

	/** Selects a value */
	private markSelected(value: T) {

		const select = this._selects.get(value);

		if (!this.isSelected(value) && select && !select.disabled) {

			this._selection.add(value);
			this.selected.push(value);
			select.selected = true;
			this._selectedToEmit.push(value);
		}
	}

	/** Deselects a value */
	private unmarkSelected(value: T) {

		const select = this._selects.get(value);

		if (this.isSelected(value) && select) {
			this._selection.delete(value);
			this.selected.splice(this.selected.indexOf(value), 1);
			select.selected = false;
			this._deselectedToEmit.push(value);
		}
	}

	/** Emits a change event and clears the records of selected and deselected values */
	private emitChangeEvent() {
		if (this._selectedToEmit.length || this._deselectedToEmit.length) {

			this.selectionChange.emit({
				added: this._selectedToEmit,
				removed: this._deselectedToEmit,
			});

			this._deselectedToEmit = [];
			this._selectedToEmit = [];
		}
	}

}
