import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { UfError, ensureUfError } from '@unifii/sdk';
import { Subscription, debounceTime } from 'rxjs';

import { UfControl } from '../../controls';
import { UfFormBuilder } from '../../services';
import { UfControlValueAccessor } from '../form';

import { CascadeSelectionDataSource, CascadeSelectionStep } from './cascade-selection-model';

@Component({
	selector: 'uf-cascade-selection',
	templateUrl: './cascade-selection.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR, useExisting: UfCascadeSelectionComponent, multi: true,
	}],
})
export class UfCascadeSelectionComponent<T> extends UfControlValueAccessor<(T | T[])[]> implements OnInit {
	
	/** provide steps' configurations and selectable options to this component */
	@Input({ required: true }) dataSource: CascadeSelectionDataSource<T>;
	@Input() columns = 1;
	@Output() override valueChange = new EventEmitter<(T | T[])[]>();

	protected steps: CascadeSelectionStep<T>[] = [];
	protected searchedOptions: (T[] | undefined)[] = [];
	protected controls: UfControl[] = [];
	protected loading = false;
	protected loadStepError: UfError | undefined;

	private ufFormBuilder = inject(UfFormBuilder);
	private controlsSubscriptions = new Map<UfControl, Subscription>();
	private ready = false;
	private skipSetup = false;

	@Input() override set value(v: (T | T[])[] | null | undefined) {
		const hasChanged = super.valueEmitPredicate(v, this.value);

		super.value = v;
		if (this.ready && hasChanged) {
			void this.setup();
		}
	}

	override get value(): (T | T[])[] {
		return super.value ?? [];
	}

	@Input() override set control(v: UfControl) {
		super.control = v;
		if (this.ready) {
			void this.setup();
		}
	}

	override get control(): UfControl {
		return super.control;
	}

	ngOnInit() {
		void this.setup();
		this.onDisabledChanges(this.control.disabled);
	}

	override valueEmitPredicate(value: (T | T[])[] | null, prev: (T | T[])[] | null): boolean {
		const hasChanged = super.valueEmitPredicate(value, prev);

		if (hasChanged && !this.skipSetup) {
			void this.setup();
		}
		
		this.skipSetup = false;

		return hasChanged && !this.loading;
	}

	protected override onDisabledChanges(disabled: boolean) {
		for (const [index, control] of this.controls.entries()) {
			if (disabled || this.steps[index]?.disabled) {
				control.disable({ emitEvent: false });
			} else {
				control.enable({ emitEvent: false });
			}
		}
	}

	protected async onStepSearch(step: CascadeSelectionStep<T>, index: number, query?: string | null | undefined) {
		if (step.predetermined) {
			return;
		}

		try {
			this.searchedOptions[index] = await step.search(query ?? undefined);
		} catch (e) {
			console.error('UfCascadeSelectionComponent.onStepSearch error', e);
		}
	}

	/** Load the steps based on the value */
	private async setup() {
		this.loading = true;
		this.removeStepsAndControlsFromIndex(0);
		const nextValue: (T | T[])[] = [];
		let nextStep = await this.getNextStep(nextValue);

		for (const value of this.value) {
			if (!nextStep) {
				break;
			}

			this.addNextStep(nextStep, value);
			nextValue.push(value);
			nextStep = await this.getNextStep(nextValue.filter(this.isStepValueNotEmpty.bind(this)));
		}

		this.addNextStep(nextStep, null);
		this.loading = false;
		this.ready = true;
	}

	private isStepValueNotEmpty(value: T | T[] | null | undefined): value is T | T[] {
		return !!value && (!Array.isArray(value) || !!value.length);
	}

	private addNextStep(step: CascadeSelectionStep<T> | undefined, value: T | T[] | null) {
		if (!step) {
			return;
		}

		this.steps.push(step);

		const index = this.controls.length;
		const control = this.ufFormBuilder.control({ value, disabled: !!step.disabled || this.control.disabled });

		// eslint-disable-next-line @typescript-eslint/no-misused-promises
		this.controlsSubscriptions.set(control, control.valueChanges.pipe(debounceTime(0)).subscribe(async(stepValue: T | T[] | null) => {
			const nextValue = this.isStepValueNotEmpty(stepValue) ? [...this.value.slice(0, index), stepValue] : this.value.slice(0, index);
			const nextStep = this.isStepValueNotEmpty(stepValue) ? await this.getNextStep(nextValue) : undefined;

			this.removeStepsAndControlsFromIndex(index + 1);
			this.addNextStep(nextStep, null);

			this.skipSetup = true;
			if (!this.loading) {
				this.control.setValue(nextValue.length ? nextValue : null);
			}
		}));
		
		this.controls.push(control);
	}

	private removeStepsAndControlsFromIndex(index: number) {
		while (this.controls.length > index) {
			const lastControl = this.controls[this.controls.length];
			
			if (lastControl) {
				this.controlsSubscriptions.get(lastControl)?.unsubscribe();
				this.controlsSubscriptions.delete(lastControl);
			}

			this.controls.pop();
			this.steps.pop();
			this.searchedOptions[index] = undefined;
		}
	}

	private async getNextStep(value: (T | T[])[]): Promise<CascadeSelectionStep<T> | undefined> {
		this.loadStepError = undefined;

		try {
			return await this.dataSource.getNextStep(value);
		} catch (e) {
			this.loadStepError = ensureUfError(e);
			console.error('UfCascadeSelectionComponent.getNextStep error', this.loadStepError);
		}

		return undefined;		
	}

}
