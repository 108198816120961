import { Component } from '@angular/core';
import { CostModelFormat } from '@unifii/sdk';

import { UfControl, ValidatorFunctions } from '@unifii/library/common';

@Component({
	selector: 'sc-empty',
	templateUrl: './show-empty.html',
	styleUrls: ['./show-empty.less'],
})
export class ShowEmptyComponent {
	
	value1: CostModelFormat = { amount: 2312292, currency: 'AUD' };
	value2: CostModelFormat = { amount: 1099, currency: 'AUD' };
	
	control1 = new UfControl(ValidatorFunctions.custom((v: CostModelFormat | null) => {
		console.log('validator1', JSON.stringify(v));
		
		return !v || v.amount > 5;
	}, 'at least 5$'), undefined, undefined, { value: { amount: 3000, currency: 'AUD' }, disabled: false });

	control2 = new UfControl(ValidatorFunctions.custom((v) => {
		console.log('validator2', JSON.stringify(v));
		
		return true;
	}, 'error'), undefined, undefined, { amount: 5000, currency: 'AUD' });

}
