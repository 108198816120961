<label *ngIf="label">{{label}}
	<ng-content></ng-content>
</label>
<div class="row">
	<div class="input-wrap">
		<input #input [formControl]="control" [attr.placeholder]="placeholder" [tabindex]="disabled ? -1 : 0"
			[attr.autocomplete]="autocomplete" [nameProperty]="nameProperty" [options]="options"
			[hasSearchConfigured]="hasSearchConfigured" [noResultsMsg]="noResultsMsg" [template]="listBoxTemplate"
			[minSearchLength]="minSearchLength" [minSearchLengthMsg]="minSearchLengthMsg"
			(optionSelected)="onselect($event)" type="text" listBoxResults />
		<div [class.input-icon--clickable]="control.value && !disabled" class="input-icon">
			<uf-icon *ngIf="!control.value" name="search" />
			<uf-icon *ngIf="control.value && !disabled" [title]="sharedTermsTK.ActionClear | translate"
				(click)="control.setValue(null); control.markAsDirty();" name="backspace" class="clear-btn" />
		</div>
		<uf-underline *ngIf="!disabled" [class.active]="focused" />
		<uf-progress />
	</div>
	<ng-content select="[actions]"></ng-content>
</div>