import { ChangeDetectorRef, Component, ViewChild, inject } from '@angular/core';
import { HierarchyUnitFormData, HierarchyUnitWithChildCount } from '@unifii/sdk';

import { ColumnGap, DataDisplayService, HierarchyCascadeSelectionComponent, Size, ToastService, UfControl } from '@unifii/library/common';

@Component({
	selector: 'sc-hierarchy-cascade-selection',
	templateUrl: './show-hierarchy-cascade-selection.html',
})
export class ShowHierarchyCascadeSelectionComponent {
	
	@ViewChild(HierarchyCascadeSelectionComponent)
		hierarchyCascadeSelection: HierarchyCascadeSelectionComponent | undefined;

	protected readonly sizes = Size;
	protected readonly columnGaps = ColumnGap;	
	protected appliedValue: HierarchyUnitFormData | undefined;
	protected control = new UfControl();
	protected ceiling: HierarchyUnitFormData | undefined;
	protected unselectableUnits: HierarchyUnitFormData[] | undefined;
	protected isRequired = false;
	protected leavesOnly = false;
	protected activesOnly = false;
	protected siblings = false;
	protected visible = true;
	
	private dataDisplayService = inject(DataDisplayService);
	private toastService = inject(ToastService);
	private cdr = inject(ChangeDetectorRef);
	
	protected display(unit: HierarchyUnitWithChildCount): string {
		return `[${unit.id}] ${unit.label} - (${unit.state})`;
	}

	protected applyValue(unit?: HierarchyUnitFormData) {
		console.log('setValue', unit);
		this.control.setValue(unit);
	}

	protected onValueChanged(unit?: HierarchyUnitFormData | HierarchyUnitFormData[]) {
		this.toastService.info(`onValueChanges ${this.dataDisplayService.displayAsString(unit)}`);
	}

	protected refresh() {
		this.visible = false;
		this.cdr.detectChanges();
		this.control.setValue(null);

		setTimeout(() => {
			this.visible = true;
			this.cdr.detectChanges();
		}, 1000);
	}

}
