import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FieldType } from '@unifii/sdk';

import { CollectionItemMetadataIdentifiers, CompanyIdentifiers, FormDefinitionMetadataIdentifiers, UserInfoIdentifiers } from '../../constants';
import { DataPropertyInfo } from '../../models';
import { CommonTranslationKey } from '../../translations';

@Injectable({ providedIn: 'root' })
export class DataPropertyInfoService {

	constructor(private translateService: TranslateService) { }

	get collectionItemReferences() {
		return {
			[CollectionItemMetadataIdentifiers.Id]: { identifier: CollectionItemMetadataIdentifiers.Id, type: FieldType.Number, label: this.translate(CommonTranslationKey.CollectionItemFieldIdLabel) },
			[CollectionItemMetadataIdentifiers.LastModifiedAt]: { identifier: CollectionItemMetadataIdentifiers.LastModifiedAt, type: FieldType.DateTime, label: this.translate(CommonTranslationKey.CollectionItemFieldLastModifiedAtLabel) },
			[CollectionItemMetadataIdentifiers.LastPublishedAt]: { identifier: CollectionItemMetadataIdentifiers.LastPublishedAt, type: FieldType.DateTime, label: this.translate(CommonTranslationKey.CollectionItemFieldLastPublishedAtLabel) },
			[CollectionItemMetadataIdentifiers.DefinitionIdentifier]: { identifier: CollectionItemMetadataIdentifiers.DefinitionIdentifier, type: FieldType.Text, label: this.translate(CommonTranslationKey.CollectionItemFieldDefinitionIdentifierLabel) },
			[CollectionItemMetadataIdentifiers.PublishedVersion]: { identifier: CollectionItemMetadataIdentifiers.PublishedVersion, type: FieldType.Text, label: this.translate(CommonTranslationKey.CollectionItemFieldPublishedVersionLabel) },
			[CollectionItemMetadataIdentifiers.Rev]: { identifier: CollectionItemMetadataIdentifiers.Rev, type: FieldType.Text, label: this.translate(CommonTranslationKey.CollectionItemFieldRevLabel) },
			[CollectionItemMetadataIdentifiers.State]: { identifier: CollectionItemMetadataIdentifiers.State, type: FieldType.Text, label: this.translate(CommonTranslationKey.CollectionItemFieldStateLabel) },
			[CollectionItemMetadataIdentifiers.Title]: { identifier: CollectionItemMetadataIdentifiers.Title, type: FieldType.Text, label: this.translate(CommonTranslationKey.CollectionItemFieldTitleLabel) },
		} as Record<CollectionItemMetadataIdentifiers, DataPropertyInfo>;
	}

	get formDefinitionReferences() {
		return {
			[FormDefinitionMetadataIdentifiers.Id]: { identifier: FormDefinitionMetadataIdentifiers.Id, type: FieldType.Text, label: this.translate(CommonTranslationKey.FormMetadataFieldIdLabel) },
			[FormDefinitionMetadataIdentifiers.SeqId]: { identifier: FormDefinitionMetadataIdentifiers.SeqId, type: FieldType.Text, label: this.translate(CommonTranslationKey.FormMetadataFieldSeqIdLabel) },
			[FormDefinitionMetadataIdentifiers.DefinitionIdentifier]: { identifier: FormDefinitionMetadataIdentifiers.DefinitionIdentifier, type: FieldType.Text, label: this.translate(CommonTranslationKey.FormMetadataFieldDefinitionIdentifierLabel) },
			[FormDefinitionMetadataIdentifiers.DefinitionVersion]: { identifier: FormDefinitionMetadataIdentifiers.DefinitionVersion, type: FieldType.Number, label: this.translate(CommonTranslationKey.FormMetadataFieldDefinitionVersionLabel) },
			[FormDefinitionMetadataIdentifiers.CreatedAt]: { identifier: FormDefinitionMetadataIdentifiers.CreatedAt, type: FieldType.DateTime, label: this.translate(CommonTranslationKey.FormMetadataFieldCreatedAtLabel) },
			[FormDefinitionMetadataIdentifiers.CreatedBy]: { identifier: FormDefinitionMetadataIdentifiers.CreatedBy, type: FieldType.Text, label: this.translate(CommonTranslationKey.FormMetadataFieldCreatedByLabel) },
			[FormDefinitionMetadataIdentifiers.OpenedAt]: { identifier: FormDefinitionMetadataIdentifiers.OpenedAt, type: FieldType.DateTime, label: this.translate(CommonTranslationKey.FormMetadataFieldOpenedAtLabel) },
			[FormDefinitionMetadataIdentifiers.StoredAt]: { identifier: FormDefinitionMetadataIdentifiers.StoredAt, type: FieldType.DateTime, label: this.translate(CommonTranslationKey.FormMetadataFieldStoredAtLabel) },
			[FormDefinitionMetadataIdentifiers.CompletedAt]: { identifier: FormDefinitionMetadataIdentifiers.CompletedAt, type: FieldType.DateTime, label: this.translate(CommonTranslationKey.FormMetadataFieldCompletedAtLabel) },
			[FormDefinitionMetadataIdentifiers.LastModifiedAt]: { identifier: FormDefinitionMetadataIdentifiers.LastModifiedAt, type: FieldType.DateTime, label: this.translate(CommonTranslationKey.FormMetadataFieldLastModifiedAtLabel) },
			[FormDefinitionMetadataIdentifiers.LastModifiedBy]: { identifier: FormDefinitionMetadataIdentifiers.LastModifiedBy, type: FieldType.Text, label: this.translate(CommonTranslationKey.FormMetadataFieldLastModifiedByLabel) },
			[FormDefinitionMetadataIdentifiers.Origin]: { identifier: FormDefinitionMetadataIdentifiers.Origin, type: FieldType.Text, label: this.translate(CommonTranslationKey.FormMetadataFieldOriginLabel) },
			[FormDefinitionMetadataIdentifiers.State]: { identifier: FormDefinitionMetadataIdentifiers.State, type: FieldType.Text, label: this.translate(CommonTranslationKey.FormMetadataFieldStateLabel) },
			[FormDefinitionMetadataIdentifiers.Action]: { identifier: FormDefinitionMetadataIdentifiers.Action, type: FieldType.Text, label: this.translate(CommonTranslationKey.FormMetadataFieldActionLabel) },
			[FormDefinitionMetadataIdentifiers.Result]: { identifier: FormDefinitionMetadataIdentifiers.Result, type: FieldType.Text, label: this.translate(CommonTranslationKey.FormMetadataFieldResultLabel) },
			[FormDefinitionMetadataIdentifiers.Bucket]: { identifier: FormDefinitionMetadataIdentifiers.Bucket, type: FieldType.Text, label: this.translate(CommonTranslationKey.FormMetadataFieldBucketLabel) },
			[FormDefinitionMetadataIdentifiers.Seq]: { identifier: FormDefinitionMetadataIdentifiers.Seq, type: FieldType.Number, label: this.translate(CommonTranslationKey.FormMetadataFieldSeqLabel) },
			[FormDefinitionMetadataIdentifiers.History]: { identifier: FormDefinitionMetadataIdentifiers.History, type: FieldType.Number, label: this.translate(CommonTranslationKey.FormMetadataFieldHistoryLabel) },
			[FormDefinitionMetadataIdentifiers.Rev]: { identifier: FormDefinitionMetadataIdentifiers.Rev, type: FieldType.Text, label: this.translate(CommonTranslationKey.FormMetadataFieldRevLabel) },
			// TODO Remove parent once DataDescriptor system in is Console/Discover
			[FormDefinitionMetadataIdentifiers.Parent]: { identifier: FormDefinitionMetadataIdentifiers.Parent, type: FieldType.Text, label: this.translate(CommonTranslationKey.FormMetadataFieldParentLabel) },
			[FormDefinitionMetadataIdentifiers.ParentBucket]: { identifier: FormDefinitionMetadataIdentifiers.ParentBucket, type: FieldType.Text, label: this.translate(CommonTranslationKey.FormMetadataFieldParentFormDataRepositoryLabel) },
			[FormDefinitionMetadataIdentifiers.ParentId]: { identifier: FormDefinitionMetadataIdentifiers.ParentId, type: FieldType.Text, label: this.translate(CommonTranslationKey.FormMetadataFieldParentIdLabel) },
			[FormDefinitionMetadataIdentifiers.ParentSeqId]: { identifier: FormDefinitionMetadataIdentifiers.ParentSeqId, type: FieldType.Text, label: this.translate(CommonTranslationKey.FormMetadataFieldParentSeqIdLabel) },
			[FormDefinitionMetadataIdentifiers.ParentDefinitionIdentifier]: { identifier: FormDefinitionMetadataIdentifiers.ParentDefinitionIdentifier, type: FieldType.Text, label: this.translate(CommonTranslationKey.FormMetadataFieldParentDefinitionIdentifierLabel) },
		} as Record<FormDefinitionMetadataIdentifiers, DataPropertyInfo>;
	}

	// TODO Why 'isActive' and 'hasPassword' are not in this list?
	get userInfoReferences() {
		return {
			[UserInfoIdentifiers.Id]: { identifier: UserInfoIdentifiers.Id, type: FieldType.Text, label: this.translate(CommonTranslationKey.UserIdLabel) },
			[UserInfoIdentifiers.Username]: { identifier: UserInfoIdentifiers.Username, type: FieldType.Text, label: this.translate(CommonTranslationKey.UsernameLabel) },
			[UserInfoIdentifiers.FirstName]: { identifier: UserInfoIdentifiers.FirstName, type: FieldType.Text, label: this.translate(CommonTranslationKey.FirstNameLabel) },
			[UserInfoIdentifiers.LastName]: { identifier: UserInfoIdentifiers.LastName, type: FieldType.Text, label: this.translate(CommonTranslationKey.LastNameLabel) },
			[UserInfoIdentifiers.Email]: { identifier: UserInfoIdentifiers.Email, type: FieldType.Email, label: this.translate(CommonTranslationKey.EmailLabel) },
			[UserInfoIdentifiers.Phone]: { identifier: UserInfoIdentifiers.Phone, type: FieldType.Phone, label: this.translate(CommonTranslationKey.PhoneLabel) },
			[UserInfoIdentifiers.IsMfaEnabled]: { identifier: UserInfoIdentifiers.IsMfaEnabled, type: FieldType.Bool, label: this.translate(CommonTranslationKey.MfaEnabledLabel) },
			// TODO Remove claims once DataDescriptor system in is Console/Discover
			[UserInfoIdentifiers.Claims]: { identifier: UserInfoIdentifiers.Claims, type: FieldType.Text, label: this.translate(CommonTranslationKey.ClaimsLabel) },
			[UserInfoIdentifiers.Company]: { identifier: UserInfoIdentifiers.Company, type: FieldType.Text, label: this.translate(CommonTranslationKey.CompanyLabel) },
			[UserInfoIdentifiers.CompanyId]: { identifier: UserInfoIdentifiers.CompanyId, type: FieldType.Text, label: this.translate(CommonTranslationKey.UserCompanyIdLabel) },
			[UserInfoIdentifiers.CompanyName]: { identifier: UserInfoIdentifiers.CompanyName, type: FieldType.Text, label: this.translate(CommonTranslationKey.UserCompanyNameLabel) },
			[UserInfoIdentifiers.Roles]: { identifier: UserInfoIdentifiers.Roles, type: FieldType.MultiChoice, label: this.translate(CommonTranslationKey.RolesLabel) },
			[UserInfoIdentifiers.CreatedAt]: { identifier: UserInfoIdentifiers.CreatedAt, type: FieldType.DateTime, label: this.translate(CommonTranslationKey.FormMetadataFieldCreatedAtLabel) },
			[UserInfoIdentifiers.LastModifiedAt]: { identifier: UserInfoIdentifiers.LastModifiedAt, type: FieldType.DateTime, label: this.translate(CommonTranslationKey.FormMetadataFieldLastModifiedAtLabel) },
			[UserInfoIdentifiers.IsExternal]: { identifier: UserInfoIdentifiers.IsExternal, type: FieldType.Bool, label: this.translate(CommonTranslationKey.UserAuthenticationLabel) },
			[UserInfoIdentifiers.Units]: { identifier: UserInfoIdentifiers.Units, type: FieldType.TextArray, label: this.translate(CommonTranslationKey.UserHierarchyUnits) },
			[UserInfoIdentifiers.UnitPaths]: { identifier: UserInfoIdentifiers.UnitPaths, type: FieldType.Text, label: this.translate(CommonTranslationKey.UserHierarchyUnitPaths) }, // {id: string, label: string}[][]
			[UserInfoIdentifiers.Manager]: { identifier: UserInfoIdentifiers.Manager, type: FieldType.Text, label: this.translate(CommonTranslationKey.ManagerLabel) },
			[UserInfoIdentifiers.ManagerId]: { identifier: UserInfoIdentifiers.ManagerId, type: FieldType.Text, label: `${this.translate(CommonTranslationKey.ManagerLabel)} ${this.translate(CommonTranslationKey.UserIdLabel)}` },
			[UserInfoIdentifiers.ManagerUsername]: { identifier: UserInfoIdentifiers.ManagerUsername, type: FieldType.Text, label: `${this.translate(CommonTranslationKey.ManagerLabel)} ${this.translate(CommonTranslationKey.UsernameLabel)}` },
			[UserInfoIdentifiers.ManagerFirstName]: { identifier: UserInfoIdentifiers.ManagerFirstName, type: FieldType.Text, label: `${this.translate(CommonTranslationKey.ManagerLabel)} ${this.translate(CommonTranslationKey.FirstNameLabel)}` },
			[UserInfoIdentifiers.ManagerLastName]: { identifier: UserInfoIdentifiers.ManagerLastName, type: FieldType.Text, label: `${this.translate(CommonTranslationKey.ManagerLabel)} ${this.translate(CommonTranslationKey.LastNameLabel)}` },
			[UserInfoIdentifiers.ManagerEmail]: { identifier: UserInfoIdentifiers.ManagerEmail, type: FieldType.Email, label: `${this.translate(CommonTranslationKey.ManagerLabel)} ${this.translate(CommonTranslationKey.EmailLabel)}` },
			[UserInfoIdentifiers.ManagerPhone]: { identifier: UserInfoIdentifiers.ManagerPhone, type: FieldType.Phone, label: `${this.translate(CommonTranslationKey.ManagerLabel)} ${this.translate(CommonTranslationKey.PhoneLabel)}` },
			[UserInfoIdentifiers.IsTester]: { identifier: UserInfoIdentifiers.IsTester, type: FieldType.Bool, label: this.translate(CommonTranslationKey.IsTesterLabel) },
			status: { identifier: CompanyIdentifiers.Status, type: FieldType.Choice, label: this.translate(CommonTranslationKey.CompanyStatusLabel) },
		} as Record<UserInfoIdentifiers | 'status', DataPropertyInfo>;
	}

	get companyReferences() {
		return {
			[CompanyIdentifiers.Id]: { identifier: CompanyIdentifiers.Id, type: FieldType.Text, label: this.translate(CommonTranslationKey.CompanyIdLabel) },
			[CompanyIdentifiers.Name]: { identifier: CompanyIdentifiers.Name, type: FieldType.Text, label: this.translate(CommonTranslationKey.CompanyNameLabel) },
			[CompanyIdentifiers.Status]: { identifier: CompanyIdentifiers.Status, type: FieldType.Choice, label: this.translate(CommonTranslationKey.CompanyStatusLabel) },
			// TODO Remove claims once DataDescriptor system in is Console/Discover
			[CompanyIdentifiers.Claims]: { identifier: CompanyIdentifiers.Claims, type: FieldType.Text, label: this.translate(CommonTranslationKey.ClaimsLabel) },
		} as Record<CompanyIdentifiers, DataPropertyInfo>;
	}

	private translate(key: string) {
		return this.translateService.instant(key) as string;
	}

}

