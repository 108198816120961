import { Injectable, inject } from '@angular/core';
import { DataType, FieldTemplate, FieldType, MessageColour, fieldTypeToDataType } from '@unifii/sdk';

import { DataDisplayOptionsValue, isDataDisplayDataSeedValue } from '../components';
import { DataDisplayInfo, DataDisplayListItem, RuntimeField, Scope } from '../models';

import { DataDisplayService } from './data-display';
import { FieldDescriptionServiceProvider } from './field-description-service-provider';

@Injectable()
export class FieldDescriptionService implements FieldDescriptionServiceProvider {

	/** Protected attributes in order to be accessible and reusable by children of this class */
	protected dataDisplayService = inject(DataDisplayService);
	
	/** Transform the value into a DataDisplayListItem
	 *
	 * @input value - the value to be transformed
	 * @input field - the source field of the value
	 * @input scope - the field/value scope
	 * @input format - overriding format for display
	*/
	transform(value: unknown, field: RuntimeField, _scope?: Scope, format?: string): DataDisplayListItem[] | null {

		let dataType = fieldTypeToDataType(field.type);

		// Guard from FieldType that don't hold data
		if (!dataType) {
			return null;
		}

		let detectedFormat = field.format;

		if ([DataType.GeoLocation, DataType.Address].includes(dataType) && field.visibleFields.includes('map')) {
			detectedFormat = 'map';
		}

		if (dataType === DataType.Choice && field.sourceConfig) {
			dataType = DataType.DataSeed;
		}

		const info = {
			type: dataType,
			format: format ?? detectedFormat,
			options: field.options,
			template: field.itemTemplate,
			sourceConfig: field.sourceConfig,
			decimals: field.type === FieldType.Number ? field.precision : undefined,
		} as DataDisplayInfo;

		// SmartForm display Options based field's data as selected/unselected UI (override default CVS)
		if (info.type === DataType.Boolean || info.type === DataType.Choice || info.type === DataType.MultiChoice) {
			info.mapToDataDisplayOptions = true;

			if (info.type === DataType.Boolean && field.template && [FieldTemplate.Buttons, FieldTemplate.BoolTickCross].includes(field.template)) {
				info.options = field.options.map((option) => {
					if (option.identifier === `${true}`) {
						return Object.assign({}, option, { colour: MessageColour.Success });
					}
					if (option.identifier === `${false}`) {
						return Object.assign({}, option, { colour: MessageColour.Error });
					}
					
					return option;
				});
			}
		}

		const data = this.dataDisplayService.displayAsDataDisplayValue(value, info);

		if (isDataDisplayDataSeedValue(data)) {
			// We display the DataSeed as a flat list of items
			return [{
				term: field.shortLabel ?? field.label,
				help: field.help,
				data: data.display ?? { selected: [], options: [] } satisfies DataDisplayOptionsValue,
			}, ...data.visibleItems.map((visibleItem) => Object.assign(visibleItem.item, { termTemplate: 'tertiary' }))];
		}

		return [{
			term: field.shortLabel ?? field.label,
			help: field.help,
			data,
		}];
	}

}
