import { AfterViewInit, Component, DestroyRef, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild, inject } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { UfControl } from '../../controls';
import { DOMEventHandler } from '../../services';

import { UfControlValueAccessor } from './uf-control-value-accessor';

@Component({
	selector: 'uf-number',
	templateUrl: './uf-number.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR, useExisting: UfNumberComponent, multi: true,
	}],
	styleUrls: ['./uf-input.less'],
})
export class UfNumberComponent extends UfControlValueAccessor<number> implements OnInit, AfterViewInit {

	@Input() name: string | null;
	@Input() label: string | null | undefined;
	@Input() placeholder: string | null | undefined;
	@Input() precision: number | string | null | undefined;
	@Input() autocomplete = Math.random().toString(36).substring(2, 18);
	
	@Output() override valueChange = new EventEmitter<number>();
	@Output() focusChange = new EventEmitter<boolean>();

	@ViewChild('input') private input: ElementRef<HTMLInputElement>;

	protected focused = false;
	protected numberControl = new UfControl();

	private domEventHandler = inject(DOMEventHandler);
	private destroy = inject(DestroyRef);
	private _val: number | null;
	private _lastKeyStroke: string;
	private _step: number | string = 'any'; // any as non-specified step for HTML input tag
	private lastCharacter: string | null;

	ngOnInit() {
		this.subscriptions.add(this.numberControl.valueChanges.subscribe((v: number | undefined | null) => {
			let value: number | undefined;

			if (v != null && !isNaN(+v)) {
				value = +v;
			}

			this.control.markAsDirty();
			this.control.setValue(value);
		}));
	}

	@Input() override set control(v: UfControl | null) {
		if (!v) {
			return;
		}

		super.control = v;
		this.numberControl.setValue(this.value, { emitEvent: false });
	}

	override get control(): UfControl {
		return super.control;
	}

	@Input() override set value(v: number | undefined | null) {
		if (this.lastCharacter === '.' || this.lastCharacter === 'delete') {
			return;
		}

		this.numberControl.setValue(v, { emitEvent: false });

		super.value = v;
	}

	override get value(): number | undefined | null {
		return super.value;
	}

	ngAfterViewInit() {
		this.domEventHandler.register({
			element: this.input,
			event: 'beforeinput',
			listener: (event) => { this.lastCharacter = event.inputType === 'deleteContentBackward' ? 'delete' : event.data; },
			destroy: this.destroy,
		});
	}

	@HostBinding('class.focused') get focusedClass() {
		return this.focused && !this.disabled;
	}

	@HostBinding('class.error') get errorClass() {
		return this.control.showError && !this.disabled;
	}

	@HostBinding('class.disabled') get disabledClass() {
		return this.disabled;
	}

	@HostBinding('class.value') get valueClass() {
		return !!this.value;
	}

	@Input() set step(v: number | string | undefined | null) {
		this._step = (typeof v === 'string' ? +v : v) ?? 'any';
	}

	get step(): number | string {
		return this._step;
	}

	override valueEmitPredicate(value: number | null, prev: number | null): boolean {

		const precision: number | null = (typeof this.precision === 'string' ? +this.precision : this.precision) ?? null;

		if (value != null) {
			if (isNaN(+value) && (this._lastKeyStroke === '-' || this._lastKeyStroke === '.')) {
				this.value = this._val;

				return false;
			}

			if (precision != null && this.countDecimals(+value) > precision) {
				return false;
			}
		}

		this._val = value;

		return super.valueEmitPredicate(value, prev);
	}

	protected onBlur() {
		if (this.value == null) {
			this.numberControl.setValue(undefined);
		}
		this.control.markAsTouched();
		this.lastCharacter = null;
		this.focused = false;

		this.focusChange.emit(false);
	}

	protected onFocus() {
		this.focused = true;
		this.focusChange.emit(true);
	}

	protected onInput(evt: Event) {
		const prev = this.control.value as string;
		const next: string = (evt.target as HTMLInputElement).value;

		// console.log('Input value "' + next + '" valid number', !isNaN(next));
		if (isNaN(next as unknown as number)) {
			(evt.target as HTMLInputElement).value = prev;
			evt.preventDefault();
			evt.stopPropagation();
		}
	}

	protected onPaste(evt: ClipboardEvent) {

		const clipboardText = evt.clipboardData?.getData('Text');
		let ok;

		if (clipboardText) {

			ok = true;

			for (const character of ['.', '-']) {
				// check for duplicate characters
				if ((evt.target as HTMLInputElement).value.includes(character) && clipboardText.includes(character)) {
					ok = false;
				}
			}

			// check for e character
			if (clipboardText.includes('e')) {
				ok = false;
			}
		}

		if (!ok) {
			evt.stopPropagation();
			evt.preventDefault();
		}
	}

	protected onKey(evt: KeyboardEvent) {
		let ok = true;

		// Block forbidden keys
		if (evt.key === '.' || evt.key === '-') { // handle dots and minuses
			// handle doubling up on dots or minuses
			if ((this._lastKeyStroke === '.' || this._lastKeyStroke === '-') && evt.key === this._lastKeyStroke) {
				ok = false;
			}

			// see if it's already present in the value
			if ((evt.target as HTMLInputElement).value.includes(evt.key)) {
				ok = false;
			}

		} else if (evt.key === 'e') { // handle e
			ok = false;
		}

		// Check result
		if (!ok) {
			evt.preventDefault();
			evt.stopPropagation();
		} else {
			this._lastKeyStroke = evt.key;
		}
	}

	private countDecimals(value: number) {

		if (!value || Math.floor(value.valueOf()) === value.valueOf()) {
			return 0;
		}

		return value.toString().split('.')[1]?.length ?? 0;
	}

}
