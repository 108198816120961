// https://github.com/marnusw/date-fns-tz/issues/183

import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DataSeed } from '@unifii/sdk';
import { listTimeZones } from 'timezone-support';

import { UfControl } from '../../controls';
import { CommonTranslationKey, TimezonesTranslationKey } from '../../translations';
import { getLocalTimeZone } from '../../utils';

import { UfControlValueAccessor } from './uf-control-value-accessor';

@Component({
	selector: 'uf-timezone',
	templateUrl: './uf-timezone.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR, useExisting: UfTimezoneComponent, multi: true,
	}],
}) export class UfTimezoneComponent extends UfControlValueAccessor<string> implements OnInit {

	@Output() override valueChange = new EventEmitter<string>();

	protected readonly translateService = inject(TranslateService);
	protected readonly placeholder = this.translateService.instant(CommonTranslationKey.TimeZoneInputPlaceholder) as string;
	protected internalControl = new UfControl();
	protected internalValue: DataSeed | null | undefined;
	protected options: DataSeed[] = [];

	private readonly timezones = listTimeZones().map(this.stringToDataSeed.bind(this));

	ngOnInit() {
		this.subscriptions.add(this.control.valueChanges.subscribe(() => {
			this.updateInternalValue();
		}));
	}

	@Input() override set value(v: string | undefined | null) {
		super.value = v;
		this.updateInternalValue();
	}

	override get value(): string | null | undefined {
		return super.value;
	}

	@Input() override set control(v: UfControl) {
		super.control = v;
		this.updateInternalValue();
	}

	override get control(): UfControl {
		return super.control;
	}

	protected override onDisabledChanges(isDisabled: boolean) {
		if (isDisabled) {
			this.internalControl.disable();
		} else {
			if (!this.control.value) {
				this.control.setValue(getLocalTimeZone(), { emitEvent: false });

				this.updateInternalValue();
			}

			this.internalControl.enable();
		}
	}

	protected selected(seed?: DataSeed | null) {
		this.control.setValue(seed?._id);
	}

	protected search(q: string) {

		if (!q) {
			this.options = [...this.timezones];

			return;
		}

		q = this.cleanString(q);

		this.options = this.timezones.filter(({ _display }) =>
			this.cleanString(_display).includes(q),
		);
	}

	// Remove anything but letters and number
	private cleanString(str: string): string {
		const regex = new RegExp(/[^a-zA-Z0-9]/g);

		return str.replace(regex, '').toLowerCase();
	}

	private updateInternalValue() {
		this.internalValue = this.value ? this.stringToDataSeed(this.value) : (this.value as null | undefined);
	}

	private stringToDataSeed(_id: string): DataSeed {
		let _display: string | undefined;

		try {
			// TODO does the translate service throw an error??
			_display = this.translateService.instant(TimezonesTranslationKey[_id as keyof typeof TimezonesTranslationKey]);
		} catch (e) {
			// Silent error
		}

		return { _id, _display: _display ?? _id };
	}

}
