import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ClaimConfig, DataSourceType, FieldType, Option, Role, RoleWithDisplay, getRoleWithDisplay } from '@unifii/sdk';

import { CompanyIdentifiers, DataSourceIdTo, UserInfoIdentifiers } from '../../constants';
import { DataDescriptor, DataDescriptorAdapter, DataDescriptorUsersType, DataPropertyDescriptor } from '../../models';
import { sourceConfigMappingsToSourceConfigMappingInfo } from '../../services';
import { displayDefaultMapping } from '../data-source';
import { FeatureFlagService } from '../feature-flag.service';

import { DataDescriptorAdapterCache } from './data-descriptor-adapter-cache';
import { claimConfigToDataPropertyDescriptor, clearPropertiesAndRegisterUnknownsAsSkipped, dataPropertyInfoToDataPropertyDescriptor, displayable, getClaimConfigs, getDefaultOptions, getDisplay, getIcon, getOperators, getPropertiesMap, inputFilterable, normalizeIdentifiersList, sortDataPropertyDescriptors, sortable, staticFilterable } from './data-descriptor-functions';
import { DataPropertyInfoService } from './data-property-info.service';

export interface UserDataDescriptorAdapterLoader {
	loadUserClaims(): Promise<ClaimConfig[]>;
	loadRoles(): Promise<Role[]>;
}
export const UserDataDescriptorAdapterLoader = new InjectionToken<UserDataDescriptorAdapterLoader>('UserDataDescriptorAdapterLoader');

export interface UserDataDescriptorPermissionController {
	canLoadUserClaims(): boolean;
	canLoadRoles(): boolean;
	canLoadCompanies(): boolean;
	canLoadHierarchy(): boolean;
	canLoadUsers(): boolean;
}
export const UserDataDescriptorPermissionController = new InjectionToken<UserDataDescriptorPermissionController>('UserDataDescriptorPermissionController');

@Injectable({ providedIn: 'root' })
export class UserDataDescriptorAdapter implements DataDescriptorAdapter {

	private readonly adapterDataType = DataDescriptorUsersType;

	constructor(
		private dataModelEntryService: DataPropertyInfoService,
		private featureFlagService: FeatureFlagService,
		private translate: TranslateService,
		private cache: DataDescriptorAdapterCache,
		@Inject(UserDataDescriptorAdapterLoader) private loader: UserDataDescriptorAdapterLoader,
		@Optional() @Inject(UserDataDescriptorPermissionController) private permissions: UserDataDescriptorPermissionController | null,
	) { }

	/**
	 * Analyze the entity 'User' with Tenant specific Claims
	 * @param identifiers optional whitelist of properties' identifier to analyze, all otherwise
	 */
	// eslint-disable-next-line complexity
	async getDataDescriptor(identifiers?: string[]): Promise<DataDescriptor> {

		// console.log(`DDUser `, properties);
		const normalizedIdentifiers = normalizeIdentifiersList(DataDescriptorUsersType, identifiers);
		const skippedProperties: Option[] = [];
		const userInfoReferences = new Map<string, DataPropertyDescriptor>();

		const userExtendedDataProperties = [
			...Object.values(this.dataModelEntryService.userInfoReferences),
			{ identifier: 'hasPassword', type: FieldType.Bool, label: 'Has password' },
			{ identifier: 'isActive', type: FieldType.Bool, label: 'Active' },
		].filter((i) => !normalizedIdentifiers || normalizedIdentifiers.has(i.identifier))
			// TODO Remove claims from the dataModelEntryService.userInfoReferences list
			.filter((f) => f.identifier !== UserInfoIdentifiers.Claims as string)
			.map((ref) => dataPropertyInfoToDataPropertyDescriptor(ref, this.adapterDataType, this.translate));

		for (const property of userExtendedDataProperties) {
			userInfoReferences.set(property.identifier, property);
		}

		// Check special entries limited by permissions

		// Requires LIST /roles permission
		if (userInfoReferences.has(UserInfoIdentifiers.Roles) && this.permissions?.canLoadRoles() === false) {
			skippedProperties.push({ identifier: UserInfoIdentifiers.Roles, name: 'No permission for /roles' });
			userInfoReferences.delete(UserInfoIdentifiers.Roles);
		}

		// Requires LIST & READ /hierarchy permission
		if (userInfoReferences.has(UserInfoIdentifiers.Units) && this.permissions?.canLoadHierarchy() === false) {
			skippedProperties.push({ identifier: UserInfoIdentifiers.Units, name: 'No permission for /hierarchy' });
			userInfoReferences.delete(UserInfoIdentifiers.Units);
		}

		// company
		if (!await this.featureFlagService.isEnabled('companies')) {
			const skippedCompanyReason = 'Company feature not enabled';

			// company
			if (userInfoReferences.has(UserInfoIdentifiers.Company)) {
				skippedProperties.push({ identifier: UserInfoIdentifiers.Company, name: skippedCompanyReason });
				userInfoReferences.delete(UserInfoIdentifiers.Company);
			}

			// company.id
			if (userInfoReferences.has(UserInfoIdentifiers.CompanyId)) {
				skippedProperties.push({ identifier: UserInfoIdentifiers.CompanyId, name: skippedCompanyReason });
				userInfoReferences.delete(UserInfoIdentifiers.CompanyId);
			}

			// company.name
			if (userInfoReferences.has(UserInfoIdentifiers.CompanyName)) {
				skippedProperties.push({ identifier: UserInfoIdentifiers.CompanyName, name: skippedCompanyReason });
				userInfoReferences.delete(UserInfoIdentifiers.CompanyName);
			}
		}

		for (const property of userInfoReferences.values()) {
			// Add roles options
			if (property.identifier === UserInfoIdentifiers.Roles as string) {
				const roles = await this.loadRoles();

				property.options = roles.map((r) => ({ identifier: r.name, name: r.display }));
			}

			// Inject a Company Data Source to allow data-source driven filtering
			if (property.identifier === UserInfoIdentifiers.Company as string) {
				property.sourceConfig = {
					type: DataSourceType.Company,
					...sourceConfigMappingsToSourceConfigMappingInfo([{
						type: FieldType.Text,
						from: CompanyIdentifiers.Id,
						to: DataSourceIdTo,
						label: this.dataModelEntryService.companyReferences[CompanyIdentifiers.Id].label,
					},
					displayDefaultMapping(CompanyIdentifiers.Name),
					]),
				};
			}
		}

		const userClaimConfigs = await getClaimConfigs(
			this.adapterDataType,
			skippedProperties,
			this.loadUserClaims.bind(this),
			this.loadUserClaim.bind(this),
			this.permissions?.canLoadUserClaims(),
			normalizedIdentifiers,
		);

		const userClaimsDataDescriptor = userClaimConfigs.map((claimConfig) => claimConfigToDataPropertyDescriptor(
			claimConfig,
			this.adapterDataType,
			this.translate,
		));

		// Sorted entries
		let entries = [
			...sortDataPropertyDescriptors([...userInfoReferences.values()]),
			...sortDataPropertyDescriptors(userClaimsDataDescriptor),
		];

		// Clear entries list
		entries = clearPropertiesAndRegisterUnknownsAsSkipped(entries, skippedProperties, normalizedIdentifiers);

		// Amend
		for (const property of entries) {
			property.display = getDisplay(property.identifier, property.label);
			property.icon = getIcon(property.identifier, property.type, this.adapterDataType);
			property.asDisplay = displayable(property, this.adapterDataType);
			property.options = getDefaultOptions(property.type, this.translate, property.options, property.sourceConfig);
			property.asSort = sortable(property, this.adapterDataType);
			property.asStaticFilter = staticFilterable(property, this.adapterDataType);
			property.asInputFilter = inputFilterable(property, this.adapterDataType);
			property.operators = getOperators(property.type, property.identifier, this.adapterDataType);

			if (property.identifier === UserInfoIdentifiers.Company as string) {
				property.asSort = false;
				property.asInputFilter = this.permissions?.canLoadCompanies() ?? true;
			}

			// TODO Restore after implementation of UNIFII-5364
			if (property.identifier === UserInfoIdentifiers.UnitPaths as string) {
				property.asInputFilter = false;
				property.asStaticFilter = false;
			}

			// User.units contains only the units identifiers, not useful for display
			if (property.identifier === UserInfoIdentifiers.Units as string) {
				property.asDisplay = false;
			}

			// Manager
			if (property.identifier === UserInfoIdentifiers.Manager as string) {
				property.asSort = false;
				property.asInputFilter = this.permissions?.canLoadUsers() ?? true;
			}

			// Is Test User
			if (property.identifier === UserInfoIdentifiers.IsTester as string) {
				property.asDisplay = false;
				property.asStaticFilter = false;
				property.asInputFilter = false;
			}
		}

		return {
			type: this.adapterDataType,
			propertyDescriptors: entries,
			propertyDescriptorsMap: getPropertiesMap(entries),
			isSearchable: true,
			skippedProperties,
		};
	}

	private loadUserClaims(): Promise<ClaimConfig[]> {

		let loader = this.cache.userClaims;

		if (!loader) {
			loader = this.loader.loadUserClaims();
			this.cache.userClaims = loader;
		}

		return loader;
	}

	private async loadUserClaim(type: string) {
		return (await this.loadUserClaims()).find((cc) => cc.type === type);
	}

	private loadRoles(): Promise<RoleWithDisplay<Role>[]> {

		let loader = this.cache.roles;

		if (!loader) {
			loader = this.loader.loadRoles().then((roles) => roles.map(getRoleWithDisplay));
			this.cache.roles = loader;
		}

		return loader;
	}

}
