import { ChangeDetectorRef, Component, OnInit, inject } from '@angular/core';
import { HierarchyUnitFormData, HierarchyUnitWithChildCount } from '@unifii/sdk';

import { ColumnGap, DataDisplayService, HierarchyUnitPickerModalComponent, HierarchyUnitPickerModalData, ModalService, Size, ToastService, UfControl, UfControlGroup, UfFormBuilder, getValueAsArray } from '@unifii/library/common';

@Component({
	selector: 'sc-hierarchy-inputs',
	templateUrl: './show-hierarchy-inputs.html',
	styleUrls: ['./show-hierarchy-inputs.less'],
})
export class ShowHierarchyInputsComponent implements OnInit {

	protected readonly sizes = Size;
	protected readonly columnGaps = ColumnGap;	
	protected group: UfControlGroup;
	protected showCascade = true;
	protected cascadeControl: UfControl;
	protected showSelector = true;
	protected selectorControl: UfControl;
	protected showUnitInput = true;
	protected unitInputControl: UfControl;
	protected showUnitInputBox = true;
	protected unitInputBoxControl: UfControl;
	protected showList = true;
	protected listControl: UfControl;
	protected showMulti = true;
	protected multiControl: UfControl;
	protected controls: UfControl[];
	protected ceiling: HierarchyUnitFormData | undefined;
	protected unselectableUnits: HierarchyUnitFormData[] | undefined;
	protected appliedValue: HierarchyUnitFormData | undefined;
	protected submitted = false;
	protected readOnly = false;
	protected isRequired = false;
	protected leavesOnly = false;
	protected activesOnly = false;
	protected siblings = false;
	protected visible = true;
	
	private dataDisplayService = inject(DataDisplayService);
	private toastService = inject(ToastService);
	private modalService = inject(ModalService);
	private cdr = inject(ChangeDetectorRef);
	private ufb = inject(UfFormBuilder);
	
	ngOnInit() {
		this.cascadeControl = this.ufb.control(null);
		this.selectorControl = this.ufb.control(null);
		this.unitInputControl = this.ufb.control(null);
		this.unitInputBoxControl = this.ufb.control(null);
		this.listControl = this.ufb.control(null);
		this.multiControl = this.ufb.control(null);

		this.controls = [
			this.cascadeControl,
			this.selectorControl,
			this.unitInputControl,
			this.unitInputBoxControl,
			this.listControl,
			this.multiControl,
		];

		this.group = this.ufb.group({
			cascade: this.cascadeControl,
			selector: this.selectorControl,
			unitInput: this.unitInputControl,
			unitInputBox: this.unitInputBoxControl,
			list: this.listControl,
			multi: this.multiControl,
		});
	}

	protected display(unit: HierarchyUnitWithChildCount): string {
		return `[${unit.id}] ${unit.label} - (${unit.state})`;
	}

	protected onValueChanged(source: string, unit: HierarchyUnitFormData | HierarchyUnitFormData[] | null) {
		this.toastService.info(`${source.toUpperCase()} => ${this.dataDisplayService.displayAsString(unit)}`);
	}

	protected onReadOnlyChanged() {
		if (this.readOnly) {
			this.group.disable();
		} else {
			this.group.enable();
		}
	}

	protected onSubmittedChanged() {
		for (const control of this.controls) {
			if (this.submitted) {
				control.markAsTouched();
				control.markAsDirty();
			} else {
				control.markAsUntouched();
				control.markAsPristine();
			}
		}
	}

	protected refresh() {
		this.visible = false;
		this.cdr.detectChanges();		
		this.setControlsValue(null);

		setTimeout(() => {
			this.visible = true;
			this.cdr.detectChanges();
		}, 1000);
	}

	protected setControlsValue(unit: HierarchyUnitFormData | HierarchyUnitFormData[] | null | undefined) {
		const units = getValueAsArray(unit);
		
		this.cascadeControl.setValue(units[0]);
		this.selectorControl.setValue(units[0]);
		this.unitInputControl.setValue(units[0]);
		this.unitInputBoxControl.setValue(units[0]);
		this.listControl.setValue(units);
		this.multiControl.setValue(units);

		this.appliedValue = units[0];
	}

	protected async openPicker() {
		const value = await this.modalService.openLarge(HierarchyUnitPickerModalComponent, {
			value: this.appliedValue,
			allowsClear: true,
			ceiling: this.ceiling,
			isRequired: this.isRequired,
			activesOnly: this.activesOnly,
			leavesOnly: this.leavesOnly,
			unselectableUnits: this.unselectableUnits,
			readOnly: this.readOnly,
			label: 'The picker label',
		} as HierarchyUnitPickerModalData);

		if (value === undefined) {
			return;
		}

		this.toastService.info(`Picker selected "${value?.id ?? 'null'}"`);
	}

}
