export enum CommonTranslationKey {
	AddItems = 'add_items',
	ActionPreviousMonth = 'action_previous_month',
	ActionNextMonth = 'action_next_month',
	AdvancedSearchLabel = 'advanced_search_label',
	ScanBarcodeLabel = 'scan_barcode_label',
	CameraCaptureError = 'camera_capture_error',
	CameraCaptureActionTakePhoto = 'camera_capture_action_take_photo',
	CreatePasswordLabelOldPassword = 'create_password_label_old_password',
	CreatePasswordLabelNewPassword = 'create_password_label_new_password',
	CreatePasswordLabelConfirmPassword = 'create_password_label_confirm_password',
	CreatePasswordLabelChangePasswordNextLogin = 'create_password_label_change_password_next_login',
	CreatePasswordActionGeneratePassword = 'create_password_action_generate_password',
	CreatePasswordErrorPwnedPassword = 'create_password_error_pwned_password',
	CreatePasswordErrorMinLength12 = 'create_password_error_min_length_12',
	CreatePasswordErrorMaxLength256 = 'create_password_error_max_length_256',
	CreatePasswordErrorNotMatch = 'create_password_error_not_match',
	DatePickerInputActionDone = 'date_picker_input_action_done',
	FiltersLabel = 'filters_label',
	PasswordStrengthLabel = 'password_strength_label',
	PasswordStrengthWeak = 'password_strength_weak',
	PasswordStrengthAverage = 'password_strength_average',
	PasswordStrengthModerate = 'password_strength_moderate',
	PasswordStrengthStrong = 'password_strength_strong',
	SsoErrorAzureAuthenticationFailedMessage = 'sso_error_azure_authentication_failed_message',
	SsoErrorAuth0AuthenticationFailedMessage = 'sso_error_auth0_authentication_failed_message',
	SsoErrorAuthenticaionFailedMessage = 'sso_error_authentication_failed_message',
	LoginErrorInvalidUsernameOrPassword = 'login_error_invalid_username_or_password_message',
	SearchInputPlaceholder = 'search_input_placeholder',
	TimePickerInputActionDone = 'time_picker_input_action_done',
	DropMenuLabel = 'drop_menu_label',
	ChoiceNoOptionsAvailable = 'choice_no_options_available',
	TableActionDataLoad = 'table_action_data_load',
	TableActionToggleVisibleColumn = 'table_action_toggle_visible_column',
	TableActionResetToDefault = 'table_action_reset_to_default',
	TableFeedbackDataErrorTitle = 'table_feedback_data_error_title',
	TableFeedbackDataErrorDescription = 'table_feedback_data_error_description',
	TableFeedbackDataErrorActionRetry = 'table_feedback_data_error_action_retry',
	TableFeedbackDataEmptyTitle = 'table_feedback_data_empty_title',
	TableFeedbackDataEmptyDescription = 'table_feedback_data_empty_description',
	TableFeedbackDataFilteredEmptyTitle = 'table_feedback_data_filtered_empty_title',
	TableFeedbackDataFilteredEmptyDescription = 'table_feedback_data_filtered_empty_description',
	TableToolbarSelectedRowsLabel = 'table_toolbar_selected_rows_label',
	TableFeedbackMaxiumSelections = 'table_feedback_maximum_selections',
	TimeZoneInputPlaceholder = 'time_zone_input_placeholder',
	DetectLocationErrorPermissionDenied = 'detect_location_error_permission_denied',
	DetectLocationErrorPositionUnavailable = 'detect_location_error_position_unavailable',
	DetectLocationErrorDetectTimedOut = 'detect_location_error_detect_timed_out',
	DetectLocationErrorUnknown = 'detect_location_error_unknown',
	FormDefinitionLoadError = 'form_definition_load_error',
	FormDataLoadError = 'form_data_load_error',
	FeedbackSuccess = 'feedback_success',
	FeedbackFail = 'feedback_fail',
	FeedbackModalConfirmTitle = 'feedback_modal_confirm_title',
	FeedbackModalConfirmMessage = 'feedback_modal_confirm_message',
	FeedbackModalAlertTitle = 'feedback_modal_alert_title',
	FormMetadataFieldIdLabel = 'form_metadata_field_id_label',
	FormMetadataFieldSeqIdLabel = 'form_metadata_field_seq_id_label',
	FormMetadataFieldCreatedByLabel = 'form_metadata_field_created_by_label',
	FormMetadataFieldCreatedAtLabel = 'form_metadata_field_created_at_label',
	FormMetadataFieldOpenedAtLabel = 'form_metadata_field_opened_at_label',
	FormMetadataFieldStoredAtLabel = 'form_metadata_field_stored_at_label',
	FormMetadataFieldCompletedAtLabel = 'form_metadata_field_completed_at_label',
	FormMetadataFieldOriginLabel = 'form_metadata_field_origin_label',
	FormMetadataFieldLastModifiedByLabel = 'form_metadata_field_last_modified_by_label',
	FormMetadataFieldLastModifiedAtLabel = 'form_metadata_field_last_modified_at_label',
	FormMetadataFieldStateLabel = 'form_metadata_field_state_label',
	FormMetadataFieldActionLabel = 'form_metadata_field_action_label',
	FormMetadataFieldResultLabel = 'form_metadata_field_result_label',
	FormMetadataFieldBucketLabel = 'form_metadata_field_bucket_label',
	FormMetadataFieldSeqLabel = 'form_metadata_field_seq_label',
	FormMetadataFieldDefinitionIdentifierLabel = 'form_metadata_field_definition_identifier_label',
	FormMetadataFieldDefinitionVersionLabel = 'form_metadata_field_definition_version_label',
	FormMetadataFieldHistoryLabel = 'form_metadata_field_history_label',
	FormMetadataFieldRevLabel = 'form_metadata_field_rev_label',
	FormMetadataFieldParentLabel = 'form_metadata_field_parent_label',
	FormMetadataFieldParentFormDataRepositoryLabel = 'form_metadata_field_parent_form_data_repository_label',
	FormMetadataFieldParentIdLabel = 'form_metadata_field_parent_id_label',
	FormMetadataFieldParentSeqIdLabel = 'form_metadata_field_parent_seq_id_label',
	FormMetadataFieldParentDefinitionIdentifierLabel = 'form_metadata_field_parent_definition_identifier_label',
	MarkdownEditorTextStylesLabel = 'markdown_editor_textstyles_label',
	MarkdownEditorNormalLabel = 'markdown_editor_normal_label',
	MarkdownEditorHeading1Label = 'markdown_editor_heading1_label',
	MarkdownEditorHeading2Label = 'markdown_editor_heading2_label',
	MarkdownEditorHeading3Label = 'markdown_editor_heading3_label',
	MarkdownEditorHeading4Label = 'markdown_editor_heading4_label',
	MarkdownEditorHeading5Label = 'markdown_editor_heading5_label',
	MarkdownEditorBoldLabel = 'markdown_editor_bold_label',
	MarkdownEditorItalicLabel = 'markdown_editor_italic_label',
	MarkdownEditorMoreFormattingLabel = 'markdown_editor_formatting_label',
	MarkdownEditorLinksLabel = 'markdown_editor_links_label',
	MarkdownEditorWebsiteLinkLabel = 'markdown_editor_website_label',
	MarkdownEditorEmailLinkLabel = 'markdown_editor_emaillink_label',
	MarkdownEditorPhoneLinkLabel = 'markdown_editor_phonelink_label',
	MarkdownEditorCodeBlockLabel = 'markdown_editor_codeblock_label',
	MarkdownEditorCodeLabel = 'markdown_editor_code_label',
	MarkdownEditorQuoteLabel = 'markdown_editor_quote_label',
	MarkdownEditorBulletListLabel = 'markdown_editor_bulletlist_label',
	MarkdownEditorNumberListLabel = 'markdown_editor_numberlist_label',
	MarkdownEditorPreviewLabel = 'markdown_editor_preview_label',
	MfaLabel = 'mfa_label',
	MfaEnabledLabel = 'mfa_enabled_label',
	MfaVirtualMfaLabel ='mfa_virtual_mfa_label',
	MfaSetupMessage = 'mfa_setup_message',
	MfaVerifyMessage = 'mfa_verify_message',
	MfaOpenAuthenticationAppMessage = 'mfa_open_authentication_app_message',
	MfaManualSetupMessage = 'mfa_manual_setup_message',
	MfaAdditionalInfoMessage = 'mfa_additional_info_message',
	MfaVerificationMessage = 'mfa_verification_message',
	MfaVerifyButtonLabel = 'mfa_verify_button_label',
	MfaRequiredLabel = 'mfa_required_label',
	MfaOptionalLabel = 'mfa_optional_label',
	MfaInvalidMessage = 'mfa_invalid_message',
	UserIdLabel = 'user_id_label',
	UserStatusLabel = 'user_status_label',
	UserInactiveLabel = 'user_inactive_label', // user status
	UserActiveLabel = 'user_active_label',
	UserPendingLabel = 'user_pending_label',
	UsernameLabel = 'username_label',
	UserAuthenticationLabel = 'user_authentication_label',
	UserHierarchyUnits = 'user_hierarchy_units',
	UserHierarchyUnitPaths = 'user_hierarchy_unit_paths',
	UserCanChangeUsernameLabel = 'user_can_change_username_label',
	UserCompanyIdLabel = 'user_company_id_label',
	UserCompanyNameLabel = 'user_company_name_label',
	UserTesterLabel = 'user_tester_label',
	FirstNameLabel = 'first_name_label',
	LastNameLabel = 'last_name_label',
	EmailLabel = 'email_label',
	PhoneLabel = 'phone_label',
	ManagerLabel = 'manager_label',
	AddressLabel = 'address_label',
	ClaimsLabel = 'claims_label',
	ClaimTypeLabel = 'claim_type_label',
	ClaimValueLabel = 'claim_value_label',
	CompanyLabel = 'company_label',
	RolesLabel = 'roles_label',
	SystemRolesLabel = 'system_roles_label',
	InternalLabel = 'internal_label',
	ExternalLabel = 'external_label',
	IsTesterLabel = 'is_tester_label',
	CompanyIdLabel = 'label_company_identifier',
	CompanyNameLabel = 'label_company_name',
	CompanyStatusLabel = 'label_company_status',
	CompanyStatusLabelActive = 'label_company_status_active',
	CompanyStatusLabelInactive = 'label_company_status_inactive',
	CompanyStatusLabelPending = 'label_company_status_pending',
	CollectionItemFieldIdLabel = 'collection_item_field_id_label',
	CollectionItemFieldLastModifiedAtLabel = 'collection_item_field_last_modified_at_label',
	CollectionItemFieldLastPublishedAtLabel = 'collection_item_field_last_published_at_label',
	CollectionItemFieldDefinitionIdentifierLabel = 'collection_item_field_definition_identifier_label',
	CollectionItemFieldPublishedVersionLabel = 'collection_item_field_published_version_label',
	CollectionItemFieldRevLabel = 'collection_item_field_rev_label',
	CollectionItemFieldStateLabel = 'collection_item_field_state_label',
	CollectionItemFieldTitleLabel = 'collection_item_field_title_label',
	UploadFileLabel = 'upload_file_label',
	UploadImageLabel = 'upload_image_label',
	UploadVideoLabel = 'upload_video_label',
	UploadAudioLabel = 'upload_audio_label',
	UploadErrorAlertTitle = 'upload_error_alert_title',
	UploadCancelledMessage = 'upload_cancelled_message',
	UploadErrorFileTypeDeniedMessage = 'upload_error_file_type_denied_message',
	UploadErrorFileToLargeMessage = 'upload_error_file_to_large_message',
	UploadErrorFailedToUploadMessage = 'upload_error_failed_to_upload_message',
	NotSupportedLabel = 'not_supported_label',
	SearchLabel = 'search_label',
	YesLabel = 'yes_label',
	NoLabel = 'no_label',
	PasswordLabel = 'password_label',
	PreviewLabel = 'preview_label',
	ValueLabel = 'value_label',
	ExpressionLabel = 'expression_label',
	ExpressionInvalidErrorMessage = 'expression_invalid_error_message',
	DateInvalidErrorMessage = 'error_invalid_date_message',
	DatetimeInvalidErrorMessage = 'error_invalid_datetime_message',
	DeleteAllItemsDialogTitle = 'delete_all_items_dialog_title',
	DeleteAllItemsDialogMessage = 'delete_all_items_dialog_message',
	TimeInvalidErrorMessage = 'error_invalid_time_message',
	MessagePrintForm = 'message_print_form',
	LabelCountOfItems = 'label_count_of_items',
	LabelFullForm = 'label_full_form',
	LabelNothingSelected = 'label_nothing_selected',
	LabelPrintForm = 'label_print_form',
	LabelSummary = 'label_summary',
	LabelTotal = 'label_total',
	HierarchyLabel = 'hierarchy_label',
	HierarchySelectorInvalidConfigurationMessage = 'hierarchy_selector_invalid_configuration_message',
	HierarchySelectorLoadErrorMessage = 'hierarchy_selector_load_error_message',
	SelectSelectableUnitMessage = 'select_selectable_unit_message',
	SelectLeafUnitMessage = 'select_leaf_unit_message',
	SelectActiveUnitMessage = 'select_active_unit_message',
	SelectDescendantUnitMessage = 'select_descendant_unit_message',
	SelectAll = 'select_all',
	DeselectAll = 'deselect_all',
	FinderUnitLabel = 'finder_unit_label',
	FinderColumnLabel = 'finder_column_label',
	FinderConfirmDeleteMessage = 'finder_confirm_delete_message',
	FinderConfirmDeleteMessageTitle = 'finder_confirm_delete_message_title',
	FinderAddLabel = 'finder_add_label',
	FinderEditLabel = 'finder_edit_label',
	FinderDeleteLabel = 'finder_delete_label',
	GeoLocationLatitudeShortLabel = 'geo_location_latitude_short_label',
	GeoLocationLongitudeShortLabel = 'geo_location_longitude_short_label',
	ContentTypeTypeLabel = 'content_type_type_label',
	ContentTypePageLabel = 'content_type_page_label',
	ContentTypeAssetLabel = 'content_type_asset_label',
	ContentTypeAttachmentLabel = 'content_type_attachment_label',
	ContentTypeUrlLabel = 'content_type_url_label',
	ContentTypeFormLabel = 'content_type_form_label',
	StepTypeLastViewedAtLabel = 'step_type_last_viewed_at_label',
	StepTypeLastCompletedAtLabel = 'step_type_last_completed_at_label',
	SearchUnitLabel = 'search_unit_label',
	SelectUnitLabel = 'select_unit_label',
}
