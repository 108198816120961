import { Inject, Injectable, Optional, inject } from '@angular/core';
import { Client, CompaniesClient, DataSourceType, ExternalDataSourcesClient, FormDataClient, ProjectContentOptions, ProjectContentOptionsInterface, PublishedContent, TenantClient, UsersClient } from '@unifii/sdk';

import { DataSourceLoader, DataSourceLoaderConfig, SourceConfig, SourceConfigBucket, SourceConfigCollection, SourceConfigCompany, SourceConfigExternal, SourceConfigNamed, SourceConfigUser, SourceConfigUserClaims } from '../../models';
import { ExpressionParser } from '../expression-parser';

import { BucketLoader } from './bucket-loader';
import { ClaimLoader } from './claim-loader';
import { CollectionLoader } from './collection-loader';
import { CompanyLoader } from './company-loader';
import { DataSourceConverter } from './data-source-converter';
import { ExternalLoader } from './external-loader';
import { ProvisioningLoader } from './provisioning-loader';

export type DataSourceLoaderFactory = (source: SourceConfig, config?: DataSourceLoaderConfig) => DataSourceLoader | undefined;

/**
 * DataLoaderFactory creates data-loaders based on Field.sourceConfig
 */
@Injectable()
export class DataLoaderFactory {

	private expressionParser = inject(ExpressionParser);
	private dataSourceConverter = inject(DataSourceConverter);
	private factoryMap = new Map<DataSourceType, DataSourceLoaderFactory>();
	private namedMap = new Map<string, DataSourceLoaderFactory>();

	constructor(
		client: Client,
		@Optional() @Inject(PublishedContent) content: PublishedContent | null,
		@Optional() @Inject(ProjectContentOptions) options: ProjectContentOptionsInterface | null,
	) {

		// User Claim loader
		this.replace(DataSourceType.UserClaims, (source: SourceConfig, config?: DataSourceLoaderConfig): DataSourceLoader =>
			new ClaimLoader(source as SourceConfigUserClaims, config, new TenantClient(client), this.dataSourceConverter));

		// Named loader based on namedMap entries
		this.replace(DataSourceType.Named, (source: SourceConfig, config?: DataSourceLoaderConfig): DataSourceLoader | undefined => {
			const sourceConfigNamed = source as SourceConfigNamed;
			const id = sourceConfigNamed.id;
			const dsl = this.namedMap.get(id);

			if (!dsl) {
				return;
			}

			return dsl(sourceConfigNamed, config);
		});

		// Users loader via Provisioning
		this.replace(DataSourceType.Users, (source: SourceConfig, config?: DataSourceLoaderConfig): DataSourceLoader =>
			new ProvisioningLoader(
				source as SourceConfigUser, 
				config, 
				new UsersClient(client), 
				this.dataSourceConverter, 
				this.expressionParser,
				options ?? { preview: false },
			));

		// Collection loader via PublishedContent
		if (content) {
			this.replace(DataSourceType.Collection, (source: SourceConfig, config?: DataSourceLoaderConfig): DataSourceLoader =>
				new CollectionLoader(source as SourceConfigCollection, config, content, this.dataSourceConverter, this.expressionParser));
		}

		// Externals loader
		if (options) {
			this.replace(DataSourceType.External, (source: SourceConfig, config?: DataSourceLoaderConfig): DataSourceLoader =>
				new ExternalLoader(source as SourceConfigExternal, config, new ExternalDataSourcesClient(client, options), this.dataSourceConverter, this.expressionParser));
		}

		// Company loader
		this.replace(DataSourceType.Company, (source: SourceConfig, config?: DataSourceLoaderConfig): DataSourceLoader =>
			new CompanyLoader(source as SourceConfigCompany, config, new CompaniesClient(client), this.dataSourceConverter, this.expressionParser));

		// Bucket loader
		if (options) {
			this.replace(DataSourceType.Bucket, (source: SourceConfig, config?: DataSourceLoaderConfig): DataSourceLoader => {
				const formDataClient = new FormDataClient(
					client,
					{
						bucket: (source as SourceConfigBucket).id,
						projectId: options.projectId,
						preview: options.preview,
					},
				);

				return new BucketLoader(source as SourceConfigBucket, config, formDataClient, this.dataSourceConverter, this.expressionParser);
			});
		}
	}

	replace(type: DataSourceType, factory: DataSourceLoaderFactory | null) {
		if (factory) {
			this.factoryMap.set(type, factory);
		}
	}

	registerNamed(name: string, factory: DataSourceLoaderFactory | null) {
		if (factory) {
			this.namedMap.set(name, factory);
		}
	}

	create(source?: SourceConfig, config?: DataSourceLoaderConfig): DataSourceLoader | undefined {

		if (!source) {
			return;
		}

		const factory = this.factoryMap.get(source.type);

		if (!factory) {
			return;
		}

		return factory(source, config);
	}

}
