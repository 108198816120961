<div class="uf-grid pad-sides">
	<h2 class="col-12">Hierarchy Cascade Selection Component</h2>
	<uf-hierarchy-unit [(value)]="ceiling" template="input" label="Ceiling" placeholder="Select a ceiling"
		class="col-6" />
	<uf-hierarchy-unit [value]="appliedValue" (valueChange)="applyValue($event)" template="input" label="Set Value"
		placeholder="Select a value to set" class="col-6" />
	<uf-hierarchy-multi-select [(value)]="unselectableUnits" label="Unselectable units" class="col-12" />
	<uf-checkbox [(value)]="isRequired" label="Required" class="col-2" />
	<uf-checkbox [(value)]="leavesOnly" label="Leafs only" class="col-2" />
	<uf-checkbox [(value)]="activesOnly" label="Actives only" class="col-2" />
	<uf-checkbox [(value)]="siblings" (valueChange)="refresh()" label="Siblings" class="col-2" />

	<uf-data-display-list class="col-12">
		<dt>Control.value</dt>
		<dd>{{control.value | dataDisplay}}</dd>
		<dt>Control status</dt>
		<dd>{{control.status}}</dd>
	</uf-data-display-list>

	<uf-hierarchy-cascade-selection *ngIf="visible" [control]="control" [ceiling]="ceiling" [isRequired]="isRequired"
		[leavesOnly]="leavesOnly" [activesOnly]="activesOnly" [unselectableUnits]="unselectableUnits"
		[nameProperty]="display.bind(this)" [mode]="siblings ? 'siblings' : 'single'"
		(valueChange)="onValueChanged($event)" class="col-12" />
</div>