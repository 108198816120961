import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FieldType } from '@unifii/sdk';

import { DateFormat } from '../../constants';
import { DateConverter } from '../../utils';

import { UfControlValueAccessor } from './uf-control-value-accessor';

@Component({
	selector: 'uf-date',
	templateUrl: './uf-date.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR, useExisting: UfDateComponent, multi: true,
	}],
	styleUrls: ['./uf-input.less', './uf-date.less'],
})
export class UfDateComponent extends UfControlValueAccessor<string> {

	@Input() name: string | null;
	@Input() label: string | null | undefined;
	@Input() placeholder: string | null | undefined;
	@Output() override valueChange = new EventEmitter<string>();

	protected readonly type = FieldType.Date;
	protected focused: boolean;

	private _format = DateFormat;
	private dateConverter = new DateConverter(this.type, this._format);

	@Input() override set value(v: string | undefined | null) {
		/**
		 * Same logic repeated in date, time, datetime inputs,
		 * maybe a possibility of adding a value set predicate to InputManger
		 */
		if (v != null) {
			v = this.dateConverter.normalizeModelValue(v);
			if (!v) {
				console.warn(`UfDateComponent.value.set: Skipped internal set for invalid value '${v}'!`);

				return;
			}
		}
		// Set value on ControlValueAccessor so internal flags are updated
		super.value = v;
	}

	override get value(): string | undefined | null {
		return super.value;
	}

	@Input() set format(v: string | null | undefined) {
		this.dateConverter = new DateConverter(this.type, v);
		this._format = this.dateConverter.displayFormat;
	}

	get format(): string {
		return this._format;
	}

	@HostBinding('class.focused') get focusedClass() {
		return this.focused && !this.disabled;
	}

	@HostBinding('class.error') get errorClass() {
		return this.control.showError && !this.disabled;
	}

	@HostBinding('class.disabled') get disabledClass() {
		return this.disabled;
	}

	@HostBinding('class.value') get valueClass() {
		return !!this.value;
	}

	override valueEmitPredicate(value: string | null, prev: string | null): boolean {
		/**
		 * Prevent null value from emitting undefined resulting in ExpressionChangedAfterItHasBeenCheckedError,
		 * InputManager will only emit undefined values (not null)
		 */
		return !(value == null && prev == null) && super.valueEmitPredicate(value, prev);
	}

}
