import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DataType, HIERARCHY_ROOT_ID_ALIAS, HierarchyUnitFormData, HierarchyUnitWithPath, isStringTrimmedNotEmpty } from '@unifii/sdk';
import { distinctUntilChanged } from 'rxjs';

import { UfControl } from '../../../controls';
import { HierarchyUnitIdentifier, HierarchyUnitProvider } from '../../../models';
import { DataDisplayService } from '../../../services';
import { CommonTranslationKey, SharedTermsTranslationKey } from '../../../translations';
import { areEquivalentHierarchyIdentifiers, hierarchyIdentifierToUnitId, toHierarchyUnitFormData } from '../../../utils';
import { UfControlValueAccessor } from '../uf-control-value-accessor';

/**
 * Add a lookup search to the Hierarchy Cascade Selection component.
 */
@Component({
	selector: 'uf-hierarchy-unit-selector',
	templateUrl: './hierarchy-unit-selector.html',
	providers: [
		{ provide: NG_VALUE_ACCESSOR, useExisting: HierarchyUnitSelectorComponent, multi: true },
	],
})
export class HierarchyUnitSelectorComponent extends UfControlValueAccessor<HierarchyUnitFormData> implements OnInit {

	@Input() leavesOnly: boolean | null | undefined;
	@Input() activesOnly: boolean | null | undefined;
	@Input() isRequired: boolean | null | undefined;
	@Input() hideSearch = false;
	@Output() override valueChange = new EventEmitter<HierarchyUnitFormData>();
	@Output() inProgress = new EventEmitter<boolean>();
	
	protected readonly commonTK = CommonTranslationKey;
	protected readonly sharedTK = SharedTermsTranslationKey;
	protected inputControl = new UfControl();
	protected searchOptions: HierarchyUnitWithPath[];

	private hierarchyUnitProvider = inject(HierarchyUnitProvider);
	private dataDisplayService = inject(DataDisplayService);
	private ceilingId: string | null | undefined;
	private _ceiling: HierarchyUnitIdentifier | null | undefined;
	private unselectableUnitsIds: string[] | undefined;
	
	@Input() override set value(v: HierarchyUnitFormData | null | undefined) {
		super.value = v;
		this.updateInputControl();
	}

	override get value(): HierarchyUnitFormData | null | undefined {
		return super.value;
	}

	@Input() override set control(v: UfControl) {
		super.control = v;
		this.updateInputControl();
	}

	override get control(): UfControl {
		return super.control;
	}

	@Input() set ceiling(v: HierarchyUnitIdentifier | null | undefined) {
		this._ceiling = v;
		const id = hierarchyIdentifierToUnitId(v);

		this.ceilingId = id !== HIERARCHY_ROOT_ID_ALIAS ? id : undefined;
	}

	get ceiling(): HierarchyUnitIdentifier | null | undefined {
		return this._ceiling;
	}

	@Input() set unselectableUnits(v: HierarchyUnitIdentifier[] | null | undefined) {
		if (!v?.length) {
			this.unselectableUnitsIds = undefined;
			
			return;
		}

		this.unselectableUnitsIds = v.map((id) => hierarchyIdentifierToUnitId(id));
	}

	ngOnInit() {
		this.onDisabledChanges(this.control.disabled);

		if (this.control.submitted) {
			this.inputControl.setSubmitted();
		}

		this.subscriptions.add(this.inputControl.valueChanges.pipe(
			distinctUntilChanged((prev, current) => areEquivalentHierarchyIdentifiers(prev, current)),
		).subscribe((unit: HierarchyUnitFormData | null) => {
			this.control.setValue(unit);
		}));
	}

	override valueEmitPredicate(value: HierarchyUnitFormData | null, prev: HierarchyUnitFormData | null): boolean {
		const hasChanged = !areEquivalentHierarchyIdentifiers(value, prev);
		
		if (hasChanged) {
			this.updateInputControl();
		}

		return super.valueEmitPredicate(value, prev);
	}

	// TODO replace for https://angular.dev/api/forms/AbstractControl#events in Angular 19
	markControlAsTouched() {
		this.control.markAsTouched();
		this.inputControl.markAsTouched();
	}

	protected override onDisabledChanges(disabled: boolean) {
		if (disabled) {
			this.inputControl.disable({ emitEvent: false });
		} else {
			this.inputControl.enable({ emitEvent: false });
		}
	}

	protected async onSearch(q?: string) {
		const options = (await this.hierarchyUnitProvider.search({
			q: isStringTrimmedNotEmpty(q) ? q.trim() : undefined,
			ceiling: this.ceilingId ?? undefined,
			active: this.activesOnly ? true : undefined,
			leaves: this.leavesOnly ? true : undefined,
		}));

		if (!this.unselectableUnitsIds) {
			this.searchOptions = options;
			
			return;
		}

		this.searchOptions = options.filter((unit) => !this.unselectableUnitsIds?.includes(unit.id));
	}

	protected onSearchValueChange(value: HierarchyUnitWithPath) {
		this.inputControl.setValue(toHierarchyUnitFormData(value));
	}

	protected displaySearchResult(v: HierarchyUnitFormData): string {
		return this.dataDisplayService.displayAsString(v, { type: DataType.HierarchyUnit }) ?? '';
	}

	private updateInputControl() {
		const equals = areEquivalentHierarchyIdentifiers(this.value, this.inputControl.value as HierarchyUnitFormData | undefined);
		
		if (!equals) {
			this.inputControl.setValue(this.value);
		}
	}

}
