<uf-progress [inProgress]="inProgress" class="large" />
<uf-panel *ngIf="control" class="stretch col">
	<div class="uf-app-bar">
		<button [title]="sharedTermsTK.ActionClose | translate" (click)="close()" ufAutofocus class="uf-action tertiary"
			type="button">
			<uf-icon name="close" />
		</button>
		<h3>{{label}}</h3>
	</div>
	<div class="grow pad">
		<uf-hierarchy-unit-selector [control]="control" [isRequired]="isRequired" [ceiling]="ceiling"
			[leavesOnly]="leavesOnly" [activesOnly]="activesOnly" [unselectableUnits]="unselectableUnits"
			(inProgress)="inProgress = $event" />
	</div>
	<div *ngIf="!inProgress" class="uf-form-actions">
		<button (click)="close()" type="button" class="uf-button tertiary left">
			{{ sharedTermsTK.ActionClose | translate }}
		</button>
		<ng-template [ngIf]="control.enabled">
			<button *ngIf="showsClear" (click)="clear()" type="button" class="uf-button right">
				{{ sharedTermsTK.ActionClear | translate }}
			</button>
			<button (click)="save()" type="button" class="uf-button primary">
				{{ sharedTermsTK.ActionSave | translate }}
			</button>
		</ng-template>
	</div>
</uf-panel>