<div [formGroup]="group" class="uf-grid pad-sides">
	<h2 class="col-12">Hierarchy Inputs</h2>
	<uf-hierarchy-unit [(value)]="ceiling" template="input" label="Ceiling" placeholder="Select a ceiling"
		class="col-6" />
	<uf-hierarchy-unit (valueChange)="setControlsValue($event)" template="input" label="Set Value"
		placeholder="Select a value to set" class="col-6" />
	<uf-hierarchy-multi-select [(value)]="unselectableUnits" label="Unselectable units"
		placeholder="Pick the unselectable units" class="col-12" />

	<div class="col-12 uf-divider doubled medium"></div>
	<uf-checkbox [(value)]="showCascade" label="Cascade" class="col-2" />
	<uf-checkbox [(value)]="showSelector" label="Selector" class="col-2" />
	<uf-checkbox [(value)]="showUnitInput" label="Input" class="col-2" />
	<uf-checkbox [(value)]="showUnitInputBox" label="Input box" class="col-2" />
	<uf-checkbox [(value)]="showList" label="List" class="col-2" />
	<uf-checkbox [(value)]="showMulti" label="Multi" class="col-2" />

	<div class="col-12 uf-divider doubled medium"></div>
	<uf-checkbox [(value)]="submitted" (valueChange)="onSubmittedChanged()" label="Submitted" class="col-2" />
	<uf-checkbox [(value)]="readOnly" (valueChange)="onReadOnlyChanged()" label="Read only" class="col-2" />
	<uf-checkbox [(value)]="isRequired" label="Required" class="col-2" />
	<uf-checkbox [(value)]="leavesOnly" label="Leafs only" class="col-2" />
	<uf-checkbox [(value)]="activesOnly" label="Actives only" class="col-2" />
	<uf-checkbox [(value)]="siblings" (valueChange)="refresh()" label="Siblings" class="col-2" />

	<div class="col-12 uf-divider doubled medium"></div>
	<h3 class="col-12">Hierarchy Cascade Selection (uf-hierarchy-cascade-selection) <uf-help
			content="Allow selection of single or siblings unit(s) via a sequence of select/autocomplete/chips" /></h3>
	<uf-hierarchy-cascade-selection *ngIf="visible && showCascade" [ceiling]="ceiling" [isRequired]="isRequired"
		[leavesOnly]="leavesOnly" [activesOnly]="activesOnly" [unselectableUnits]="unselectableUnits"
		[nameProperty]="display.bind(this)" [mode]="siblings ? 'siblings' : 'single'"
		(valueChange)="onValueChanged('cascade', $event)" formControlName="cascade" class="col-12" />


	<div class="col-12 uf-divider doubled medium"></div>
	<h3 class="col-12">Hierarchy Unit Selector (uf-hierarchy-unit-selector) <uf-help
			content="Allow selection of a single unit, optional 'search' lookup for quicker selection" /></h3>
	<div>ControlValue: {{selectorControl.value}}</div>
	<uf-hierarchy-unit-selector *ngIf="visible && showSelector" [ceiling]="ceiling" [isRequired]="isRequired"
		[leavesOnly]="leavesOnly" [activesOnly]="activesOnly" [unselectableUnits]="unselectableUnits"
		(valueChange)="onValueChanged('selector', $event)" formControlName="selector" class="col-12" />

	<div class="col-12 uf-divider doubled medium"></div>
	<h3 class="col-12">Hierarchy Unit Picker (uf-hierarchy-unit-picker-modal) <uf-help
			content="Modal wrapper of the Unit Selector" /></h3>
	<button (click)="openPicker()" class="uf-button" type="button">Open picker</button>

	<div class="col-12 uf-divider doubled medium"></div>
	<h3 class="col-12">Hierarchy Unit (uf-hierarchy-unit) <uf-help content="Single unit input using picker for edit" />
	</h3>
	<uf-hierarchy-unit *ngIf="visible && showUnitInput" [ceiling]="ceiling" [isRequired]="isRequired"
		[leavesOnly]="leavesOnly" [activesOnly]="activesOnly" [unselectableUnits]="unselectableUnits"
		(valueChange)="onValueChanged('input', $event)" formControlName="unitInput" class="col-6" template="input"
		placeholder="Template input" label="Unit Input" />
	<uf-hierarchy-unit *ngIf="visible && showUnitInputBox" [ceiling]="ceiling" [isRequired]="isRequired"
		[leavesOnly]="leavesOnly" [activesOnly]="activesOnly" [unselectableUnits]="unselectableUnits"
		(valueChange)="onValueChanged('input box', $event)" formControlName="unitInputBox" class="col-6" template="box"
		placeholder="Template box" label="Unit Input Box" />

	<div class="col-12 uf-divider doubled medium"></div>
	<h3 class="col-12">Hierarchy List (uf-hierarchy-list) <uf-help content="Multiple units input render as list" /></h3>
	<uf-hierarchy-list *ngIf="visible && showList" (valueChange)="onValueChanged('list', $event)" formControlName="list"
		class="col-12" label="List" placeholder="Fill it.." />

	<div class="col-12 uf-divider doubled medium"></div>
	<h3 class="col-12">Hierarchy Multi Select (uf-hierarchy-multi-select) <uf-help
			content="Multiple units input render as chips" /></h3>
	<uf-hierarchy-multi-select *ngIf="visible && showMulti" (valueChange)="onValueChanged('multi', $event)"
		formControlName="multi" class="col-12" label="Multi" placeholder="Fill it..." />

	<div class="col-12 uf-divider doubled medium" style="margin-bottom: 1rem;"></div>
</div>