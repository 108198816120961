import { Component, Injector, Input, inject } from '@angular/core';
import { ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { UfFormControl } from '../../controls';

@Component({
	selector: 'uf-error',
	templateUrl: './uf-error.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR, useExisting: UfErrorComponent, multi: true,
	}],
})
export class UfErrorComponent implements ControlValueAccessor {

	/** Parallel reduced implementation of Control value accessor due to no value involved*/
	@Input() formControlName: string;

	private injector = inject(Injector);
	private _control: UfFormControl;

	@Input() set control(v: UfFormControl) {
		this._control = v;
	}

	get control(): UfFormControl {
		if (this.formControlName) {
			return this.injector.get(ControlContainer).control?.get(this.formControlName) as UfFormControl;
		}

		return this._control;
	}

	// empty implementation as this is a read only component
	writeValue() { /** */ }
	registerOnChange() { /** */ }
	registerOnTouched() { /** */ }

}
