import { Injectable, inject } from '@angular/core';

import { CascadeSelectionDataSource, CascadeSelectionStep } from '@unifii/library/common';

import { WorldCity, WorldCountry, WorldDataService, WorldDataValue, WorldRegion, WorldState, WorldSubregion } from '../../../services';

Injectable({ providedIn: 'root' });
export class ShowCascadeSelectionDataSource implements CascadeSelectionDataSource<WorldDataValue> {

	private readonly stepsIsMultiple: boolean[] = [
		true, // region
		false, // sub-region
		true, // country
		false, // state
		false, // city
	];

	private worldDataService = inject(WorldDataService);

	async getNextStep(value: (WorldDataValue | WorldDataValue[])[]): Promise<CascadeSelectionStep<WorldDataValue> | undefined> {

		switch (value.length) {
			case 0:
				// regions
				return {
					label: 'Region',
					placeholder: 'Your journey starts here!',
					isMultiple: this.stepsIsMultiple[value.length],
					display: (region: WorldRegion) => `{${region.parent_id}.${region.id}}: ${region.name}`,
					predetermined: true,
					disabled: false, // true // to test the disabled step configuration feature,
					options: await this.worldDataService.queryRegions(),
				};
			case 1:
				// sub-region
				return {
					label: 'SubRegion',
					placeholder: 'Lots more to pick...',
					isMultiple: this.stepsIsMultiple[value.length],
					display: (subregion: WorldSubregion) => `{${subregion.parent_id}.${subregion.id}}: ${subregion.name}`,
					predetermined: false,
					search: (query: string | undefined) => this.worldDataService.querySubregions(query, this.getIds(value[0])),
				};
			case 2:
				// countries
				return {
					label: 'Country',
					placeholder: 'Just a bit more..',
					isMultiple: this.stepsIsMultiple[value.length],
					display: (country: WorldCountry) => `{${country.parent_id}.${country.id}}: ${country.name} [Currency: ${country.currency}]`,
					predetermined: false,
					search: (query: string | undefined) => this.worldDataService.queryCountries(query, this.getIds(value[1])),
				};
			case 3:
				// state
				return {
					label: 'State',
					placeholder: `We're mostly there, trust me...`,
					isMultiple: this.stepsIsMultiple[value.length],
					display: (state: WorldState) => `{${state.parent_id}.${state.id}}: ${state.name}`,
					predetermined: true,
					options: await this.worldDataService.queryStates(undefined, this.getIds(value[2])),
				};
			case 4:
				// city
				return {
					label: 'City',
					placeholder: `Finally, the last step`,
					isMultiple: this.stepsIsMultiple[value.length],
					display: (city: WorldCity) => `{${city.parent_id}.${city.id}}: ${city.name}`,
					predetermined: false,
					search: (query: string | undefined) => this.worldDataService.queryCities(query, this.getIds(value[3])),
				};
			default:
				return undefined;
		}
	}

	async generateRandomValue(steps?: number) {
		steps = (steps ?? (Math.random() * 100)) % 6;

		const value: (WorldDataValue | WorldDataValue[])[] = [];

		// Region
		if (steps > 0) {
			this.optionallyAddRandomPickedItem(
				await this.worldDataService.queryRegions(),
				value,
			);
		}

		// Subregion
		if (steps > 1 && value.length > 0) {
			this.optionallyAddRandomPickedItem(
				await this.worldDataService.querySubregions(undefined, this.getIds(value[0])),
				value,
			);
		}

		// Country
		if (steps > 2 && value.length > 1) {
			this.optionallyAddRandomPickedItem(
				await this.worldDataService.queryCountries(undefined, this.getIds(value[1])),
				value,
			);
		}

		// State
		if (steps > 3 && value.length > 2) {
			this.optionallyAddRandomPickedItem(
				await this.worldDataService.queryStates(undefined, this.getIds(value[2])),
				value,
			);
		}

		// City
		if (steps > 4 && value.length > 3) {
			this.optionallyAddRandomPickedItem(
				await this.worldDataService.queryCities(undefined, this.getIds(value[3])),
				value,
			);
		}
		
		return value;
	}

	private optionallyAddRandomPickedItem<T>(items: T[], target: unknown[]) {
		const picked = items[Math.floor(Math.random() * items.length)];

		const isMultiple = this.stepsIsMultiple[target.length];

		if (picked) {
			isMultiple ? target.push([picked]) : target.push(picked);
		}
	}

	private getIds(value: WorldDataValue | WorldDataValue[] | undefined): number | number[] | undefined {
		return Array.isArray(value) ? value.map((v) => v.id) : value?.id;
	}

}
