import { Injectable, inject } from '@angular/core';
import { DATE_TIME_DATA_FORMAT, HierarchyUnitExtended, HierarchyUnitWithPath, isNotNull } from '@unifii/sdk';
import { add, format } from 'date-fns';

import { HierarchyUnitIdentifier, HierarchyUnitProvider } from '../models';
import { hierarchyIdentifierToUnitId } from '../utils';

export const hierarchyCacheFactory = (): HierarchyCache =>
	inject(HierarchyCache, { optional: true }) ?? new HierarchyCache();

/**
 * HierarchyUnitCache caches units data
 * to improve performance when the same endpoint is called repeatedly.
 * Is provided as sibling to the component that use the cache mechanism
 */
@Injectable()
export class HierarchyCache {

	private readonly cacheLifetime: Duration = { minutes: 20 };
	private provider = inject(HierarchyUnitProvider);
	private _cacheExpiry: string | null;
	private unitsExtended = new Map<string, HierarchyUnitExtended>();
	private unitsWithPath = new Map<string, HierarchyUnitWithPath>();

	async getUnit(unitIdentifier: HierarchyUnitIdentifier): Promise<HierarchyUnitExtended | undefined> {
		this.checkCache();

		const id = hierarchyIdentifierToUnitId(unitIdentifier);
		let unit = this.unitsExtended.get(id);

		if (unit) {
			return unit;
		}

		unit = await this.provider.getUnit(id);

		if (unit) {
			this.unitsExtended.set(id, unit);

			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			const { childCount, ...unitWithPath } = unit;

			this.unitsWithPath.set(id, unitWithPath);
		}

		return unit;
	}

	async getUnits(unitIdentifiers: HierarchyUnitIdentifier[]): Promise<HierarchyUnitWithPath[]> {
		this.checkCache();

		const requestedIds = unitIdentifiers.map((id) => hierarchyIdentifierToUnitId(id));
		const newIds: string[] = [];
		const units = new Map<string, HierarchyUnitWithPath>;

		for (const id of requestedIds) {
			const cachedUnit = this.unitsWithPath.get(id);

			if (cachedUnit) {
				units.set(id, cachedUnit);
			} else {
				newIds.push(id);
			}
		}

		const loadedUnits = newIds.length ? await this.provider.getUnits(newIds) : [];

		for (const loadedUnit of loadedUnits) {
			units.set(loadedUnit.id, loadedUnit);
			this.unitsWithPath.set(loadedUnit.id, loadedUnit);
		}

		return requestedIds.map((id) => units.get(id)).filter(isNotNull);
	}

	private checkCache() {
		if (this.cacheExpiry < this.now) {
			this.unitsExtended.clear();
			this.unitsWithPath.clear();

			this._cacheExpiry = null;
		}
	}

	private get cacheExpiry(): string {
		if (!this._cacheExpiry) {
			this._cacheExpiry = format(add(new Date(), this.cacheLifetime), DATE_TIME_DATA_FORMAT);
		}

		return this._cacheExpiry;
	}

	private get now(): string {
		return format(new Date(), DATE_TIME_DATA_FORMAT);
	}

}
