<div *ngIf="ready" class="uf-grid pad-sides">
	<h2 class="col-12">Cascade Selection Component</h2>
	<pre class="col-12">Value: {{valueChanged}}</pre>
	<button (click)="setValue()" class="uf-button" type="button">Set random value</button>

	<!-- Control -->
	<ng-template [ngIf]="control" [formGroup]="group">
		<h2 class="col-12">Configured via Control</h2>
		<uf-checkbox [(value)]="enabled" (valueChange)="toggleEnabled()" label="Enabled" />

		<uf-cascade-selection [dataSource]="dataSource" [columns]="2" (valueChange)="onValueChange($event)"
			formControlName="control" class="col-12" />
	</ng-template>

</div>