import { ChangeDetectorRef, Component, Input, OnDestroy, inject } from '@angular/core';
import { Observable, Subscription, interval, of } from 'rxjs';
import { delay, filter, finalize, map, takeWhile } from 'rxjs/operators';

/**
 * Progress component, indicator that expresses a specified wait time
 *  - inProgress - use bool to show hide progress
 *  - progress - set numerical value for progress
 *  - start(value?: number) - programmatic way of starting progress
 *  - completed() - programmatic way of completing progress
 */
@Component({
	selector: 'uf-progress',
	templateUrl: './progress.html',
	styleUrls: ['./progress.less'],
})
export class ProgressComponent implements OnDestroy {

	private cdr = inject(ChangeDetectorRef);
	private animationSub: Subscription | null;
	private finalizeSub: Subscription | null;
	private _progress?: number | null; // Number between 0.1 and 1
	
	@Input() set inProgress(v: boolean) {

		if (v == null) {
			return;
		} else if (v === true) {
			this.start();
		} else {
			this.complete();
		}
	}

	get inProgress(): boolean {
		return this.progress != null && this.progress > 0;
	}

	@Input() set progress(v: number | null | undefined) {

		if (v === 1 && !this.animationRunning) {
			this.animateBar();
		}
		this._progress = v;

		this.cdr.detectChanges();
	}

	get progress(): number | null | undefined {
		return this._progress;
	}

	@Input() set completed(v: boolean | null | undefined) {
		if (v === true) {
			this.complete();
		}
	}

	ngOnDestroy() {
		this.finalizeSub?.unsubscribe();
		this.animationSub?.unsubscribe();
	}

	start(value = 0.1) {

		if (this.animationSub != null && !this.animationSub.closed) {
			return;
		}

		this.progress = value;
		this.animateBar();
	}

	complete() {

		if (!this.animationRunning) {
			return;
		}

		if (this.finalizeSub != null && !this.finalizeSub.closed) {
			this.finalizeSub.unsubscribe();
		}
		this.finalizeSub = this.finalizeObservable.subscribe(() => { this.progress = 1; });
	}

	private animateBar() {
		this.animationSub = this.animationLoop.subscribe((v) => { this.progress = v; });
	}

	private get animationLoop(): Observable<number> {

		return interval(400).pipe(
			filter((n) => this.isPrime(n)),
			takeWhile(() => this.progress != null && this.progress < 1),
			map(() => this.calcNextProgress(this.progress ?? undefined)),
			finalize(() => { this.progress = undefined; }));
	}

	private get finalizeObservable(): Observable<undefined> {
		return of(undefined).pipe(delay(200));
	}

	private calcNextProgress(v = 0.1): number {
		return (Math.random() * (1 - v) / 8) + v;
	}

	private isPrime(num: number): boolean {

		for (let i = 2; i < num; i++) {
			if (num % i === 0) {
				return false;
			}
		}

		return num > 1;
	}

	private get animationRunning(): boolean {
		return this.animationSub != null && !this.animationSub.closed;
	}

}
