import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, inject } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { HierarchyUnitFormData, HierarchyUnitWithChildCount, isNotNull } from '@unifii/sdk';
import { distinctUntilChanged } from 'rxjs';

import { UfControl } from '../../../controls';
import { HierarchyCascadeSelectionMode, HierarchyUnitIdentifier, HierarchyUnitProvider, UfHierarchyError } from '../../../models';
import { HierarchyService, HierarchyValidators, hierarchyCacheFactory } from '../../../services';
import { SharedTermsTranslationKey } from '../../../translations';
import { areEquivalentHierarchyIdentifiers, getValueAsArray, hierarchyIdentifierToUnitId, toHierarchyUnitFormData } from '../../../utils';
import { UfControlValueAccessor } from '../uf-control-value-accessor';

import { HierarchyCascadeSelectionDataSource } from './hierarchy-cascade-selection-data-source';

@Component({
	selector: 'uf-hierarchy-cascade-selection',
	templateUrl: './hierarchy-cascade-selection.html',
	providers: [
		{ provide: NG_VALUE_ACCESSOR, useExisting: HierarchyCascadeSelectionComponent, multi: true },
	],
})
export class HierarchyCascadeSelectionComponent extends UfControlValueAccessor<HierarchyUnitFormData | HierarchyUnitFormData[]> implements OnInit, OnChanges, OnDestroy {

	@Input() mode: HierarchyCascadeSelectionMode = 'single';
	@Input() activesOnly: boolean | null | undefined;
	@Input() leavesOnly: boolean | null | undefined;
	@Input() unselectableUnits: HierarchyUnitIdentifier[] | null | undefined;
	@Input() isRequired: boolean | null | undefined;
	@Input() nameProperty: string | ((option: HierarchyUnitWithChildCount) => string) = 'label';
	@Output() override valueChange = new EventEmitter<HierarchyUnitFormData | HierarchyUnitFormData[]>();
	@Output() inProgress = new EventEmitter<boolean>();

	protected readonly sharedTK = SharedTermsTranslationKey;
	protected dataSource: HierarchyCascadeSelectionDataSource;
	protected inputControl = new UfControl();
	
	private provider = inject(HierarchyUnitProvider);
	private cache = hierarchyCacheFactory();
	private service = inject(HierarchyService);
	private validators: HierarchyValidators | undefined;
	private skipUpdateInputControl = false;
	private _ceiling: HierarchyUnitIdentifier | null | undefined;
	private ceilingId: string | null;
	private _error: UfHierarchyError | undefined;
	private _ready = false;
	
	@Input() override set value(v: HierarchyUnitFormData | HierarchyUnitFormData[] | null | undefined) {
		super.value = v;

		if (this.ready) {
			void this.updateInputControl();
		}
	}

	override get value(): HierarchyUnitFormData | HierarchyUnitFormData[] | null | undefined {
		return super.value;
	}

	@Input() override set control(v: UfControl) {
		super.control = v;

		if (this.ready) {
			void this.updateInputControl();
		}
	}

	override get control(): UfControl {
		return super.control;
	}

	@Input() set ceiling(v: HierarchyUnitIdentifier | null | undefined) {
		this._ceiling = v;
		this.ceilingId = hierarchyIdentifierToUnitId(v);
	}

	get ceiling(): HierarchyUnitIdentifier | null | undefined {
		return this._ceiling;
	}

	protected set error(v: UfHierarchyError | undefined) {
		this._error = v;
		this.onDisabledChanges(this.control.disabled);
	}

	protected get error(): UfHierarchyError | undefined {
		return this._error;
	}

	protected set ready(v: boolean) {
		this._ready = v;
		this.inProgress.next(!v);
	}

	protected get ready(): boolean {
		return this._ready;
	}

	ngOnChanges(changes: SimpleChanges) {
		if (!this.ready) {
			return;
		}

		// Affects the data-source, full initialization requested
		if (changes.ceiling || changes.activesOnly || changes.mode) {
			void this.initCascadeSelectionComponent();

			return;
		}

		// Affect only the validators logic
		if (changes.isRequired || changes.leavesOnly || changes.unselectableUnits) {
			this.setValidators(this.control);
		}
	}

	async ngOnInit() {
		this.onDisabledChanges(this.control.disabled);

		await this.initCascadeSelectionComponent();

		this.subscriptions.add(this.inputControl.valueChanges.pipe(
			distinctUntilChanged((
				prev: HierarchyUnitWithChildCount[] | HierarchyUnitWithChildCount[][] | undefined,
				current: HierarchyUnitWithChildCount[] | HierarchyUnitWithChildCount[][] | undefined,
			) => areEquivalentHierarchyIdentifiers(prev, current)),
		).subscribe(
			// eslint-disable-next-line @typescript-eslint/no-misused-promises
			async(stepsValue: HierarchyUnitWithChildCount[] | HierarchyUnitWithChildCount[][] | undefined) => {
				this.control.markAsTouched();
			
				const lastSelectedUnits = stepsValue ? getValueAsArray(stepsValue[stepsValue.length - 1]) : [];
				const units = lastSelectedUnits.length ? (await Promise.all(lastSelectedUnits.map((unit) => this.provider.getUnit(unit.id)))).filter(isNotNull) : [];
			
				// When stepsValue is valorized and the last step match the ceiling skip the emit
				if (this.ceilingId && lastSelectedUnits.length === 1 && lastSelectedUnits[0]?.id === this.ceilingId) {
					this.control.setValue(null);
				
					return;
				}

				const inputControlValue = !units.length ? undefined : this.mode === 'single' ? units[0] : units;
				const controlValue = Array.isArray(inputControlValue) ? inputControlValue.map(toHierarchyUnitFormData) : inputControlValue ? toHierarchyUnitFormData(inputControlValue) : undefined;
				
				this.skipUpdateInputControl = true;
				this.control.setValue(controlValue);
			},
		));
	}

	override ngOnDestroy() {
		super.ngOnDestroy();
		if (this.validators) {
			this.control.removeValidators(this.validators.validators);
			this.control.removeAsyncValidators(this.validators.asyncValidators);
		}
	}

	override valueEmitPredicate(value: HierarchyUnitFormData | HierarchyUnitFormData[] | null, prev: HierarchyUnitFormData | HierarchyUnitFormData[] | null): boolean {

		const hasChanged = !areEquivalentHierarchyIdentifiers(value, prev);

		if (hasChanged && !this.skipUpdateInputControl) {
			void this.updateInputControl();
		}

		this.skipUpdateInputControl = false;

		return this.ready && hasChanged;
	}

	protected override onDisabledChanges(disabled: boolean) {
		if (disabled || this.error) {
			this.inputControl.disable({ emitEvent: false });
		} else {
			this.inputControl.enable({ emitEvent: false });
		}
	}

	protected override onControlChanged(prevControl: UfControl | null, currentControl: UfControl | null) {
		super.onControlChanged(prevControl, currentControl);

		if (this.validators && prevControl) {
			prevControl.removeValidators(this.validators.validators);
			prevControl.removeAsyncValidators(this.validators.asyncValidators);
		}

		if (this.ready && currentControl) {
			this.setValidators(currentControl);
		}
	}

	protected reset() {
		this.error = undefined;
		this.control.setValue(null);
	}

	protected retry() {
		this.error = undefined;
		void this.initCascadeSelectionComponent();
	}

	private async initCascadeSelectionComponent() {
		this.ready = false;
		this.error = undefined;

		const dataSource = new HierarchyCascadeSelectionDataSource(
			this.provider,
			this.cache,
			this.service,
			{
				mode: this.mode,
				ceiling: this.ceiling,
				activesOnly: this.activesOnly,
				nameProperty: this.nameProperty,
			},
		);

		try {
			await dataSource.init();
		} catch (error) {
			this.error = error as UfHierarchyError;
		}

		if (this.error) {
			return;
		}

		this.dataSource = dataSource;
		this.setValidators(this.control);
		await this.updateInputControl();
		this.ready = true;
	}

	private setValidators(control: UfControl) {
		if (this.validators) {
			control.removeValidators(this.validators.validators);
			control.removeAsyncValidators(this.validators.asyncValidators);
		}

		this.validators = this.service.createValidator({
			ceiling: this.ceiling,
			isRequired: !!this.isRequired,
			activesOnly: !!this.activesOnly,
			leavesOnly: !!this.leavesOnly,
			unselectableUnits: this.unselectableUnits,
		});
		
		control.addValidators(this.validators.validators);
		control.addAsyncValidators(this.validators.asyncValidators);
		control.updateValueAndValidity();
	}

	private async updateInputControl() {
		try {
			const stepsValue = await this.dataSource.getStepsValue(this.value);

			this.inputControl.setValue(stepsValue);
		} catch (error) {
			this.error = error as UfHierarchyError;
			console.error('updateInputControl error', this.error);
		}
	}

}
