import { AfterContentInit, Component, ContentChild, EventEmitter, HostBinding, Input, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { FormControl, FormRecord, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Dictionary, isDictionary } from '@unifii/sdk';

import { UfControl } from '../../controls';
import { RadioInputGroup, RadioInputTemplate } from '../../models';

import { RadioInputsComponent } from './radio-utilities/radio-inputs.component';
import { UfControlValueAccessor } from './uf-control-value-accessor';

@Component({
	selector: 'uf-radio',
	templateUrl: './uf-radio.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR, useExisting: UfRadioComponent, multi: true,
	}],
	styleUrls: ['./uf-radio.less'],
})
export class UfRadioComponent extends UfControlValueAccessor<any> implements AfterContentInit {

	@Input() id: string;
	@Input() label?: string | null;
	@Input() nameProperty: string | ((option: any) => string) = 'name';
	@Input() valueProperty?: string | null;
	@Input() trackBy: string;
	@Input() direction?: 'Row' | 'Column' | null = 'Row';
	@Input() boolTemplate: RadioInputTemplate = 'Radio';

	@Output() override valueChange = new EventEmitter();

	@ContentChild('radioOptions', { static: true })
	private customInputs: RadioInputGroup | undefined;

	@ViewChild('containerRef', { read: ViewContainerRef, static: true })
	private containerRef: ViewContainerRef;

	unmatchedValue: any; /** Value previously set that no longer exist in options */

	private groupName = this.generateGroupName();
	private inputControl = new FormControl();
	private inputControlGroup = new FormRecord({ [this.groupName]: this.inputControl });
	private _columns: number;
	private _rows: number;
	private _options: any[] = [];
	private defaultInputs: RadioInputsComponent;

	ngAfterContentInit() {

		this.onDisabledChanges(this.control.disabled);
		this.updateInput(this.value);

		if (this.customInputs == null) {
			const componentRef = this.containerRef.createComponent(RadioInputsComponent);

			this.defaultInputs = componentRef.instance;
		}

		// style inputs
		this.inputs.direction = this.direction ?? 'Row';
		this.inputs.columns = (typeof this.columns === 'string' ? +this.columns : this.columns) ?? 2;
		this.inputs.rows = typeof this.rows === 'string' ? +this.rows : this.rows;

		// function inputs
		this.inputs.groupName = this.groupName;
		this.inputs.formGroup = this.inputControlGroup;
		this.inputs.options = this.options ?? [];
		this.inputs.unmatchedValue = this.unmatchedValue;
		this.inputs.template = this.boolTemplate;

		// Subscribe to changes
		this.subscriptions.add(this.inputControl.valueChanges.subscribe((v) => { this.inputValueChange(v); }));
	}

	private get inputs(): RadioInputGroup {
		return this.customInputs ?? this.defaultInputs;
	}

	@Input() override set value(v: any) {
		super.value = v;
		this.updateInput(v);
	}

	override get value(): any {
		return super.value;
	}

	@Input() override set control(v: UfControl) {
		super.control = v;
		this.updateInput(this.value);
	}

	override get control(): UfControl {
		return super.control;
	}

	@Input() set options(v: any[] | null | undefined) {

		this._options = v ?? [];

		if (this.inputs != null) {
			this.inputs.options = this._options;
		}

		this.updateInput(this.value);
	}

	get options(): any[] | null | undefined {
		return this._options;
	}

	@Input() set columns(v: number | string | null | undefined) {

		if (!v) {
			return;
		}

		const numberV = typeof v === 'string' ? +v : v;

		this._columns = numberV;

		if (this.inputs != null) {
			this.inputs.columns = numberV;
		}
	}

	get columns(): number | string | null | undefined {
		return this._columns || 2;
	}

	@Input() set rows(v: number | string) {

		if (!v) {
			return;
		}

		const numberV = typeof v === 'string' ? +v : v;

		this._rows = numberV;

		if (this.inputs != null) {
			this.inputs.rows = numberV;
		}
	}

	get rows(): number | string {
		return this._rows || 1;
	}

	@HostBinding('attr.id') get elementId() {
		return this.id;
	}

	@HostBinding('class.disabled') get disabledClass() {
		return this.disabled;
	}

	override valueEmitPredicate(value: string | null, prev: string | null): boolean {
		this.updateInput(value);

		return super.valueEmitPredicate(value, prev);
	}

	protected override onDisabledChanges(disabled?: boolean) {

		if (disabled === true && this.inputControl.enabled) {
			this.inputControl.disable({ emitEvent: false });
		} else if (disabled === false && this.inputControl.disabled) {
			this.inputControl.enable({ emitEvent: false });
		}
	}

	private inputValueChange(inputValue: Dictionary<any> | string | null) {

		if (!this.control.dirty) {
			this.control.markAsDirty();
		}

		// condition added for fix already on dev ignore when merged
		if (!this.control.touched && inputValue != null) {
			this.control.markAsTouched();
		}

		const value = this.valueProperty && isDictionary(inputValue) ? inputValue[this.valueProperty] : inputValue;

		this.control.setValue(value);
	}

	// called set value(), set control(), set options
	private updateInput(value: any): any {

		const { matched, unmatched } = this.matchToOption(value);
		const next = matched || unmatched;

		if (next !== this.inputControl.value) {
			this.inputControl.setValue(next, { emitEvent: false });
		}
		this.unmatchedValue = unmatched;

		if (this.inputs != null) {
			this.inputs.unmatchedValue = this.unmatchedValue;
		}
	}

	private matchToOption(value: any): { matched: any; unmatched: any } {

		const matched = (this.options ?? []).find((option) => (
			value != null && (
			// Reference match
				(option === value) ||
				// match using trackBy
				(this.trackBy && option[this.trackBy] != null && option[this.trackBy] === value[this.trackBy]) ||
				// match by valueProperty
				(this.valueProperty && option[this.valueProperty] === value))));

		let unmatched;

		if (matched == null) {
			unmatched = value || null;
		}

		return { matched, unmatched };
	}

	private generateGroupName(): string {

		let text = '';
		const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

		for (let i = 0; i < 15; i++) {
			text += possible.charAt(Math.floor(Math.random() * possible.length));
		}

		return text;
	}

}
