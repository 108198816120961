import { Injectable, Injector, StaticProvider, Type, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Modal } from '../components/modal/modal';
// Break circular dependency
import { ModalContainerComponent } from '../components/modal/modal-container.component';
import { ModalAlertData, ModalAnchoredPosition, ModalConfig, ModalConfirmData, ModalDisplayType, ModalOptions } from '../components/modal/modal-types';
import { AlertModalComponent, ConfirmModalComponent } from '../components/modal/modals';
import { CommonTranslationKey, SharedTermsTranslationKey } from '../translations';

export type ModalDialogSize = 'fit' | 'medium' | 'large' | 'fullScreen';

@Injectable({ providedIn: 'root' })
export class ModalService {

	private modalContainer: ModalContainerComponent;
	private translate = inject(TranslateService);

	get defaultModalAlertData(): Readonly<ModalAlertData> {
		return Object.freeze({
			title: this.translate.instant(CommonTranslationKey.FeedbackModalAlertTitle) as string,
			confirmLabel: this.translate.instant(SharedTermsTranslationKey.ActionOk) as string,
			closeButtonLabel: this.translate.instant(SharedTermsTranslationKey.ActionClose) as string,
		} satisfies ModalAlertData);
	}

	get defaultModalConfirmData(): Readonly<ModalConfirmData> {
		return Object.freeze({
			title: this.translate.instant(CommonTranslationKey.FeedbackModalConfirmTitle) as string,
			message: this.translate.instant(CommonTranslationKey.FeedbackModalConfirmMessage) as string,
			cancelLabel: this.translate.instant(SharedTermsTranslationKey.ActionCancel) as string,
			confirmLabel: this.translate.instant(SharedTermsTranslationKey.ActionConfirm) as string,
		} satisfies ModalConfirmData);
	}

	registerContainer(container: ModalContainerComponent) {
		this.modalContainer = container;
	}

	openFit<Data, Result>(type: Type<Modal<Data, Result>>, data?: Data, options?: ModalOptions<Result>, providers?: StaticProvider[], injector?: Injector): Promise<Result | undefined> {
		return this.open(type, ModalDisplayType.Fit, data, options, providers, injector);
	}

	openMedium<Data, Result>(type: Type<Modal<Data, Result>>, data?: Data, options?: ModalOptions<Result>, providers?: StaticProvider[], injector?: Injector): Promise<Result | undefined> {
		return this.open(type, ModalDisplayType.Medium, data, options, providers, injector);
	}

	openLarge<Data, Result>(type: Type<Modal<Data, Result>>, data?: Data, options?: ModalOptions<Result>, providers?: StaticProvider[], injector?: Injector): Promise<Result | undefined> {
		return this.open(type, ModalDisplayType.Large, data, options, providers, injector);
	}

	openFullScreen<Data, Result>(type: Type<Modal<Data, Result>>, data?: Data, options?: ModalOptions<Result>, providers?: StaticProvider[], injector?: Injector): Promise<Result | undefined> {
		return this.open(type, ModalDisplayType.FullScreen, data, options, providers, injector);
	}

	// eslint-disable-next-line better-max-params/better-max-params
	openAnchor<Data, Result>(type: Type<Modal<Data, Result>>, event: Event, position: ModalAnchoredPosition, data?: Data, options?: ModalOptions<Result>, providers?: StaticProvider[], injector?: Injector): Promise<Result | undefined> {
		return this.open(type, ModalDisplayType.Anchored, data, options, providers, injector, event, position);
	}

	openConfirm(data?: ModalConfirmData) {

		const safeData = Object.assign(
			{},
			this.defaultModalConfirmData,
			data,
		);

		return this.open(ConfirmModalComponent, ModalDisplayType.Fit, safeData);
	}

	openAlert(data?: ModalAlertData) {

		const safeData = Object.assign(
			{},
			this.defaultModalAlertData,
			data,
		);

		return this.open(AlertModalComponent, ModalDisplayType.Fit, safeData);
	}

	close(config: ModalConfig<any, any>) {
		config.done();
		this.modalContainer.remove(config);
		this.modalContainer.focusLast();
	}

	closeLatest() {
		const latestConfig = this.modalContainer.pop();

		if (!latestConfig) {
			return;
		}

		this.close(latestConfig);
		this.modalContainer.focusLast();
	}

	closeAll() {
		let modalConfig = this.modalContainer.pop();
		
		while (modalConfig) {
			this.close(modalConfig);
			modalConfig = this.modalContainer.pop();
		}
	}

	// eslint-disable-next-line better-max-params/better-max-params
	private open<Data, Result>(
		type: Type<Modal<Data, Result>>,
		displayType: ModalDisplayType,
		data?: Data,
		options?: ModalOptions<Result>,
		providers?: StaticProvider[],
		injector?: Injector,
		event?: Event,
		position?: ModalAnchoredPosition,
	): Promise<Result | undefined> {
		return new Promise<Result | undefined>((resolve) => {
			this.modalContainer.push({
				type,
				data,
				event,
				position,
				providers: providers ?? [],
				injector,
				display: displayType,
				guard: options?.guard,
				update: options?.update,
				done: (result?: Result) => { resolve(result); },
			});
		});
	}

}
