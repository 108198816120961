import { Component, ViewChild, inject } from '@angular/core';
import { DataSeed } from '@unifii/sdk';

import { LocationProvider, UfControl, UfSearchResultComponent } from '@unifii/library/common';

import { SCDataService } from '../../services';

@Component({
	selector: 'sc-show-search-result',
	templateUrl: './show-search-result.html',
})
export class ShowSearchResultComponent {

	@ViewChild('addressSearch') addressSearch: UfSearchResultComponent;
	@ViewChild('fruitSearch') fruitSearch: UfSearchResultComponent;

	protected addressControl = new UfControl();
	protected fruitControl = new UfControl();
	protected enabled = true;
	protected addressOptions: DataSeed[];
	protected addresses: DataSeed[] = [];	
	protected fruitOptions: string[];
	protected fruits: string[] = [];

	private location = inject(LocationProvider);
	private data = inject(SCDataService);

	protected async search(q: string) {
		if (this.location.search) {
			const places = await this.location.search(q);

			this.addressOptions = places;
		}
	}

	protected async findFruits(q: string) {
		this.fruitOptions = await this.data.asyncSearchFruitNames(q, 1000);
		console.log(this.fruitOptions.length);
	}

	protected fruitNameProperty(fruitName: string): string {
		return `Custom display for ${fruitName}`;
	}

	protected clearSearchInput() {
		this.addressSearch.clear();
		this.fruitSearch.clear();
	}

	protected toggleEnable() {
		if (this.enabled) {
			this.addressControl.enable();
			this.fruitControl.enable();
		} else {
			this.addressControl.disable();
			this.fruitControl.disable();
		}
	}

}
