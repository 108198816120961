import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, inject } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { GeoLocation } from '@unifii/sdk';

import { LocationProvider } from '../../models';
import { GoogleLocationProvider, GoogleMapData } from '../../services';
import { SharedTermsTranslationKey } from '../../translations';

import { UfControlValueAccessor } from './uf-control-value-accessor';

@Component({
	selector: 'uf-google-map',
	templateUrl: './uf-google-map.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR, useExisting: UfGoogleMapComponent, multi: true,
	}],
	styleUrls: ['uf-google-map.less'],
})
export class UfGoogleMapComponent extends UfControlValueAccessor<GeoLocation> implements OnInit {

	@Output() override valueChange = new EventEmitter<GeoLocation>();

	protected readonly sharedTermsTK = SharedTermsTranslationKey;

	private locationProvider = inject(LocationProvider);
	private map: GoogleMapData | undefined;
	private googleLocationProvider: GoogleLocationProvider | undefined;
	
	@ViewChild('map') set mapElement(v: ElementRef) {
		this.createMap(v, this.value);
	}

	@Input() override set value(v: GeoLocation | null | undefined) {
		if (this.map && !this.patternUtil.isEqual(v, this.map.getMarkerLocation())) {
			this.map.setMarker(v);
		}
		super.value = v;
	}

	override get value(): GeoLocation | null | undefined {
		return super.value;
	}

	ngOnInit() {
		if (this.locationProvider instanceof GoogleLocationProvider) {
			this.googleLocationProvider = this.locationProvider;
		} else {
			console.warn('UfGoogleMapComponent - locationProvider is not of type GoogleLocationProvider');
		}
	}

	private createMap(element: ElementRef, marker?: GeoLocation | null) {
		if (!this.googleLocationProvider) {
			return;
		}

		this.map = new GoogleMapData(this.googleLocationProvider, element, marker ?? undefined);
		this.subscriptions.add(this.map.markerChange.subscribe((geoLocation) => { this.valueChange.emit(geoLocation); }));
	}

}
