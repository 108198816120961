<label *ngIf="label">{{label}}
	<ng-content></ng-content>
</label>
<div class="row">
	<div class="input-wrap">
		<input #input [formControl]="inputControl" [attr.name]="name" [attr.placeholder]="placeholder"
			[tabindex]="disabled ? -1 : 0" [attr.autocomplete]="autocomplete" [nameProperty]="nameProperty"
			[allowCustom]="allowCustom" [options]="options" [noResultsMsg]="noResultsMsg"
			[hasSearchConfigured]="hasSearchConfigured" [minSearchLength]="minSearchLength"
			[minSearchLengthMsg]="minSearchLengthMsg" [template]="listBoxTemplate" (optionSelected)="onselect($event)"
			type="text" listBoxResults />
		<div [class.input-icon--clickable]="control.value" class="input-icon">
			<uf-icon *ngIf="!control.value || disabled" name="search" />
			<uf-icon *ngIf="control.value && !disabled" [title]="sharedTermsTK.SearchActionClear | translate"
				(click)="delete(); control.markAsDirty()" class="clear-btn" name="backspace" />
		</div>
		<uf-error [control]="control" />
		<uf-underline *ngIf="!disabled" [class.active]="focused" />
		<uf-progress />
	</div>
	<ng-content select="[actions]"></ng-content>
</div>