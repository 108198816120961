<div class="uf-grid">

	<ng-template [ngIf]="!hideSearch && control.enabled">
		<uf-search-result [disabled]="control.disabled" [label]="commonTK.SearchUnitLabel | translate"
			[options]="searchOptions" [nameProperty]="displaySearchResult.bind(this)" (searchChange)="onSearch($event)"
			(valueChange)="onSearchValueChange($event)" class="col-12" />

		<h3 class="col-12">{{commonTK.SelectUnitLabel | translate}}</h3>
	</ng-template>

	<uf-hierarchy-cascade-selection [control]="inputControl" [ceiling]="ceiling" [isRequired]="isRequired"
		[activesOnly]="activesOnly" [leavesOnly]="leavesOnly" [unselectableUnits]="unselectableUnits"
		(inProgress)="inProgress.next($event)" mode="single" class="col-12" />

</div>