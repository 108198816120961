import { InjectionToken } from '@angular/core';
import { ErrorType, HierarchyChildrenSearchOptions, HierarchySearchOptions, HierarchyUnitExtended, HierarchyUnitFormData, HierarchyUnitWithChildCount, HierarchyUnitWithPath, UfError } from '@unifii/sdk';

export type HierarchyUnitIdentifier = string | { id: string };

export type HierarchyCascadeSelectionMode = 'single' | 'siblings';

export class UfHierarchyError extends UfError {

	override data: {
		showRetry?: boolean;
		showClear?: boolean;
	};

	constructor(message: string, showRetry: boolean, showClear: boolean) {
		super(message, ErrorType.Unknown, { showRetry, showClear });
	}

}

export type HierarchyValidatorOptions = {
	isRequired?: boolean | null;
	leavesOnly?: boolean | null;
	activesOnly?: boolean | null;
	ceiling?: HierarchyUnitIdentifier | HierarchyUnitIdentifier[] | null;
	unselectableUnits?: HierarchyUnitIdentifier[] | null;
};

export interface HierarchyUnitProvider {
	search(params?: HierarchySearchOptions): Promise<HierarchyUnitWithPath[]>;
	getUnit(id: string): Promise<HierarchyUnitExtended | undefined>;
	getUnits(ids: string[]): Promise<HierarchyUnitWithPath[]>;
	getChildren(parentId: string, params?: HierarchyChildrenSearchOptions): Promise<HierarchyUnitWithChildCount[]>;
}

export const HierarchyUnitProvider = new InjectionToken<HierarchyUnitProvider>('HierarchyUnitLoader');

export interface HierarchyUnitPickerModalData {
	/** picker input current value */
	value?: HierarchyUnitFormData | null;
	/** unit preselected when the picker dialog opens and no 'value' is set */
	preselected?: HierarchyUnitIdentifier | null;
	/** allow action clear for the 'value' in input */
	allowsClear?: boolean | null;
	/** limits the selection to descendant units only */
	ceiling?: HierarchyUnitIdentifier | null;
	/** picker input label */
	label?: string | null;
	/** picker input is readonly */
	readOnly?: boolean | null;
	/** add a validator to make the selection required */
	isRequired?: boolean | null;
	/** inactive units won't display as selectable options */
	activesOnly?: boolean | null;
	/** add a validator that allows only leaf units to be selected */
	leavesOnly?: boolean | null;
	/** add a validator to forbid selection of a specific list of units */
	unselectableUnits?: HierarchyUnitIdentifier[] | null;
	/** add a validator to limit selection of unit to the sections of the tree identified by this list of units' identifier */
	matchOrDescendantsUnits?: HierarchyUnitIdentifier[] | null;
}
