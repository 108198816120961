import { Component, ElementRef, EventEmitter, HostBinding, Input, Output, ViewChild, inject } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { HierarchyUnitFormData } from '@unifii/sdk';

import { HierarchyUnitIdentifier, HierarchyUnitPickerModalData } from '../../models';
import { ModalService } from '../../services';
import { SharedTermsTranslationKey } from '../../translations';

import { HierarchyUnitPickerModalComponent } from './hierarchy-utilities';
import { UfControlValueAccessor } from './uf-control-value-accessor';

@Component({
	selector: 'uf-hierarchy-unit',
	templateUrl: './uf-hierarchy-unit.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR, useExisting: UfHierarchyUnitComponent, multi: true,
	}],
	styleUrls: ['./uf-input.less', './uf-hierarchy-unit.less'],
})
export class UfHierarchyUnitComponent extends UfControlValueAccessor<HierarchyUnitFormData> {

	@HostBinding('class.border-none') get borderNone() {
		return this.template === 'input';
	}
	
	@ViewChild('showPickerButton') buttonElement: ElementRef<HTMLButtonElement> | null;

	@Input() label: string | null | undefined;
	@Input() placeholder: string | null | undefined;
	/** ceiling unit to limit the selection to descendant units only */
	@Input() ceiling: HierarchyUnitIdentifier | null | undefined;
	/** add a validator that allows only leaf units to be selected */
	@Input() leavesOnly: boolean | null | undefined;
	/** inactive units won't display as selectable options */
	@Input() activesOnly: boolean | null | undefined;
	/** add a validator to make the selection required */
	@Input() isRequired = false;
	/** add a validator to forbid selection of a specific list of units */
	@Input() unselectableUnits: HierarchyUnitIdentifier[] | null | undefined;
	/** unit preselected when the picker dialog opens */
	@Input() preselected: HierarchyUnitIdentifier | null | undefined;
	/** add a validator to limit selection of unit to the sections of the tree identified by this list of units' identifier */
	@Input() matchOrDescendantsUnits: HierarchyUnitIdentifier[] | null | undefined;
	/** 'input' render the component inline, 'box' render it as a bordered box, 'box' is the default */
	@Input() template: 'box' | 'input' = 'box';	
	@Output() override valueChange = new EventEmitter<HierarchyUnitFormData>();

	protected readonly sharedTK = SharedTermsTranslationKey;

	private isPickerOpen = false;
	private modalService = inject(ModalService);

	protected async showPicker() {

		if (this.disabled || this.isPickerOpen) {
			return;
		}

		this.isPickerOpen = true;

		const selectedUnit = await this.modalService.openLarge(
			HierarchyUnitPickerModalComponent,
			{
				value: this.value,
				label: this.label,
				allowsClear: true,
				ceiling: this.ceiling,
				preselected: this.preselected,
				leavesOnly: this.leavesOnly,
				unselectableUnits: this.unselectableUnits,
				matchOrDescendantsUnits: this.matchOrDescendantsUnits,
				isRequired: this.isRequired,
				activesOnly: this.activesOnly,
			} satisfies HierarchyUnitPickerModalData,
		);

		this.isPickerOpen = false;

		if (selectedUnit === undefined) {
			return;
		}

		this.applyValue(selectedUnit);
		this.buttonElement?.nativeElement.focus();
	}

	protected applyValue(value: HierarchyUnitFormData | null) {
		this.control.setValue(value);
		this.control.markAsTouched();
	}

	@HostBinding('class.error') get errorClass() {
		return this.control.showError && !this.disabled;
	}

	@HostBinding('class.disabled') get disabledClass() {
		return this.disabled;
	}

	@HostBinding('class.value') get valueClass() {
		return !!this.value;
	}

}
