import { Injectable, inject } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { HierarchyUnitExtended, HierarchyUnitState, isArrayOfType, isDictionary, isHierarchyPath, isNumber, isValueOfStringEnumType } from '@unifii/sdk';

import { HierarchyUnitIdentifier, HierarchyValidatorOptions, UfHierarchyError } from '../models';
import { CommonTranslationKey, SharedTermsTranslationKey } from '../translations';
import { ValidatorFunctions, getValueAsArray, hierarchyIdentifierToUnitId, isHierarchyUnitIdentifier } from '../utils';

import { hierarchyCacheFactory } from './hierarchy-cache';

type UnitWithChildCount = Pick<HierarchyUnitExtended, 'childCount' | 'id'>;
type UnitWithState = Pick<HierarchyUnitExtended, 'state' | 'id'>;
type UnitWithPath = Pick<HierarchyUnitExtended, 'path' | 'id'>;

export type HierarchyValidators = {
	validators: ValidatorFn[];
	asyncValidators: AsyncValidatorFn[];
}

const isUnitWithChildCount = (value: unknown): value is UnitWithChildCount =>
	isDictionary(value) && isNumber(value.childCount) && isHierarchyUnitIdentifier(value);

const isUnitWithState = (value: unknown): value is UnitWithState =>
	isDictionary(value) && isValueOfStringEnumType(HierarchyUnitState)(value.state) && isHierarchyUnitIdentifier(value);

const isUnitWithPath = (value: unknown): value is UnitWithPath =>
	isDictionary(value) && isHierarchyPath(value.path) && isHierarchyUnitIdentifier(value);

@Injectable({ providedIn: 'root' })
export class HierarchyService {

	private translateService = inject(TranslateService);
	private hierarchyCache = hierarchyCacheFactory();

	/**
	 * Return validators based on the rules requested.
	 * The validators expect a nullable unit-like or units-like array as value.
	 * @param options configuration of rules to validate
	 * @returns set of validators matching the input options
	 */
	createValidator(options: HierarchyValidatorOptions): HierarchyValidators {
		const validators: ValidatorFn[] = [];
		const asyncValidators: AsyncValidatorFn[] = [];
		const ceilings = getValueAsArray(options.ceiling);

		if (options.isRequired) {
			validators.push(this.buildIsRequiredValidator());
		}

		if (options.leavesOnly) {
			asyncValidators.push(this.buildLeavesOnlyValidator());
		}

		if (options.activesOnly) {
			asyncValidators.push(this.buildActivesOnlyValidator());
		}

		if (ceilings.length) {
			asyncValidators.push(this.buildCeilingsValidator(ceilings));
		}

		if (options.unselectableUnits?.length) {
			validators.push(this.buildUnselectableUnitsValidator(options.unselectableUnits));
		}
		
		return { validators, asyncValidators };
	}

	private buildIsRequiredValidator(): ValidatorFn {
		return ValidatorFunctions.custom(
			(value: unknown) => {
				if (value == null) {
					return false;
				}

				const values = getValueAsArray(value);

				return isArrayOfType(values, isHierarchyUnitIdentifier) && !!values.length;
			},
			this.translateService.instant(SharedTermsTranslationKey.ValidatorValueRequired),
		);
	}

	private buildUnselectableUnitsValidator(unselectableUnits: HierarchyUnitIdentifier[]): ValidatorFn {
		const unselectableUnitsIds = unselectableUnits.map((i) => hierarchyIdentifierToUnitId(i));

		return ValidatorFunctions.custom(
			(value: unknown) => {
				const values = getValueAsArray(value);

				if (!isArrayOfType(values, isHierarchyUnitIdentifier)) {
					return false;
				}

				return values.every((unit) => !unselectableUnitsIds.includes(hierarchyIdentifierToUnitId(unit)));
			},
			this.translateService.instant(CommonTranslationKey.SelectSelectableUnitMessage),
		);
	}

	private buildLeavesOnlyValidator(): AsyncValidatorFn {
		return async(control: AbstractControl): Promise<ValidationErrors | null> => {
				
			const values = getValueAsArray(control.value as unknown);

			if (!values.length) {
				return null;
			}

			const error = { message: this.translateService.instant(CommonTranslationKey.SelectLeafUnitMessage) as string };

			if (!isArrayOfType(values, isHierarchyUnitIdentifier)) {
				return error;
			}

			let unitWithChildCount: UnitWithChildCount | undefined;

			for (const unitIdentifier of values) {
				if (!isUnitWithChildCount(unitIdentifier)) {
					unitWithChildCount = await this.hierarchyCache.getUnit(unitIdentifier);
				} else {
					unitWithChildCount = unitIdentifier;
				}

				if (!unitWithChildCount || unitWithChildCount.childCount) {
					return error;
				}
			}

			return null;
		};
	}

	private buildActivesOnlyValidator(): AsyncValidatorFn {
		return async(control: AbstractControl): Promise<ValidationErrors | null> => {
				
			const values = getValueAsArray(control.value as unknown);

			if (!values.length) {
				return null;
			}

			const error = { message: this.translateService.instant(CommonTranslationKey.SelectActiveUnitMessage) as string };

			if (!isArrayOfType(values, isHierarchyUnitIdentifier)) {
				return error;
			}

			let unitWithState: UnitWithState | undefined;

			for (const unitIdentifier of values) {
				if (!isUnitWithState(unitIdentifier)) {
					unitWithState = await this.hierarchyCache.getUnit(unitIdentifier);
				} else {
					unitWithState = unitIdentifier;
				}

				if (!unitWithState || unitWithState.state === HierarchyUnitState.Inactive) {
					return error;
				}
			}

			return null;
		};
	}

	private buildCeilingsValidator(ceilingsIdentifiers: HierarchyUnitIdentifier[]): AsyncValidatorFn {
		const ceilings = ceilingsIdentifiers.map((i) => hierarchyIdentifierToUnitId(i));
		
		return async(control: AbstractControl): Promise<ValidationErrors | null> => {
				
			const values = getValueAsArray(control.value as unknown);

			if (!values.length) {
				return null;
			}

			const error = {
				message: this.translateService.instant(
					CommonTranslationKey.SelectDescendantUnitMessage,
					{ descendants: ceilings.join(', ') },
				) as string,
			};

			if (!isArrayOfType(values, isHierarchyUnitIdentifier)) {
				return error;
			}

			let unitWithPath: UnitWithPath | undefined;

			for (const unitIdentifier of values) {
				if (!isUnitWithPath(unitIdentifier)) {
					unitWithPath = await this.hierarchyCache.getUnit(unitIdentifier);
				} else {
					unitWithPath = unitIdentifier;
				}

				if (!unitWithPath || !unitWithPath.path.some((step) => ceilings.includes(step.id))) {
					return error;
				}
			}

			return null;
		};
	}

	get misconfiguredError(): UfHierarchyError {
		return new UfHierarchyError(
			this.translateService.instant(CommonTranslationKey.HierarchySelectorInvalidConfigurationMessage),
			true,
			false,
		);
	}

	get selectorLoadError(): UfHierarchyError {
		return new UfHierarchyError(
			this.translateService.instant(CommonTranslationKey.HierarchySelectorLoadErrorMessage),
			true,
			false,
		);
	}

	get forbiddenError(): UfHierarchyError {
		return new UfHierarchyError(
			this.translateService.instant(SharedTermsTranslationKey.ErrorForbidden),
			true,
			false,
		);
	}

	get invalidError(): UfHierarchyError {
		return new UfHierarchyError(
			this.translateService.instant(CommonTranslationKey.SelectSelectableUnitMessage),
			false,
			true,
		);
	}

}
