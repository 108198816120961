<label *ngIf="label" class="gap-sm-bottom pad-sm-left">{{label}}
	<ng-content></ng-content>
</label>
<p *ngIf="!value?.length" class="small pad-sm-left gap-sm-top gap-none-bottom light">{{ placeholder }}</p>
<ul class="uf-list transparent gap-sm-bottom">
	<li *ngFor="let unit of value; let i = index" class="uf-list-item small">
		<p class="small grow pad-sm-left">{{ unit.path | dataDisplay: { type: 'HierarchyPath'} }}</p>
		<ng-template [ngIf]="!disabled">
			<button [title]="sharedTermsTK.ActionEdit | translate" (click)="addOrEdit(unit, i)" type="button"
				data-type="chip" class="uf-action tertiary">
				<uf-icon name="edit" />
			</button>
			<button [title]="sharedTermsTK.ActionRemove | translate" (click)="remove(i)" type="button" data-type="chip"
				class="uf-action tertiary">
				<uf-icon name="delete" />
			</button>
		</ng-template>
	</li>
</ul>
<button [disabled]="disabled" (click)="addOrEdit()" type="button" class="uf-button x-small gap-top gap-right right">
	{{ sharedTermsTK.ActionAdd | translate }}
</button>