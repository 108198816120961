import { AstNode, DataSeed, ProjectContentOptionsInterface, Q, Query, UserInfo, UsersClient, isNotNull } from '@unifii/sdk';

import { DataSourceIdTo, UserInfoIdentifiers } from '../../constants';
import { Context, DataSourceLoader, DataSourceLoaderConfig, Scope, SourceConfigUser } from '../../models';
import { ExpressionParser } from '../expression-parser';

import { DataSourceConverter } from './data-source-converter';
import { sortByDisplay } from './data-source-helper-functions';
import { toDataSeedSafeOption } from './retrocompatibility-loader-hack';
import { SourceConfigQueryBuilder } from './source-config-query-builder';

/** Initialized via DataLoaderFactory */
export class ProvisioningLoader implements DataSourceLoader {

	private queryBuilder: SourceConfigQueryBuilder;

	constructor(
		public sourceConfig: SourceConfigUser,
		public config: DataSourceLoaderConfig | undefined,
		private usersClient: UsersClient,
		private dataSourceConverter: DataSourceConverter,
		expressionParser: ExpressionParser,
		private contextOptions: Pick<ProjectContentOptionsInterface, 'preview'>,
	) {
		this.queryBuilder = new SourceConfigQueryBuilder(sourceConfig, expressionParser);
	}

	async getOptions(context?: Context, scope?: Scope, signal?: AbortSignal): Promise<DataSeed[]> {
		const query = this.getQuery(undefined, context, scope);
		const users = await this.usersClient.query(query, { signal, analytics: this.config?.requestAnalytics });

		return users.map((u) => this.mapToSeed(u)).filter(isNotNull).map(toDataSeedSafeOption);
	}

	async search(q?: string, context?: Context, scope?: Scope, signal?: AbortSignal, contextFilters?: AstNode): Promise<DataSeed[]> {
		const query = this.getQuery(q, context, scope, contextFilters);
		const users = await this.usersClient.query(query, { signal, analytics: this.config?.requestAnalytics });

		return users.map((u) => this.mapToSeed(u)).filter(isNotNull);
	}

	async findAllBy(match: string, context?: Context, scope?: Scope, signal?: AbortSignal): Promise<DataSeed[]> {

		if (!this.sourceConfig.findBy) {
			console.warn('Datasource has not set findBy, falling back on search');

			return this.search(match, context, scope, signal);
		}

		const query = this.queryBuilder.create(match, context, scope, this.sourceConfig.findBy);
		const users = await this.usersClient.query(query, { signal, analytics: this.config?.requestAnalytics });

		// Map results to output params
		return (users.map((u) => this.mapToSeed(u)).filter(isNotNull)).sort(sortByDisplay);
	}

	async get(id: string): Promise<DataSeed | null> {
		const useUsername = this.sourceConfig.mappings.find((scm) => scm.to === DataSourceIdTo && scm.from === UserInfoIdentifiers.Username as string) != null;
		const user = useUsername ? await this.usersClient.getByUsername(id) : await this.usersClient.get(id);

		return this.mapToSeed(user);
	}

	mapToSeed(user?: UserInfo): DataSeed | null {
		return this.dataSourceConverter.toDataSeed(user, this.sourceConfig);
	}

	private getQuery(searchTerm?: string, context?: Context, scope?: Scope, contextFilters?: AstNode): Query {
		let query = this.queryBuilder.create(searchTerm, context, scope, undefined, contextFilters);

		if (!this.contextOptions.preview) {
			// Exclude isTester users in stable
			query = query.and(Q.eq(UserInfoIdentifiers.IsTester, false));
		}

		return query;
	}

}
