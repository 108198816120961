import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FieldType } from '@unifii/sdk';

import { ShortTimeFormat } from '../../constants';
import { DateConverter } from '../../utils';

import { UfControlValueAccessor } from './uf-control-value-accessor';

@Component({
	selector: 'uf-time',
	templateUrl: './uf-time.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR, useExisting: UfTimeComponent, multi: true,
	}],
	styleUrls: ['./uf-input.less', './uf-time.less'],
})
export class UfTimeComponent extends UfControlValueAccessor<string> {

	@Input() name: string;
	@Input() label: string | null | undefined;
	@Input() autofocus: boolean | string = false; // TODO review the usage of this property
	@Input() suffix: string | null;
	@Input() timezone: string | null; // TODO review the usage of this property
	@Output() override valueChange = new EventEmitter<string>();

	protected readonly type = FieldType.Time;

	private _format = ShortTimeFormat;
	private _placeholder?: string | null;
	private _step?: number | null;
	private dateConverter = new DateConverter(this.type, this.format);

	@Input() set step(v: number | string | undefined | null) {
		this._step = typeof v === 'string' ? +v : v;
	}

	get step(): number | null | undefined {
		return this._step;
	}

	/**
	 * Overwrite ControlValueAccessor implementation
	 * Accept a string time value in format HH:mm:ss
	 */
	@Input() override set value(v: string | null | undefined) {
		/**
		 * Same logic repeated in date, time, datetime inputs,
		 * maybe a possibility of adding a value set predicate to InputManger
		 */
		if (v) {
			v = this.dateConverter.normalizeModelValue(v);
			if (!v) {
				console.warn(`UfTimeComponent.value.set: Skipped internal set for invalid value '${v}'!`);

				return;
			}
		}
		// Set value on ControlValueAccessor so internal flags are updated
		super.value = v;
	}

	override get value(): string | null | undefined {
		return super.value;
	}

	/** This is the display format */
	@Input() set format(v: string | null | undefined) {
		this.dateConverter = new DateConverter(this.type, v);
		this._format = this.dateConverter.displayFormat;
	}

	/** Return input overridden format or format based on nativeInput flag */
	get format(): string {
		return this._format;
	}

	@Input() set placeholder(v: string | null | undefined) {
		this._placeholder = v;
	}
	/** Return with following logic
	 *  1. the provided @Input placeholder if any
	 *  2. the provided format if not been replaced with the default one
	 *  3. the replaced/default format
	 */
	get placeholder(): string {

		if (this._placeholder) {
			return this._placeholder;
		}

		if (this._format.toLowerCase() === this.format.toLowerCase()) {
			return this._format;
		}

		return this.format;
	}

	@HostBinding('class.disabled') get disabledClass() {
		return this.disabled;
	}

	override valueEmitPredicate(value: string | null, prev: string | null): boolean {
		/**
		 * Prevent null value from emitting undefined resulting in ExpressionChangedAfterItHasBeenCheckedError,
		 * InputManager will only emit undefined values (not null)
		 */
		return !(value == null && prev == null) && super.valueEmitPredicate(value, prev);
	}

}
