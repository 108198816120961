<div class="uf-grid pad-sides">
	<h2 class="col-12">Search Result</h2>
	<div class="col-12 row space-children">
		<button (click)="clearSearchInput()" class="uf-button" type="button">Clear Search Input</button>
		<uf-checkbox [(value)]="enabled" (valueChange)="toggleEnable()" />
	</div>

	<uf-search-result #addressSearch [control]="addressControl" [options]="addressOptions"
		(searchChange)="search($event)" (valueChange)="addresses.push($event)" label="Find address" class="col-12"
		nameProperty="formatted_address" />

	<ng-template [ngForOf]="addresses" let-address ngFor target="_blank">
		<div class="col-4">
			<div class="uf-content-card">
				<div class="uf-grid">
					<div class="col-12">
						<h3>{{address._display}}</h3>
					</div>
				</div>
			</div>
		</div>
	</ng-template>

	<uf-search-result #fruitSearch [control]="fruitControl" [options]="fruitOptions" [blurOnSelect]="false"
		[minSearchLength]="3" [nameProperty]="fruitNameProperty.bind(this)" (searchChange)="findFruits($event)"
		(valueChange)="fruits.push($event)" label="Find some fruit" class="col-12" />

	<div *ngFor="let fruit of fruits" class="col-4">
		<div class="uf-content-card">
			<div class="uf-grid">
				<div class="col-12">
					<h3>{{fruit}}</h3>
				</div>
			</div>
		</div>
	</div>

</div>