import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { HierarchyUnitFormData } from '@unifii/sdk';

import { HierarchyUnitIdentifier, HierarchyUnitPickerModalData } from '../../models/hierarchy-model';
import { ModalService } from '../../services/modal.service';
import { SharedTermsTranslationKey } from '../../translations';

import { HierarchyUnitPickerModalComponent } from './hierarchy-utilities';
import { UfControlValueAccessor } from './uf-control-value-accessor';

@Component({
	selector: 'uf-hierarchy-multi-select',
	templateUrl: './uf-hierarchy-multi-select.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR, useExisting: UfHierarchyMultiSelectComponent, multi: true,
	}],
	styleUrls: ['./uf-input.less', './uf-hierarchy-multi-select.less'],
})
export class UfHierarchyMultiSelectComponent extends UfControlValueAccessor<HierarchyUnitFormData[]> {

	@Input() label: string | null | undefined;
	@Input() placeholder: string | null | undefined;
	@Input() ceiling: HierarchyUnitIdentifier | null;
	@Input() leavesOnly: boolean | null | undefined;
	@Input() activesOnly: boolean | null | undefined;
	@Input() preselected: HierarchyUnitIdentifier | null | undefined;
	@Input() allowDuplicates = false;
	@Input() unselectableUnits: HierarchyUnitIdentifier[] | null | undefined;
	@Output() override valueChange = new EventEmitter<HierarchyUnitFormData[]>();

	protected readonly sharedTermsTK = SharedTermsTranslationKey;

	private isPickerOpen = false;
	private modalService = inject(ModalService);

	protected async onFocus() {
		if (this.disabled || this.isPickerOpen) {
			return;
		}

		const componentValue = this.value ?? [];
		const unselectableUnits = this.unselectableUnits ?? [];

		this.isPickerOpen = true;

		const selectedUnit = await this.modalService.openLarge(
			HierarchyUnitPickerModalComponent,
			{
				label: this.label,
				ceiling: this.ceiling,
				preselected: this.preselected,
				leavesOnly: this.leavesOnly,
				activesOnly: this.activesOnly,
				unselectableUnits: this.allowDuplicates ? unselectableUnits : [...componentValue, ...unselectableUnits],
			} satisfies HierarchyUnitPickerModalData,
		);

		if (selectedUnit) {
			this.control.setValue([...(this.value ?? []), selectedUnit]);
			this.control.markAsTouched();
		}

		this.isPickerOpen = false;
	}

	protected remove(id: string) {
		if (!this.value) {
			return;
		}

		this.control.setValue(this.value.filter((hierarchy) => hierarchy.id !== id));
	}

}
