import { Component, ElementRef, EventEmitter, HostBinding, Inject, Injector, Input, OnDestroy, OnInit, Optional, Output, SkipSelf, ViewChild, ViewContainerRef, forwardRef } from '@angular/core';
import { FieldTemplate, FieldType, InputStyle } from '@unifii/sdk';
import { Subject } from 'rxjs';

import { ContextProvider, PatternUtil, RuntimeField, Scope, UfControl, UfControlArray, UfControlGroup, UfFormControl } from '@unifii/library/common';

import { FIELD_WIDTH_MAP } from '../constants';
import { ComponentRegistry, ComponentRegistryOptions, ComponentRegistryType, FormComponentRegistryOptions, FormField } from '../models';
import { FormFieldService, FormService, ScopeManager, SmartFormServices, WorkflowService } from '../services';
import { FieldHelperFunctions } from '../utils';

@Component({
	selector: 'uf-field',
	template: '<div #child></div>',
	styleUrls: ['./field.less'],
})
export class FieldComponent implements OnInit, OnDestroy {

	// TODO make field a required input
	@Input() field: RuntimeField;
	@Input() control?: UfFormControl;
	@Input() cssClass: InputStyle | null | undefined = null; // Provides a way for css classes to be passed down to rendered component used by tables and where ever else requires an override
	@Output() contentChange = new EventEmitter();

	@HostBinding('class.smart-form-field') isSmartFormField = false;
	@ViewChild('child', { read: ViewContainerRef, static: true }) private container: ViewContainerRef;

	// notifies listeners when the component is hidden in the UI
	hiddenChanges = new Subject<boolean>();

	private component: FormField | null;
	private permissionGranted: boolean;
	private _content: any;
	private _scope: Scope = {};
	private _hidden = false;
	private formFieldService: FormFieldService | null = null;
	private injector: Injector | null;
	private patternUtil = new PatternUtil<unknown>();

	constructor(
		@Inject(ContextProvider) private contextProvider: ContextProvider,
		@Inject(ComponentRegistry) private registry: ComponentRegistry,
		private element: ElementRef<HTMLElement>,
		private parentInjector: Injector,
		@Optional() private formService: FormService | null,
		@Optional() private workflowService: WorkflowService | null,
		@SkipSelf() @Optional() @Inject(forwardRef(() => FieldComponent)) public parent: FieldComponent | null,
	) {
	}

	@HostBinding('class.hidden') get componentHidden() {
		return !this.permissionGranted || this.hidden || this.field.template === FieldTemplate.Hidden;
	}

	@Input() set scope(v: Scope | null) {

		this._scope = v ?? {};

		if (this.component) {
			this.component.scope = this._scope;
		}

		// TODO should this move to ngOnInit to guarantee that field is set
		const nullableField = this.field as RuntimeField | null;

		if (nullableField?.identifier) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			this.content = this._scope[nullableField.identifier];
		}
	}

	get scope(): Scope {
		return this._scope;
	}

	@Input() set content(v) {

		/** needs to be here for setting autofill */
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		this._content = v;

		if (this.component) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			this.component.content = v;
		}
	}

	get content() {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		return this._content;
	}

	set hidden(hidden: boolean | undefined) {
		if (hidden == null || this._hidden === hidden) {
			return;
		}

		this._hidden = hidden;

		this.hiddenChanges.next(hidden);

		if (hidden && this.component) {
			this.component.content = undefined;
		}

		if (this.control) {
			if (hidden) {
				// Call reset and disable separately as the 'formState' arg won't update the status of subcontrols
				this.control.reset();
				this.control.disable();
			} else if (!this.control.parent?.disabled) {
				this.control.enable();
			}
		}
	}

	get hidden(): boolean {
		return this._hidden;
	}

	get disabled(): boolean {
		return (this.field as RuntimeField | null)?.isReadOnly || this.hidden;
	}

	ngOnInit() {
		this.initProviders();

		this.permissionGranted = this.isGranted(this.field.visibleTo);
		if (!this.permissionGranted) {
			return;
		}

		const options: ComponentRegistryOptions = { tags: this.field.tags };
		let fieldType = this.field.type;

		if (this.workflowService) {
			(options as FormComponentRegistryOptions).isActive = this.workflowService.isActive(this.field);

			// switch field types for inactive steps and steppers as they are rendered as form groups and need to adopt their styles
			if (!(options as FormComponentRegistryOptions).isActive && [FieldType.Step, FieldType.Stepper].includes(fieldType)) {
				fieldType = FieldType.Group;
			}
		}

		// Use formFieldService to determine if registry is FormComponentRegistry
		if (this.formFieldService && this.parent) {
			(options as FormComponentRegistryOptions).parent = this.parent.field;
			(options as FormComponentRegistryOptions).isDataSourceMappingField = this.field.isDataSourceMappingField;
		}

		const component = this.registry.get(this.field.type, options);

		if (!component) {
			console.error('No component found');

			return;
		}

		const componentRef = this.container.createComponent(component, { index: 0, injector: this.injector ?? this.parentInjector });

		this.component = componentRef.instance;

		this.initFormControl(this.registry.type);

		const suffix = this.getLabelSuffix(this.field.isRequired);

		if (suffix != null) {
			this.component.suffix = suffix;
		}

		this.component.field = this.field;
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		this.component.content = this.content;
		this.component.scope = this.scope;
		this.component.cssClass = this.cssClass ?? undefined;
		this.component.contentChange = this.contentChange;

		// Guard element.nativeElement.dateset, not available in AngularUniversal
		if (this.field.identifier && this.element.nativeElement.dataset as DOMStringMap | undefined) {
			this.element.nativeElement.dataset.identifier = this.field.identifier;
		}

		if (this.formFieldService) {
			this.formFieldService.init(this.component, this, this.container);
			this.isSmartFormField = true;
		}

		this.initCssClasses(this.registry.type, fieldType);
	}

	ngOnDestroy() {
		this.formFieldService?.onDestroy();
	}

	// TODO change name to setContent so it is more accurate
	setValue(v: any) {

		// Skip the new value when it's already equal to the internal one
		if (this.patternUtil.isEqual(v, this.content)) {
			return;
		}

		const nullableField = this.field as RuntimeField | null;

		if (nullableField?.identifier) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			this.scope[nullableField.identifier] = v;
		}

		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		this.content = v;

		if (!this.control) {
			return;
		}

		if (this.control instanceof UfControl) {
			this.control.setValue(v);
		} else {
			this.control.patchValue(v);
		}
	}

	private initProviders() {
		try {
			for (const providerToken of SmartFormServices) {
				this.parentInjector.get(providerToken);
			}

			this.injector = Injector.create({
				providers: [{ provide: FormFieldService }],
				parent: this.parentInjector,
			});

			this.formFieldService = this.injector.get(FormFieldService);

		} catch (e) {
			// Not all providers for FormFieldService have been provided
		}
	}

	private isGranted(roles: string | string[]): boolean {

		if (!roles) {
			return true;
		}

		const userContext = this.contextProvider.get().user;

		if (!userContext) {
			return true;
		}

		/**
		 * Hide and if Field not visible based on user roles
		 * this condition is only applicable to content fields smart form fields
		 */
		return FieldHelperFunctions.areRolesMatching(userContext.roles, roles);
	}

	private initCssClasses(registryType: ComponentRegistryType, fieldType: FieldType) {
		const htmlElement: HTMLElement = this.element.nativeElement;
		const cssClasses: string[] = [`uf-field--${fieldType.toLowerCase()}`];

		let fieldWidthClass = 'col-1of1';

		// TODO logic smart-form related move to form field service
		if (registryType === ComponentRegistryType.Display) {
			cssClasses.push('summary');
		}

		if (registryType === ComponentRegistryType.Input && ![FieldType.Survey, FieldType.Stepper].includes(fieldType)) {
			fieldWidthClass = (FIELD_WIDTH_MAP[this.field.width ?? ''] as string | null) ?? fieldWidthClass;
		}

		// Element doesn't include a column class
		if (!htmlElement.className.includes('col-')) {
			cssClasses.push(fieldWidthClass);
		}

		htmlElement.classList.add(...cssClasses);
	}

	private initFormControl(registryType: ComponentRegistryType) {
		const control = this.getControl(registryType);

		if (control == null) {
			return;
		}

		this.control = control;
		if (this.component) {
			this.component.control = control;
		}
		control.component = this;

		if (this.field.isReadOnly) {
			control.disable({ onlySelf: true, emitEvent: false });
		}
	}

	private getControl(registryType: ComponentRegistryType): UfFormControl | undefined {

		try {
			const scopeManager = this.injector?.get(ScopeManager);

			if (scopeManager != null) {
				return scopeManager.getControl(this.field) ?? this.createControl(this.field.type);
			}
		} catch (e) { /** */ }

		// TODO Any better detection logic? This is weak
		if (registryType === ComponentRegistryType.Input) {
			// When field component used with InputComponentRegistry outside of a smart form
			return this.control ?? this.createControl(this.field.type);
		}

		return undefined;
	}

	private createControl(fieldType: FieldType): UfFormControl {
		// Add controls if field is formField but not part under a form.component
		if (FieldHelperFunctions.isGroup(fieldType)) {
			return new UfControlGroup({});
		}
		if (fieldType === FieldType.Repeat) {
			return new UfControlArray([]);
		}

		return new UfControl();
	}

	private getLabelSuffix(required: boolean): string | undefined {

		if (this.formService?.definitionSettings == null) {
			return;
		}

		if (required) {
			return this.formService.definitionSettings.requiredSuffix;
		}

		return this.formService.definitionSettings.optionalSuffix;
	}

}
