import { Component, EventEmitter, HostBinding, Input, OnInit, Output, inject } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CostModelFormat, isCostModelFormat, isNotNull, isNumber, isString } from '@unifii/sdk';

import { UfFormBuilder } from '../../services';

import { UfControlValueAccessor } from './uf-control-value-accessor';

/** When add other currencies extends FieldDisplayPipe too */
@Component({
	selector: 'uf-cost',
	templateUrl: './uf-cost.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR, useExisting: UfCostComponent, multi: true,
	}],
	styleUrls: ['./uf-input.less', './uf-cost.less'],
})
export class UfCostComponent extends UfControlValueAccessor<CostModelFormat> implements OnInit {

	@Input() name: string | null;
	@Input() label: string | null | undefined;
	@Input() placeholder: string | null | undefined;
	@Input() suffix = '$';
	@Input() currency = 'AUD';
	@Output() override valueChange = new EventEmitter<CostModelFormat>();

	protected readonly decimalPrecision = 2;
	protected readonly dollarRegEx = /^(|\d)\d*\.?\d{0,2}$/;
	protected inputControl = inject(UfFormBuilder).control(null);
	protected focused: boolean;

	ngOnInit() {
		// Sync inputControl.disabled with the input control
		this.onDisabledChanges(this.control.disabled);

		// Sync inputControl.value with the input control
		this.setInputValue(this.control.value);

		this.subscriptions.add(this.inputControl.valueChanges.subscribe(() => {
			this.onInputControlValueChanged();
		}));
	}

	@Input() override set value(v: CostModelFormat | null | undefined) {
		if (!isCostModelFormat(v)) {
			v = undefined;
		}

		super.value = v;
		this.setInputValue(v);
	}

	@HostBinding('class.focused') get focusedClass() {
		return this.focused && !this.disabled;
	}

	@HostBinding('class.error') get errorClass() {
		return this.control.showError && !this.disabled;
	}

	@HostBinding('class.disabled') get disabledClass() {
		return this.disabled;
	}

	@HostBinding('class.value') get valueClass() {
		return !!this.value;
	}

	private get inputControlValue(): number | null {
		return this.inputControl.value as number | null;
	}

	// TODO changes to control should not happens here
	override valueEmitPredicate(value: CostModelFormat | null, prev: CostModelFormat | null): boolean {
		if (!value) {
			this.setInputValue(undefined);
		} else {
			const cents = `${value.amount}`;

			if (!this.compareCentsToDollars(cents, this.inputControlValue)) {
				this.setInputValue(value);
			}
		}

		return super.valueEmitPredicate(value, prev);
	}

	protected override onDisabledChanges(disabled: boolean) {
		if (disabled) {
			this.inputControl.disable();
		} else {
			this.inputControl.enable();
		}
	}

	protected onFocusChange(focused: boolean) {
		this.focused = focused;

		if (!focused) {
			this.control.markAsTouched();
		}
	}

	private onInputControlValueChanged() {
		const inputValueString = isNotNull(this.inputControlValue) ? `${this.inputControlValue}` : null;

		if (inputValueString && !this.dollarRegEx.test(inputValueString)) {
			this.setInputValue(null);

			return;
		}

		this.setControlValue(this.toFixedDecimals(inputValueString));
	}

	private setInputValue(modelValue: CostModelFormat | null | undefined) {
		const inputControlValue = this.inputControlValue;
		let dollars = modelValue ? `${(modelValue.amount / 100)}` : undefined;

		if (this.patternUtil.isEqual(this.value, modelValue) && this.compareCentsToDollars(modelValue?.amount, inputControlValue)) {
			return;
		}

		// Amend computed dollars value when model value match inputControl but formatted value doesn't
		// This to avoid that a copy-pasted value like 40.50 result in 40.5 in the inputControl
		if (dollars && isNotNull(inputControlValue) && `${inputControlValue}` !== dollars &&
			this.toFixedDecimals(inputControlValue) === this.toFixedDecimals(dollars)
		) {
			dollars = `${inputControlValue}`;
		}

		this.inputControl.setValue(isNotNull(dollars) ? +dollars : null, { emitEvent: false });
		this.control.setValue(modelValue, { emitEvent: false });
	}

	private toFixedDecimals(value: string | number | null | undefined): string | null {
		return isNotNull(value) ? (+value).toFixed(this.decimalPrecision) : null;
	}

	private setControlValue(dollars: string | null) {
		const costValue = this.getModelValue(dollars, this.currency);

		this.control.markAsDirty();
		this.control.setValue(costValue);
	}

	private getModelValue(dollars: string | null, currency: string): CostModelFormat | null {
		if (!dollars) {
			return null;
		}

		return {
			amount: +dollars * 100, /* cost value must always be cents */
			currency,
		};
	}

	private compareCentsToDollars(cents: string | number | null | undefined, dollars: string | number | null | undefined): boolean {
		const numericCents = isString(cents) ? +cents : cents;
		const numericDollars = isString(dollars) ? +dollars : dollars;
		
		return numericCents === (isNumber(numericDollars) ? numericDollars * 100 : numericDollars);
	}

}
