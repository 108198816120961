import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, inject } from '@angular/core';
import { Subject, Subscription, fromEvent } from 'rxjs';

// Break circular dependency
import { ModalPositionAlignment } from '../components/modal/modal-types';
import { ListBoxComponent, ListBoxConfig, ListBoxOption } from '../components/modal/modals/list-box.component';
import { WindowWrapper } from '../native';
import { ModalService } from '../services';

/** Directive that captures focus event from a form input or button and displays a list modal of options */
@Directive({
	selector: 'input[listBox], button[listBox]',
})
export class ListBoxDirective implements OnInit, OnDestroy {

	@Input() nameProperty: string | ((v: any) => string) | null | undefined;
	@Input() container: HTMLElement | undefined;
	@Input() optionsChange = new Subject<any[]>();
	@Input() template: TemplateRef<any> | undefined;
	@Input() cssClass: string | string[] | Set<string> | undefined; // css class that will be added to the list box
	@Output() optionSelected = new EventEmitter<any>();
	@Output() done = new EventEmitter<void>();

	protected _options: any[] | undefined;
	protected _pendingPopup: Promise<any> | null;
	protected subscriptions = new Subscription();

	private lastUserEvent: FocusEvent | MouseEvent | null;
	private element = inject<ElementRef<HTMLInputElement | HTMLButtonElement>>(ElementRef);
	private nativeElement = this.element.nativeElement;
	private window = inject<Window>(WindowWrapper);
	private modalService = inject(ModalService);

	@Input() set options(v: any[] | null | undefined) {
		this._options = v ?? undefined;
		void this.openOrUpdateDialog();
	}

	get options(): any[] | null | undefined {
		return this._options;
	}

	protected set pendingPopup(v: Promise<any> | null) {
		this._pendingPopup = v;
	}

	protected get pendingPopup(): Promise<any> | null {
		return this._pendingPopup;
	}

	private get elementTagName(): 'INPUT' | 'BUTTON' {
		return this.nativeElement.tagName as 'INPUT' | 'BUTTON';
	}

	private get anchorElement(): HTMLElement {
		return this.container ?? this.nativeElement;
	}

	ngOnInit() {
		if (this.elementTagName === 'INPUT') {
			this.subscriptions.add(fromEvent(this.nativeElement, 'focus').subscribe((event) => {
				this.lastUserEvent = event as FocusEvent;
				void this.openOrUpdateDialog();
			}));
		} else {
			this.subscriptions.add(fromEvent(this.nativeElement, 'click').subscribe((event) => {
				this.lastUserEvent = event as MouseEvent;
				void this.openOrUpdateDialog();
			}));
		}
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	protected generateDisplayableOptions(): ListBoxOption[] {
		return (this.options ?? []) as unknown as ListBoxOption[];
	}

	protected async openOrUpdateDialog() {

		const optionsToShow = this.generateDisplayableOptions();

		// Box dialog already open, refresh the options content
		if (this.pendingPopup) {
			this.optionsChange.next(optionsToShow);

			return;
		}

		// Guard for user interaction
		if (!this.lastUserEvent) {
			return;
		}

		// Guard for available options to show
		if (!optionsToShow.length) {
			return;
		}

		// Guard disabled or readonly
		if (this.nativeElement.disabled || (this.nativeElement as HTMLInputElement).readOnly) {
			return;
		}

		// Guard event source doesn't match active element on focus event
		if (this.lastUserEvent.type === 'focus' && this.nativeElement !== this.window.document.activeElement) {
			return;
		}

		/**
		 * When showing the modal some browsers require reflow to be forced
		 * to position correctly (hello safari), the current code was created from using this
		 * github reference https://gist.github.com/paulirish/5d52fb081b3570c81e3a and
		 * through experimenting on desktop and mobile browsers
		 */
		const initialStyle = this.element.nativeElement.style.display;

		this.element.nativeElement.style.display = 'none';
		this.element.nativeElement.style.display = initialStyle;

		// Show a new modal
		try {
			const popupConfig: ListBoxConfig = {
				options: optionsToShow,
				optionsChange: this.optionsChange,
				template: this.template,
				nameProperty: this.nameProperty,
				cssClass: this.cssClass,
			};

			if (this.elementTagName === 'INPUT') {
				popupConfig.width = this.anchorElement.clientWidth + 'px';
			} else {
				popupConfig.minWidth = this.anchorElement.clientWidth + 'px';
			}
			this.pendingPopup = this.modalService.openAnchor(
				ListBoxComponent,
				this.lastUserEvent,
				{
					target: this.anchorElement,
					originX: ModalPositionAlignment.Left,
					originY: ModalPositionAlignment.Bottom,
					alignmentX: ModalPositionAlignment.Left,
					alignmentY: [ModalPositionAlignment.Bottom, ModalPositionAlignment.Top],
				},
				popupConfig,
			);

			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			const result = await this.pendingPopup;

			if (result != null) {
				this.emitValue(result);
			}

			this.done.emit();

		} finally {
			this.pendingPopup = null;
		}
	}

	private emitValue(value: any) {
		this.optionSelected.emit(value);
	}

}
