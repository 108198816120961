import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FieldType, ZonedDateTime, isZonedDateTime } from '@unifii/sdk';
import { format } from 'date-fns';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
// https://github.com/marnusw/date-fns-tz/issues/183
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc';
import { merge } from 'rxjs';

import { DateAndTimeFormat } from '../../constants';
import { UfControl } from '../../controls';
import { DateConverter, getLocalTimeZone } from '../../utils';

import { UfControlValueAccessor } from './uf-control-value-accessor';

@Component({
	selector: 'uf-datetime-tz',
	templateUrl: './uf-datetime-tz.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR, useExisting: UfDatetimeTZComponent, multi: true,
	}],
	styleUrls: ['./uf-input.less', './uf-datetime.less', './uf-datetime-tz.less'],
})
export class UfDatetimeTZComponent extends UfControlValueAccessor<ZonedDateTime> implements OnInit {

	@Input() name: string | null;
	@Input() placeholder: string | null | undefined;
	@Input() label: string | null | undefined;
	@Input() step: string | number | null | undefined;
	@Output() override valueChange = new EventEmitter<ZonedDateTime>();

	protected datetimeControl = new UfControl();
	protected timezoneControl = new UfControl();

	/** Flag that the update flow has been triggered by an external set */
	private emittedOnSet = false;
	private _format = DateAndTimeFormat;
	private dateConverter = new DateConverter(FieldType.DateTime, this.format);

	ngOnInit() {
		if (!this.value && !this.disabled) {
			this.timezoneControl.setValue(getLocalTimeZone(), { emitEvent: false });
		}

		this.subscriptions.add(
			merge(this.datetimeControl.valueChanges, this.timezoneControl.valueChanges)
				.subscribe(() => { this.updateValueOnInternalChange(); }),
		);
	}

	@Input() set format(v: string | null | undefined) {
		this.dateConverter = new DateConverter(FieldType.DateTime, v);
		this._format = this.dateConverter.displayFormat;
	}

	get format(): string {
		return this._format;
	}

	@Input() override set value(v: ZonedDateTime | null | undefined) {
		this.updateInternalControls(v);
		super.value = v;
	}

	override get value(): ZonedDateTime | undefined | null {
		return super.value;
	}

	@Input() override set control(v: UfControl) {
		this.updateInternalControls((v as UfControl | null)?.value as ZonedDateTime | null | undefined);
		this.onDisabledChanges(v.disabled);
		super.control = v;
	}

	override get control(): UfControl {
		return super.control;
	}

	protected override onDisabledChanges(disabled: boolean) {

		if (disabled && this.datetimeControl.enabled) {
			this.datetimeControl.disable({ emitEvent: false });
			this.timezoneControl.disable({ emitEvent: false });

			return;
		}

		if (!disabled && this.datetimeControl.disabled) {
			this.datetimeControl.enable({ emitEvent: false });
			this.timezoneControl.enable({ emitEvent: false });
		}

		super.onDisabledChanges(disabled);
	}

	/** Update internal controls value
	 *
	 * @param v the value to update the controls to
	 */
	private updateInternalControls(v: ZonedDateTime | null | undefined) {

		// Override invalid ZonedDateTime param with null
		if (!isZonedDateTime(v)) {
			v = null;
		}

		// Detect same value, relax patternUtil.isEqual by consider null and undefined equivalents
		const sameValue = this.patternUtil.isEqual(v, this.value) || (!v && !this.value);

		// When it is sameValue and null, do not clear internal dateTimeControls if timeZone is already null
		if (sameValue && v == null && (!this.timezoneControl.value || this.datetimeControl.value)) {
			return;
		}

		const nextTimezone = v?.tz;
		const nextDatetime = v ? this.getDatetimeFromUtc(v.value, v.tz) : undefined;

		this.emittedOnSet = true;
		if (nextTimezone !== this.timezoneControl.value) {
			this.timezoneControl.setValue(nextTimezone);
		}

		if (nextDatetime !== this.datetimeControl.value) {
			this.datetimeControl.setValue(nextDatetime);
		}
		this.emittedOnSet = false;
	}

	/** Update the value when the internal controls value change */
	private updateValueOnInternalChange() {
		if (this.emittedOnSet) {
			return;
		}

		const dateTime = this.datetimeControl.value as string | null;
		const timeZone = this.timezoneControl.value as string | null;
		const value = (dateTime && timeZone) ?
			this.getZonedDatetime(dateTime, timeZone) :
			undefined;

		this.control.setValue(value);
		this.control.markAsTouched();
	}

	private getDatetimeFromUtc(value: string, tz: string): string {
		return format(utcToZonedTime(value, tz), this.dateConverter.modelFormat);
	}

	private getZonedDatetime(datetime: string, tz: string): ZonedDateTime {
		const zonedTime = zonedTimeToUtc(datetime, tz);
		const value = zonedTime.toISOString().replace('Z', '+00:00');

		return { tz, value };
	}

	@HostBinding('class.disabled') get disabledClass() {
		return this.disabled;
	}

	@HostBinding('class.error') get errorClass() {
		return this.control.showError && !this.disabled;
	}

}
