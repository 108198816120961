import { AfterViewInit, Component, DestroyRef, EventEmitter, Input, Output, ViewChild, ViewContainerRef, inject } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { REGEXP_EMAIL, REGEXP_TELEPHONE } from '@unifii/sdk';

import { WindowWrapper } from '../../native';
import { DOMEventHandler, WindowResizeEventHandler, WindowResizeInfo } from '../../services';
import { CommonTranslationKey } from '../../translations';
import { Regexp } from '../../utils';

import { UfControlValueAccessor } from './uf-control-value-accessor';
import { UfTextareaComponent } from './uf-textarea.component';

@Component({
	selector: 'uf-markdown-editor',
	templateUrl: './uf-markdown-editor.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR, useExisting: UfMarkdownEditorComponent, multi: true,
	}],
	styleUrls: ['./uf-markdown-editor.less'],
})
export class UfMarkdownEditorComponent extends UfControlValueAccessor<string> implements AfterViewInit {

	@ViewChild(UfTextareaComponent, { static: true }) textarea: UfTextareaComponent;

	@Input() label: string;
	@Input() preview = false;
	@Output() override valueChange = new EventEmitter<string>();

	@ViewChild('containerRef', { read: ViewContainerRef, static: true })

	private containerRef: ViewContainerRef;

	readonly layoutSizes = {
		Normal: 'normal',
		Compact: 'compact',
		CompactExtraSmall: 'compact-xs',
	};

	// list requires options
	readonly defaultOptions = [{ name: null, value: null }];

	readonly commonTK = CommonTranslationKey;

	protected translate = inject(TranslateService);

	protected readonly headingOptions = [
		{ name: this.translate.instant(CommonTranslationKey.MarkdownEditorNormalLabel) as string, value: '' },
		{ name: this.translate.instant(CommonTranslationKey.MarkdownEditorHeading1Label) as string, value: '# ' },
		{ name: this.translate.instant(CommonTranslationKey.MarkdownEditorHeading2Label) as string, value: '## ' },
		{ name: this.translate.instant(CommonTranslationKey.MarkdownEditorHeading3Label) as string, value: '### ' },
		{ name: this.translate.instant(CommonTranslationKey.MarkdownEditorHeading4Label) as string, value: '#### ' },
		{ name: this.translate.instant(CommonTranslationKey.MarkdownEditorHeading5Label) as string, value: '##### ' },
	];

	protected layoutSize = this.layoutSizes.Normal;
	protected showPreview: boolean;

	private window = inject(WindowWrapper) as Window;

	private domEventHandler = inject(DOMEventHandler);
	private windowResizeEventHandler = inject(WindowResizeEventHandler, { optional: true });
	private destroy = inject(DestroyRef);

	ngAfterViewInit() {
		this.windowResizeEventHandler?.register({
			listener: this.setLayoutSize.bind(this),
			destroy: this.destroy,
			reference: this.containerRef.element,
			fireOnRegister: true,
		});

		this.domEventHandler.register({
			element: this.nativeElement,
			event: 'keydown',
			listener: this.onKeydown.bind(this),
			destroy: this.destroy,
		});
	}

	override valueEmitPredicate(value: string | null, prev: string | null): boolean {

		// temporary debounce guard
		if (value === prev) {
			return super.valueEmitPredicate(value, prev);
		}

		if (this.characterAdded(value ?? '', prev ?? '') === '\n' ||
			this.characterAdded(value ?? '', prev ?? '') === '\t') {
			this.updateLists();
		}

		return super.valueEmitPredicate(value, prev);
	}

	onKeydown(e: KeyboardEvent) {

		if (e.key === 'Tab') {

			e.preventDefault();

			if (!this.value) {
				this.value = '';
			}

			// temporary storage
			const selectionStart = this.nativeElement.selectionStart;
			const selectionEnd = this.nativeElement.selectionEnd;

			const value = this.insert(this.value, selectionStart, '\t');

			// update element value to set correct selection
			this.nativeElement.value = value;
			// reset
			this.nativeElement.setSelectionRange(selectionStart + 1, selectionEnd + 1);
			this.nativeElement.focus();
			this.control.setValue(value);
		}
	}

	/**
	 * Insert custom value at selected point
	 *
	 * @param value text added after selection
	 */
	insertCustom(s: string) {

		if (this.showPreview) {
			return;
		}

		if (!this.value) {
			this.value = '';
		}

		// temporary storage
		const selectionStart = this.nativeElement.selectionStart;
		const selectionEnd = this.nativeElement.selectionEnd;

		this.control.setValue(this.insert(this.value, selectionStart, s));

		this.nativeElement.setSelectionRange(selectionStart + s.length, selectionEnd + s.length);
		this.nativeElement.focus();
	}

	// Button Fns
	bold() {
		this.insertAround('**', '**', true);
	}

	italic() {
		this.insertAround('*', '*', true);
	}

	codeblock() {
		this.insertAround('```\n', '\n```', true);
	}

	code() {
		this.insertAround('`', '`', true);
	}

	async websiteLink() {
		await this.checkLink(Regexp.REGEXP_HTTP_WEBSITE, 'https://', false);
	}

	async telLink() {
		await this.checkLink(REGEXP_TELEPHONE, 'tel:');
	}

	async emailLink() {
		await this.checkLink(REGEXP_EMAIL, 'mailto:');
	}

	quote() {
		this.insertAround('>', '', true);
	}

	unorderedList() {
		this.insertAround('- ', '', false);
	}

	orderedList() {
		this.insertAround('1. ', '', false);
	}

	togglePreview() {
		this.showPreview = !this.showPreview;
	}

	changeTextSize(insert: string) {

		if (this.showPreview) {
			return;
		}

		if (!this.value) {
			this.value = '';
		}

		// temporary storage
		let selectionStart = this.nativeElement.selectionStart;
		let selectionEnd = this.nativeElement.selectionEnd;
		let value = this.value;

		const insertPoint = value.slice(0, selectionStart).lastIndexOf('\n') + 1;

		// if line starts with heading already, delete
		const heading = value.slice(insertPoint, value.indexOf(' ', insertPoint) + 1);

		if (heading.match(/#+ /)) {
			value = value.slice(0, insertPoint) + value.slice(insertPoint + heading.length, value.length);

			// if user hasn't selected the heading tag, remove from selection
			if (insertPoint === selectionStart - heading.length) {
				selectionStart = selectionStart - heading.length;
			}

			selectionEnd = selectionEnd - heading.length;
		}

		// add heading
		value = this.insert(value, insertPoint, insert);
		this.control.setValue(value);

		// reset
		this.nativeElement.setSelectionRange(selectionStart + insert.length, selectionEnd + insert.length);
		this.nativeElement.focus();
	}

	/**
	 * checks clipboard for Regex, otherwise returns placeholder
	 *
	 * @param regex expression to check clipboard for
	 * @param placeholder added before clipboard item or used if not valid
	 * @param addPlaceholder set to false to remove placeholder from clipboard - (website link)
	 */
	private async checkLink(regex: RegExp, placeholder: string, addPlaceholder = true) {
		try {

			const text = await this.window.navigator.clipboard.readText();

			if (!regex.test(text)) {
				throw Error('Not valid format');
			}

			this.insertAround('[', `](${addPlaceholder ? placeholder : ''}${text})`);

		} catch {
			this.insertAround('[', `](${placeholder})`);
		}

	}

	private updateLists() {
		this.updateList('- ');
		this.updateList('1. ');
	}

	/**
	 * check and add/remove list item
	 *
	 * @param listType list type
	 */
	private updateList(listType: string) {

		if (!this.value) {
			this.value = '';
		}

		// temporary storage
		const selectionStart = this.nativeElement.selectionStart;
		const selectionEnd = this.nativeElement.selectionStart;
		let value = this.value;

		const currentLineStart = value.slice(0, selectionStart).lastIndexOf('\n') + 1;
		let currentLineEnd = currentLineStart + value.slice(currentLineStart, value.length).indexOf('\n');

		// last line guard
		if (currentLineEnd - currentLineStart === -1) {
			currentLineEnd = value.length;
		}

		const previousLineStart = value.slice(0, currentLineStart - 1).lastIndexOf('\n') + 1;

		// don't check if no previous line
		if (currentLineStart <= 0 && previousLineStart <= 0) {
			return;
		}

		const listItem = new RegExp(`\t*${listType}.+`);
		const levelExp = new RegExp(`(\t*)${listType}`);

		// these need to become checking
		const previousLineListItem = listItem.test(value.slice(previousLineStart, currentLineStart - 1));
		const previousLineLevel = value.slice(previousLineStart, currentLineStart - 1).match(levelExp)?.[1]?.length ?? 0;
		const currentLineEmpty = value.slice(currentLineStart, currentLineEnd).trim() === '';

		// new line
		if (previousLineListItem && currentLineEmpty) {
			const insert = '\t'.repeat(previousLineLevel) + listType;

			value = this.insert(value, currentLineStart, insert);
			this.control.setValue(value, { emitEvent: false });

			this.nativeElement.setSelectionRange(selectionStart + insert.length, selectionEnd + insert.length);
			this.nativeElement.focus();

			return;
		}

		// empty line
		const emptyExp = new RegExp(`(\t*)${listType}\n`);
		const empty = value.slice(previousLineStart, currentLineStart).match(emptyExp);

		if (empty) {

			const levels = empty[1]?.length ?? 0;

			if (levels > 0) {
				const insert = '\t'.repeat(levels - 1) + listType;

				value = value.slice(0, previousLineStart) + insert + value.slice(currentLineStart, value.length);
				this.control.setValue(value, { emitEvent: false });

				this.nativeElement.setSelectionRange(selectionStart - 1, selectionEnd - 1);
				this.nativeElement.focus();

				return;
			}

			value = value.slice(0, previousLineStart) + '\n' + value.slice(currentLineStart, value.length);
			this.control.setValue(value, { emitEvent: false });

			this.nativeElement.setSelectionRange(selectionStart - listType.length, selectionEnd - listType.length);
			this.nativeElement.focus();

			return;
		}

		// indent
		const indent = new RegExp(`(\t*)${listType}\t`);

		if (indent.test(value.slice(currentLineStart, selectionStart))) {
			const insert = '\t'.repeat(previousLineLevel + 1) + listType;

			value = value.slice(0, currentLineStart) + insert + value.slice(selectionStart, value.length);
			this.control.setValue(value, { emitEvent: false });
			this.nativeElement.setSelectionRange(selectionStart, selectionEnd);
			this.nativeElement.focus();

			return undefined;
		}

	}

	/**
	 * Wrapped selected string in before and after characters and re-focuses selected text
	 *
	 * @param before text added before selected string
	 * @param after text added after selected string
	 * @param toggle if can be toggled on and off
	 */
	private insertAround(before: string, after: string, toggle?: boolean) {

		if (this.showPreview) {
			return;
		}

		if (!this.value) {
			this.value = '';
		}

		// temporary storage
		const selectionStart = this.nativeElement.selectionStart;
		const selectionEnd = this.nativeElement.selectionEnd;
		let value = this.value;

		// remove existing around selected
		if (toggle && value.slice(selectionStart - before.length, selectionStart) === before
			&& value.slice(selectionEnd, selectionEnd + after.length) === after) {
			value = value.slice(0, selectionStart - before.length) + value.slice(selectionStart, selectionEnd) + value.slice(selectionEnd + after.length, value.length);
			this.control.setValue(value);
			this.nativeElement.setSelectionRange(selectionStart - before.length, selectionEnd - after.length);
			this.nativeElement.focus();

			return;
		}

		// remove existing in selected
		if (toggle && value.slice(selectionStart, selectionStart + before.length) === before
			&& value.slice(selectionEnd - after.length, selectionEnd) === after) {
			value = value.slice(0, selectionStart) + value.slice(selectionStart + before.length, selectionEnd - after.length) + value.slice(selectionEnd, value.length);
			this.control.setValue(value);
			this.nativeElement.setSelectionRange(selectionStart, selectionEnd - (before.length + after.length));
			this.nativeElement.focus();

			return;
		}

		// add around selected
		value = this.insert(value, this.nativeElement.selectionStart, before);
		value = this.insert(value, this.nativeElement.selectionEnd + before.length, after);

		this.control.setValue(value);

		this.nativeElement.setSelectionRange(selectionStart + before.length, selectionEnd + before.length);
		this.nativeElement.focus();
	}

	/**
	 * insert string at position
	 *
	 * @param str current value
	 * @param pos position of insert
	 * @param insertStr string to insert
	 */
	private insert(str: string, pos: number, insertStr: string): string {

		if (pos < 0) {
			return str;
		}

		return str.slice(0, pos) + insertStr + str.slice(pos, str.length);
	}

	/**
	 * first character new in 'a' different from 'b'
	 *
	 * @param a select for compare
	 * @param b compare with selected
	 */
	private characterAdded(a: string, b: string): string | undefined {

		// guard against any removals or more than 1 character added
		if (a.length !== b.length + 1) {
			return;
		}

		const selectString = [...a];
		const compareString = [...b];

		return selectString.find((char, i) => char !== compareString[i]);
	}

	private get nativeElement(): HTMLTextAreaElement {
		return this.textarea.input.nativeElement;
	}

	private setLayoutSize(res: WindowResizeInfo) {

		if (!res.elementWidth) {
			return;
		}

		if (res.elementWidth >= 500) {
			this.layoutSize = this.layoutSizes.Normal;

			return;
		}

		if (res.elementWidth >= 350) {
			this.layoutSize = this.layoutSizes.Compact;

			return;
		}

		this.layoutSize = this.layoutSizes.CompactExtraSmall;
	}

}
