import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { HierarchyUnitFormData } from '@unifii/sdk';

import { HierarchyUnitIdentifier, HierarchyUnitPickerModalData } from '../../models';
import { ModalService } from '../../services';
import { SharedTermsTranslationKey } from '../../translations';

import { HierarchyUnitPickerModalComponent } from './hierarchy-utilities';
import { UfControlValueAccessor } from './uf-control-value-accessor';

@Component({
	selector: 'uf-hierarchy-list',
	templateUrl: './uf-hierarchy-list.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR, useExisting: UfHierarchyListComponent, multi: true,
	}],
	styleUrls: ['./uf-input.less'],
})
export class UfHierarchyListComponent extends UfControlValueAccessor<HierarchyUnitFormData[]> {

	@Input() label: string | null | undefined;
	@Input() placeholder: string | null | undefined;
	@Input() ceiling: HierarchyUnitIdentifier | null | undefined;
	@Input() leavesOnly: boolean | null | undefined;
	@Input() activesOnly: boolean | null | undefined;
	@Input() preselected: HierarchyUnitIdentifier | null | undefined;
	@Input() allowDuplicates = false;
	@Input() unselectableUnits: HierarchyUnitIdentifier[] | null | undefined;
	@Output() override valueChange = new EventEmitter<HierarchyUnitFormData[]>();

	protected readonly sharedTermsTK = SharedTermsTranslationKey;

	private isPickerOpen = false;
	private modalService = inject(ModalService);

	protected async addOrEdit(editUnit?: HierarchyUnitFormData, index?: number) {
		if (this.disabled || this.isPickerOpen) {
			return;
		}

		const controlValue = this.value ?? [];
		const unselectableUnits = [...this.unselectableUnits ?? []];

		if (!this.allowDuplicates) {
			// Units in the control value can't be re-selected (with exclusion of the one under edit)
			const valueUnitsExcludeEditedOne = controlValue.filter((valueUnit) => valueUnit.id !== editUnit?.id);

			unselectableUnits.push(...valueUnitsExcludeEditedOne);
		}

		this.isPickerOpen = true;

		const selectedUnit = await this.modalService.openLarge(
			HierarchyUnitPickerModalComponent,
			{
				value: editUnit,
				label: this.label,
				ceiling: this.ceiling,
				preselected: this.preselected,
				leavesOnly: this.leavesOnly,
				activesOnly: this.activesOnly,
				unselectableUnits,
			} satisfies HierarchyUnitPickerModalData,
		);

		this.isPickerOpen = false;

		if (selectedUnit === undefined) {
			return;
		}

		const itemIndex = index ?? -1;

		if (selectedUnit && !editUnit) {
			this.setControlValue([...controlValue, selectedUnit]);
		}

		if (selectedUnit && itemIndex >= 0) {
			const copy = [...controlValue];

			copy.splice(itemIndex, 1, selectedUnit);
			this.setControlValue(copy);
		}
	}

	protected remove(index: number) {
		if (!this.value || this.disabled) {
			return;
		}

		const copy = [...this.value];

		copy.splice(index, 1);
		this.setControlValue(copy);
	}

	private setControlValue(value: HierarchyUnitFormData[]) {
		this.control.setValue(value);
		this.control.markAsTouched();
	}

}
