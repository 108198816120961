import { Component, OnInit, inject } from '@angular/core';

import { ColumnGap, Size, ToastService, UfControl, UfControlGroup, UfFormBuilder } from '@unifii/library/common';
import { WorldDataValue } from 'showcase/src/app/services';

import { ShowCascadeSelectionDataSource } from './show-cascade-selection-data-source';

@Component({
	selector: 'sc-cascade-selection',
	templateUrl: './show-cascade-selection.html',
})
export class ShowCascadeSelectionComponent implements OnInit {

	protected readonly sizes = Size;
	protected readonly columnGaps = ColumnGap;
	protected dataSource = inject(ShowCascadeSelectionDataSource);
	protected valueChanged: string | undefined;
	protected group: UfControlGroup;
	protected control: UfControl | undefined;
	protected enabled = true;
	protected ready = false;

	private toastService = inject(ToastService);
	private ufb = inject(UfFormBuilder);

	async ngOnInit() {
		const value = await this.dataSource.generateRandomValue(0);

		this.control = this.ufb.control({ value: null, disabled: !this.enabled });
		this.control.setValue(value);
		this.group = this.ufb.group({ control: this.control });
		this.ready = true;
	}

	protected onValueChange(value: (WorldDataValue | WorldDataValue[])[] | null) {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		this.valueChanged = value?.map((v) => Array.isArray(v) ? v.map((single) => single.name).join(' | ') : v.name).join(' > ');
		this.toastService.info(this.valueChanged ?? `'null value'`);
	}

	protected async setValue(value?: (WorldDataValue | WorldDataValue[])[] | null) {
		if (!this.control) {
			return;
		}

		if (value === undefined) {
			value = await this.dataSource.generateRandomValue();
		}

		this.control.setValue(value);
	}

	protected toggleEnabled() {
		if (this.enabled) {
			this.control?.enable();
		} else {
			this.control?.disable();
		}
	}

}
