import { HIERARCHY_ROOT_ID_ALIAS, HierarchyUnitExtended, HierarchyUnitFormData, HierarchyUnitState, HierarchyUnitWithChildCount, isNotNull } from '@unifii/sdk';

import { HierarchyCascadeSelectionMode, HierarchyUnitIdentifier, HierarchyUnitProvider } from '../../../models';
import { HierarchyCache, HierarchyService } from '../../../services';
import { getValueAsArray, hierarchyIdentifierToUnitId } from '../../../utils';
import { CascadeSelectionDataSource, CascadeSelectionStep } from '../../cascade-selection';

export type HierarchyCascadeSelectionDataSourceOptions = {
	nameProperty?: string | ((option: HierarchyUnitWithChildCount) => string);
	mode?: HierarchyCascadeSelectionMode;
	ceiling?: HierarchyUnitIdentifier | null;
	activesOnly?: boolean | null;
};

export class HierarchyCascadeSelectionDataSource implements CascadeSelectionDataSource<HierarchyUnitWithChildCount> {

	private mode: HierarchyCascadeSelectionMode;
	private ceiling: string | null | undefined;
	private activesOnly: boolean;
	private rootUnit: HierarchyUnitWithChildCount | undefined;
	private ceilingUnitsId: string[] = [];

	constructor(
		private provider: HierarchyUnitProvider,
		private cache: HierarchyCache,
		private service: HierarchyService,
		private options?: HierarchyCascadeSelectionDataSourceOptions,
	) {
		const ceilingId = hierarchyIdentifierToUnitId(this.options?.ceiling);
		
		this.mode = this.options?.mode ?? 'single';
		this.activesOnly = !!options?.activesOnly;
		this.ceiling = ceilingId !== HIERARCHY_ROOT_ID_ALIAS ? ceilingId : undefined;
	}

	/**
	 * Initialize the dataSource by pre-loading root and ceiling units
	 * @throws UfHierarchyError in case of configuration issues
	 */
	async init() {
		try {
			this.rootUnit = await this.provider.getUnit(HIERARCHY_ROOT_ID_ALIAS);
		} catch (e) {
			console.error('HierarchyCascadeSelectionDataSource.init - load root unit error', e);
		}

		if (!this.rootUnit || this.rootUnit.childCount === 0) {
			throw this.service.misconfiguredError;
		}
		
		if (!this.ceiling) {
			return;
		}

		let ceiling: HierarchyUnitExtended | undefined;

		try {
			ceiling = await this.provider.getUnit(this.ceiling);
		} catch (e) {
			console.error(`HierarchyCascadeSelectionDataSource.init - load ceiling unit ${this.ceiling} error`, e);
		}

		if (!ceiling?.childCount) {
			throw this.service.misconfiguredError;
		}

		const ceilingUnits = await this.getUnits(ceiling.path);

		if (ceilingUnits.length !== ceiling.path.length) {
			throw this.service.misconfiguredError;
		}

		this.ceilingUnitsId = ceilingUnits.map((unit) => unit.id);
	}

	getNextStep(value: (HierarchyUnitWithChildCount | HierarchyUnitWithChildCount[])[]): Promise<CascadeSelectionStep<HierarchyUnitWithChildCount> | undefined> {

		if (!this.rootUnit) {
			// Initialization not executed, next step can't be resolved
			return Promise.resolve(undefined);
		}

		const lastValue = value[value.length - 1];

		if (Array.isArray(lastValue) && lastValue.length > 1) {
			// Siblings mode value with multiple units selected, no next step available
			return Promise.resolve(undefined);
		}

		const lastUnit = getValueAsArray(lastValue ?? this.rootUnit)[0];
		
		if (!lastUnit?.childCount) {
			return Promise.resolve(undefined);
		}

		const nextStep = {
			label: lastUnit.childrenLabel,
			predetermined: false,
			isMultiple: this.mode === 'siblings',
			disabled: this.ceilingUnitsId.length > value.length,
			search: (query: string | undefined) =>
				this.provider.getChildren(lastUnit.id, { q: query, state: this.activesOnly ? HierarchyUnitState.Active : undefined }),
			display: this.options?.nameProperty ?? 'label',
		} satisfies CascadeSelectionStep<HierarchyUnitWithChildCount>;

		return Promise.resolve(nextStep);
	}

	/**
	 * Transform the component value to its equivalent cascade-selection steps' values
	 * @param value this component value
	 * @throws UfHierarchyError - when the value is not compatible with the component configuration
	 * @returns cascade-selection steps' values
	 */
	async getStepsValue(value: HierarchyUnitFormData | HierarchyUnitFormData[] | null | undefined): Promise<HierarchyUnitWithChildCount[] | HierarchyUnitWithChildCount[][] | undefined> {

		if (value && ((this.mode === 'single' && Array.isArray(value)) || this.mode === 'siblings' && !Array.isArray(value))) {
			throw this.service.invalidError;
		}

		const values = getValueAsArray(value);
		const firstUnit = values[0];

		if (!firstUnit) {
			if (!this.ceiling) {
				return undefined;
			}

			const ceilingUnits = await this.getUnits((await this.cache.getUnit(this.ceiling))?.path ?? []);

			return this.mode === 'single' ? ceilingUnits : ceilingUnits.map(getValueAsArray);
		}

		// Check that all units are siblings
		if (new Set(values.map((unit) => unit.path[unit.path.length - 2]?.id).filter(isNotNull)).size > 1) {
			throw this.service.invalidError;
		}

		if (this.ceiling && !firstUnit.path.some((step) => step.id === this.ceiling)) {
			throw this.service.invalidError;
		}

		if (this.mode === 'single') {
			return this.getUnits(firstUnit.path);
		}

		const parentUnits = (await this.getUnits(firstUnit.path.slice(0, -1))).map(getValueAsArray);
		const siblingUnits = await this.getUnits(values);

		return [...parentUnits, siblingUnits];
	}

	private async getUnits(identifiers: HierarchyUnitIdentifier[]): Promise<HierarchyUnitWithChildCount[]> {
		const units: HierarchyUnitWithChildCount[] = [];

		for (const identifier of identifiers) {
			const unit = await this.cache.getUnit(identifier);

			if (unit) {
				units.push(unit);
			}
		}

		return units;
	}

}
