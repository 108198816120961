import { Component, EventEmitter, OnInit, inject } from '@angular/core';
import { HierarchyUnitFormData, HierarchyUnitSelectionMode } from '@unifii/sdk';

import { HierarchyPrefillController, HierarchyUnitIdentifier, HierarchyUnitProvider, RuntimeField, UfControl, areEquivalentHierarchyIdentifiers, toHierarchyUnitsIds } from '@unifii/library/common';
import { FieldComponent, FormField } from '@unifii/library/smart-forms';

@Component({
	selector: 'uf-hierarchy-unit-input',
	templateUrl: './hierarchy-unit-input.html',
})
export class HierarchyUnitInputComponent implements FormField, OnInit {

	field: RuntimeField;
	control: UfControl;
	content: HierarchyUnitFormData | undefined;
	contentChange: EventEmitter<HierarchyUnitFormData>;
	suffix: string;
	cssClass: string | string[];
	
	protected hierarchyCeiling: HierarchyUnitIdentifier | undefined;
	protected preselectedUnit: HierarchyUnitIdentifier | undefined;
	protected leavesOnly: boolean;
	protected matchOrDescendantsUnits: HierarchyUnitIdentifier[] = [];

	private hierarchyPrefillCtrl: HierarchyPrefillController;
	private fieldComponent = inject(FieldComponent);
	private hierarchyProvider = inject(HierarchyUnitProvider);
	private _setValue: (v: any) => void;
	private lastSetValue: unknown;

	ngOnInit() {
		this.hierarchyCeiling = this.field.hierarchyConfig?.ceiling;
		this.hierarchyPrefillCtrl = new HierarchyPrefillController(this.hierarchyProvider, this.hierarchyCeiling);
		this.leavesOnly = (this.field.hierarchyConfig?.selectionMode ?? HierarchyUnitSelectionMode.Leaf) === HierarchyUnitSelectionMode.Leaf;

		// Monkey patch FieldComponent.setValue with custom logic for Hierarchy (values can be validated and prefill applied if needed)
		// Call to _setValue must be run with FieldComponent context
		// eslint-disable-next-line @typescript-eslint/unbound-method
		this._setValue = this.fieldComponent.setValue;

		// Call to FieldComponent.setValue must be run with HierarchyUnitInputComponent context (this)
		// eslint-disable-next-line @typescript-eslint/no-misused-promises
		this.fieldComponent.setValue = this.setValue.bind(this);
	}

	protected onValueChange(unit: HierarchyUnitFormData) {
		this.contentChange.emit(unit);
	}

	private async setValue(rawValue: unknown) {
		if (
			rawValue === this.lastSetValue ||
			areEquivalentHierarchyIdentifiers(
				toHierarchyUnitsIds(rawValue),
				toHierarchyUnitsIds(this.lastSetValue),
			)
		) {
			return;
		}

		this.lastSetValue = rawValue;

		if (rawValue == null) {
			this.preselectedUnit = undefined;
			this.matchOrDescendantsUnits = [];
			this._setValue.call(this.fieldComponent, null);
			
			return;
		}

		// TODO implement a queue system with abortSignal for the current execution
		const { units, ancestor } = await this.hierarchyPrefillCtrl.getUnitsAndCommonAncestor(rawValue);

		if (units.length === 1 && (!ancestor || !this.leavesOnly || ancestor.childCount === 0)) {

			const hierarchyUnit: HierarchyUnitFormData | undefined = ancestor ? {
				id: ancestor.id,
				path: ancestor.path,
				label: ancestor.label,
			} : undefined;

			this._setValue.call(this.fieldComponent, hierarchyUnit);
		} else {
			// Prefill non leaf units
			this.preselectedUnit = ancestor ?? undefined;
		}

		if (this.field.bindTo && !this.field.autofill) {
			// Limit selectable units to be part of the bindTo values
			this.matchOrDescendantsUnits = units;
		}
	}

}
