<uf-table-toolbar />

<ng-container #outlet *ngIf="config" />

<uf-table-status [ngClass]="{'showAlways': showStatusAlways}" />

<ng-template #tableTemplate>
	<!-- Container required so content can overflow horizontally and toolbar can remain as <a> -->
	<div [ufSort]="sort" [selectable]="config.selectable" selectMaster class="table-container">
		<div class="table">
			<div class="header">
				<div [ngClass]="{'has-actions': config.actions}" class="header-row">
					<div *ngIf="config.selectable" class="small-cell">
						<uf-select-cell />
					</div>
					<ng-container *ngFor="let column of config.columns">
						<div *ngIf="!column.hidden" class="cell">
							<div [ufSortElement]="column.name" [sortDisabled]="!column.sortable" class="header-label">
								{{column.label}}
							</div>
						</div>
					</ng-container>
					<div *ngIf="config.actions || config.columnToggles" class="small-cell sticky-cell">
						<uf-table-column-toggles *ngIf="config.columnToggles" />
					</div>
				</div>
			</div>
			<div class="body">
				<div *ngFor="let row of rows; trackBy: trackBy; let odd = odd" [ngClass]="{
					'stale': row.context.stale,
					'selectable': config.selectable,
					'clickable': row.memoize.link ?? rowAction,
					'odd': odd === true
				}" class="body-row">

					<div *ngIf="config.selectable" class="small-cell">
						<uf-select-cell [item]="row.context.$implicit" [index]="row.context.index" />
					</div>

					<ng-container *ngFor="let column of config.columns">
						<ng-container *ngIf="!column.hidden">
							<ng-container *ngTemplateOutlet="
						(row.memoize.link ?? rowAction) ? linkCell : readOnlyCell; context: {$implicit: row, column: column}" />
						</ng-container>
					</ng-container>

					<div *ngIf="config.actions || config.columnToggles" class="small-cell sticky-cell">
						<uf-table-actions *ngIf="config.actions" [row]="row" />
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #listTemplate>
	<ul [ufSort]="sort" [selectable]="config.selectable" selectMaster class="uf-list accent uf-box">
		<li *ngFor="let row of rows; trackBy: trackBy;" [ngClass]="{
					'stale': row.context.stale,
					'selectable': config.selectable,
					'clickable': row.memoize.link ?? rowAction
				}" class="row">
			<a *ngIf="row.memoize.link ?? rowAction" [routerLink]="row.memoize.link" [target]="row.memoize.linkTarget"
				(click)="onRowAction(row.context.$implicit)" class="grow pad pad-right-none">
				<ng-container *ngTemplateOutlet="descriptionList; context: {$implicit: row}" />
			</a>
			<div *ngIf="!row.memoize.link && !rowAction" class="grow pad pad-right-none">
				<ng-container *ngTemplateOutlet="descriptionList; context: {$implicit: row}" />
			</div>

			<div *ngIf="config.actions || config.selectable || config.cardExpands" class="col center-all pad-sm">
				<uf-select-cell *ngIf="config.selectable" [item]="row.context.$implicit" [index]="row.context.index"
					[isTableCell]="false" />
				<uf-table-actions *ngIf="config.actions" [row]="row" />
				<uf-table-card-expander [context]="row.context" />
			</div>
		</li>
	</ul>
</ng-template>

<ng-template #cardsTemplate>
	<div [ufSort]="sort" [selectable]="config.selectable" selectMaster class="uf-card-list">
		<ng-container *ngFor="let row of rows; trackBy: trackBy; let index = index;">
			<a *ngIf="row.memoize.link ?? rowAction" [routerLink]="row.memoize.link" [target]="row.memoize.linkTarget"
				(click)="onRowAction(row.context.$implicit)" class="uf-card">
				<ng-container *ngTemplateOutlet="cardContent; context: {$implicit: row, config: config}" />
			</a>
		</ng-container>
	</div>
</ng-template>

<ng-template #cardContent let-row>
	<div *ngIf="row.memoize.image" class="uf-img-3x2">
		<img [src]="row.memoize.image">
	</div>
	<div *ngIf="row.memoize.label" class="uf-card-title">
		<h4>{{ row.memoize.label }}</h4>
	</div>
	<div class="pad pad-none-top">
		<ng-container *ngTemplateOutlet="descriptionList; context: {$implicit: row}" />
	</div>
</ng-template>

<ng-template #descriptionList let-row>
	<uf-data-display-list>
		<ng-container *ngFor="let column of config.columns">
			<ng-container *ngIf="!column.hidden || row.context.expanded">
				<dt>{{column.label}}</dt>
				<dd>
					<ng-container
						*ngTemplateOutlet="cellDisplayValue; context: {$implicit: row, column: column, listTemplate: true }" />
				</dd>
			</ng-container>
		</ng-container>
	</uf-data-display-list>
</ng-template>

<ng-template #linkCell let-row let-column="column">
	<a [routerLink]="row.memoize.link ?? []" [target]="row.memoize.linkTarget"
		(click)="onRowAction(row.context.$implicit)" class="list-item">
		<ng-container *ngTemplateOutlet="cellDisplayValue; context: {$implicit: row, column: column}" />
	</a>
</ng-template>


<ng-template #readOnlyCell let-row let-column="column">
	<div class="list-item">
		<ng-container *ngTemplateOutlet="cellDisplayValue; context: {$implicit: row, column: column}" />
	</div>
</ng-template>

<ng-template #cellDisplayValue let-row let-column="column" let-listTemplate="listTemplate">
	<ng-template [ngIf]="cellsTemplate[column.name]" let-cellTemplate [ngIfElse]="defaultTemplate">
		<ng-container
			*ngTemplateOutlet="cellTemplate; context: {$implicit: row.context.$implicit, column: column, index: row.context.index}" />
	</ng-template>
	<ng-template #defaultTemplate>
		<uf-data-display-table-cell *ngIf="!listTemplate" [data]="row.memoize.columnsValue[column.name]" />
		<uf-data-display-table-list *ngIf="listTemplate" [data]="row.memoize.columnsValue[column.name]" />
	</ng-template>
</ng-template>