import { Component, ElementRef, EventEmitter, HostBinding, Input, Output, ViewChild, inject } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

import { UfControl } from '../../controls';
import { CommonTranslationKey } from '../../translations';

import { UfControlValueAccessor } from './uf-control-value-accessor';

@Component({
	selector: 'uf-file',
	templateUrl: './uf-file.html',
	providers: [{
		provide: NG_VALUE_ACCESSOR, useExisting: UfFileComponent, multi: true,
	}],
	styleUrls: ['./uf-file.less'],
})
export class UfFileComponent extends UfControlValueAccessor<any> {

	@ViewChild('fileInput') inputRef: ElementRef;
	@Input() label: string | null;
	@Input() type: string | null | undefined;
	@Input() typeLabel: string | null | undefined;
	@Input() multiple: boolean | string = false;
	@Output() override valueChange = new EventEmitter();
	@Output() touchedChange = new Subject<boolean>();

	protected focused: boolean;
	protected inputControl = new UfControl();

	private translate = inject(TranslateService);

	/**
	 * Prevent value from being emitted by the control value accessor as valueChanges is handled in this component
	 */
	override valueEmitPredicate(): boolean {
		return false;
	}

	get uploadLabel() {
		switch (this.typeLabel) {
			case 'image': return this.translate.instant(CommonTranslationKey.UploadImageLabel) as string;
			case 'video': return this.translate.instant(CommonTranslationKey.UploadVideoLabel) as string;
			case 'audio': return this.translate.instant(CommonTranslationKey.UploadAudioLabel) as string;
			default: return this.translate.instant(CommonTranslationKey.UploadFileLabel) as string;
		}
	}
	
	protected onChange() {
		const files = Array.from(this.inputRef.nativeElement.files);

		this.control.setValue(files);
		this.valueChange.emit(files);
		this.inputRef.nativeElement.value = '';
	}

	@HostBinding('class.disabled') get disabledClass() {
		return this.disabled;
	}

	@HostBinding('class.focused') get focusedClass() {
		return this.focused;
	}

}
